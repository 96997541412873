import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import noImg from '../../../../assets/img/noimage_200.png';
import audiopng from '../../../../assets/img/audio.jpg';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import PageTitle from '../../PageTitle';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
import ReactFileReader from 'react-file-reader';
import ReactAudioPlayer from 'react-audio-player';
import CKEditor from 'ckeditor4-react';


class AddManna extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			files : '',
			photoname : '',
			files1 : '',
			audioname : '',
			company : Reqst.getTokenData('id','data'),
			evelist : false,
			mannid : '',
			eve_tit : '',
			eve_content : '',
			photo : '',
			audiourl : '',
			eve_date : '',
			audiosize : '',
			audiorealsize : '',
			userrole : Reqst.getTokenData('role','data'),
			user : Reqst.getTokenData('id','data')
		}
		this.validator = new SimpleReactValidator();
		this.handleFormSubmit= this.handleFormSubmit.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
	    this.handlefileRemove = this.handlefileRemove.bind(this);
		this.handlePhotoRemove = this.handlePhotoRemove.bind(this);
		this.handleFiles1 = this.handleFiles1.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleDatafullRemove = this.handleDatafullRemove.bind(this);
		this.handleEditorChange = this.handleEditorChange.bind(this);
	}
	
	
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	
	handleEditorChange(v){
		var newContent = v.editor.getData();
		this.setState({editorContent:newContent});
	}
	
	
	handleFiles(files){
		this.setState({ files: files.base64,photoname : files.fileList[0]['name']});
	}
	
	handleFiles1(files){
		this.setState({ files1: files.base64,audioname : files.fileList[0]['name'],audiosize:files.fileList[0]['size']});
		this.bytesToSize(this.state.audiosize);
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	
	
	
	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleFormSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			$("#showLoader").show();
			var data = Sanitize.objectifyForm($("#frmManadd").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('appcontent/updatemanna',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					$("#showLoader").hide();
					//document.getElementById("frmManadd").reset();
					this.getdata();
					this.setState({ files: '',photoname : ''});
					this.setState({ files1: '',audioname : ''});
					this.setState({ audiosize: '',audiorealsize : ''});
					swal("Success",res['data'].msg,"success");
				}			
				else
				{
					$("#showLoader").hide();
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	handlefileRemove(){
		
		this.setState({ files1: '',audioname : ''});
		this.setState({ audiosize: '',audiorealsize : ''});
		 $(".btn_div").show();
		
	}
	
	handlePhotoRemove(){
		
		this.setState({ files: '',photoname : ''});
		 $(".btn_div").show();
		
	}
	
	handleDatafullRemovefn(field){
		
		this.state.field = field;
		this.removefile();
		
	}
	
	handleDatafullRemove = (field): void => {
		
		 confirmAlert({
		  title: 'Confirm to Delete',
		  message: 'Are you sure to Delete this File ?',
		  buttons: [
			{
			  label: 'No'
			},
			{
			  label: 'Yes',
			  onClick: () => this.handleDatafullRemovefn(field)
			}
		  ]
		})
  };
	
	 bytesToSize(bytes) {
	   var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	   if (bytes == 0) return '0 Byte';
	   var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	   var size =  Math.round(bytes / Math.pow(1024, i), 2) ;
	   var mes = sizes[i];
	    if(parseInt(size)>5 && mes=='MB'){
			   
			   $(".btn_div").hide();
			   alert('File Size More than 5MB ');
			   
	   }
	   else{
		   
		   $(".btn_div").show();
		   
	   }
   };
	
	componentDidMount(){
		
		this.getdata();
		
	}
	
	removefile(){
		 $("#showLoader").show();
		Reqst.GetData('appcontent/removemannafile/'+this.state.mannid+'/'+this.state.user+'/'+this.state.field).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res.status=='1'){
					swal("Success",res.msg,"success");
					this.getdata();
			}
			else{
				
					swal("Error",res['data'].msg,"error");
			}
		});
	}
	
	getdata(){
		 $("#showLoader").show();
		Reqst.GetData('appcontent/getmannasbyid/'+this.props.match.params.id).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res.length>0){
				this.setState({
					mannid : res[0]['id'],
					eve_tit : res[0]['title'],
					editorContent : res[0]['content'],
					photo : res[0]['coverphoto'],
					audiourl : res[0]['audiourl'],
					eve_date : res[0]['dateon']
				});	
			}
		});
	}
	

	render(){
		
	
	
		  
		if(this.state.userrole!='admin' && this.state.userrole!='staff')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
	   if(this.state.evelist)
		{
			return(<Redirect to="/appcontent/dailymanna" />);
		}
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Update Today’s Sharing</title>
				</Helmet>
				
				<PageTitle title="Update Today’s Sharing" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({evelist:true})}} >
							<i className="fa fa-arrow-left"></i> Back
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
				<form action="" method="post" encType="multipart/form-data" id="frmManadd" onSubmit={this.handleFormSubmit} >
				<input type="hidden" name="userid" value={this.state.user} />
				<input type="hidden" name="churchid" value={this.state.company} />
				<input type="hidden" name="mannid" value={this.state.mannid} />
				 <input type="hidden" name="photoname" value={this.state.photoname} />
				  <input type="hidden" name="files" value={this.state.files} />
				  <input type="hidden" name="audioname" value={this.state.audioname} />
				  <input type="hidden" name="files1" value={this.state.files1} />
				    <input type="hidden" value={this.state.editorContent} id="eve_content" name="eve_content" />
					<Row>
					
						
						<Col md={9} >
							<div className="form-group" >
								<label className="label-text" >Title<span className="text-danger">*</span></label>
								<input type="text" name="eve_tit" id="eve_tit" className="form-control single-line-input" value={this.state.eve_tit}  required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Title', this.state.eve_tit, 'required')}
							</div>
						</Col>
						<Col md={3} >
							<div className="form-group" >
								<label className="label-text" >To be Shared on<span className="text-danger">*</span></label>
								<input type="date" name="eve_date" id="eve_date" className="form-control single-line-input" value={this.state.eve_date} required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Event On', this.state.eve_date, 'required')}
							</div>
						</Col>
						<Col md={12}>
						<div className="form-group" >
								            <CKEditor 
												  data={this.state.editorContent} 
												  config={{extraPlugins :'font,colorbutton,colordialog,iframe'}}
												  onChange = {this.handleEditorChange}
												  
												 />
							</div>
					 </Col>
					 <Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Audio<span className="text-danger"> (Maximum Size Less than 5MB)</span></label>
								{this.state.audiourl!='' && this.state.audiourl!=null ?
								<span style={{marginLeft:'5px'}}
								 className="btn btn-danger btn-xs" onClick={() => this.handleDatafullRemove('audiourl') }  ><i className="fa fa-remove"></i>
								</span>
								: '' }
								<ReactFileReader handleFiles={this.handleFiles1} fileTypes='audio/*' base64={true}>
							      <img src={audiopng} style={{border : '1px solid #cccccc',width : '145px',cursor : 'pointer'}} />
						       </ReactFileReader>
							   <ReactAudioPlayer
									  src={this.state.files1!='' ? this.state.files1 : this.state.audiourl!='' && this.state.audiourl!=null ?  this.state.audiourl : '' }
									  
									  controls={this.state.files1!='' ? this.state.files1 : this.state.audiourl!='' && this.state.audiourl!=null ?  true : false }
								/>
								{this.state.files1!='' ?
								  <span className="btn btn-danger btn-xs" onClick={this.handlefileRemove}>Remove</span>
								  : ''								  
								}
							</div>
						</Col>
						 <Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Image<span className="text-danger"> (Recommended Size: 600px X 450px (W : H = 4:3 ))</span></label>
								{this.state.photo!='' && this.state.photo!=null ?
								<OverlayTrigger overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}>
									<span style={{marginLeft:'43px'}}
									 className="btn btn-danger btn-xs" onClick={() => this.handleDatafullRemove('coverphoto') }  ><i className="fa fa-remove"></i>
									</span>
								</OverlayTrigger>
								: '' }
								<ReactFileReader handleFiles={this.handleFiles} fileTypes='image/*' base64={true}>
							     <img src={this.state.files!='' ? this.state.files : this.state.photo!='' && this.state.photo!=null ? this.state.photo : noImg} style={{border : '1px solid #cccccc',width : '145px',cursor : 'pointer'}} />
						       </ReactFileReader>
							   {this.state.files!='' ?
								  <span className="btn btn-danger btn-xs" onClick={this.handlePhotoRemove}>Remove</span>
								  : ''								  
								}
							</div>
						</Col>
						
						
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4 btn_div">
							<LoadingBtn text="Update" loadingText="Updting.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
			</div>
		);
	}
	
}
export default AddManna;


