import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Pagination from "react-js-pagination";
import PageTitle from '../../PageTitle';
import { confirmAlert } from 'react-confirm-alert'; 
import noImg from '../../../../assets/img/noimage_200.png';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class Testimonials extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			company : Reqst.getTokenData('id','data'),
			user : Reqst.getTokenData('id','data'),
			testimonials : [],
			pageedit : false,
			userrole : Reqst.getTokenData('role','data'),
			currentPage: 1,
			idd : '',
			todosPerPage: 20,
			addPrayreq : false,
			editPrayreq : false,
			activePage : 1
		}
		this.validator = new SimpleReactValidator();
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.onShowChange = this.onShowChange.bind(this);
		this.handleAddSubmit = this.handleAddSubmit.bind(this);
		this.handleUpdateSubmit = this.handleUpdateSubmit.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChkChange = this.handleChkChange.bind(this);
	}
	
	handleChkChange(e){
		if(e.target.checked){
			this.setState({showname : 1});
		}
		else {
			this.setState({showname : 0});
		}
	}
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	handleAddSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			this.setState({showLoader:true});
			var data = Sanitize.objectifyForm($("#frmPrreqadd").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('appcontent/addtestimonial/',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false});	
					swal("Success",res['data'].msg,"success");
					this.setState({addPrayreq: false});
					this.getdata();
					
				}	
				else
				{
					this.setState({showLoader: false});	
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	handleUpdateSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			this.setState({showLoader:true});
			var data = Sanitize.objectifyForm($("#frmPrreqUp").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('appcontent/updatetestimonial/',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false});	
					swal("Success",res['data'].msg,"success");
					this.setState({editPrayreq: false});
					this.getdata();
					
				}	
				else
				{
					this.setState({showLoader: false});	
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	handlePageChange(pageNumber) {
		
		this.setState({activePage: pageNumber});
		this.state.currentPage = Number(pageNumber);
	}
	
	onShowChange(e){
		this.state.todosPerPage = e.target.value;
		
			
			this.getdata();
		
	}
	
	
	handleUpdate(id){
		
		this.state.idd = id;
		this.setState({editPrayreq: true});
		this.praydata();
	
	}
	
	handleRemovefn(id){
		
		this.state.idd = id;
		this.removepr();
		
	}
	
	handleRemove = (dd): void => {
		
		this.state.idd = dd;
		 confirmAlert({
		  title: 'Confirm to Delete',
		  message: 'Are you sure to Delete this Testimonial ?',
		  buttons: [
			{
			  label: 'No'
			},
			{
			  label: 'Yes',
			  onClick: () => this.handleRemovefn(dd)
			}
		  ]
		})
  };
	
	gettimeform(time){
		
		//var hours = time.getHours();
		var hours = (time+24-2)%24; 
		var mid='am';
		if(hours==0){ //At 00 hours we need to show 12 am
		hours=12;
		}
		else if(hours>12)
		{
		hours=hours%12;
		mid='pm';
		}
		
		return time + mid;
		
		
	}
	
	getdateofdt(date){
		      
			  if(date!='' && date!=null){		
			  var date1 = new Date(date);
		      var year = date1.getFullYear();
			  var month = (date1.getMonth()+1);
			  var day   = date1.getDate()
			  var strTime = day + '-' + month + '-' + year;
			  return strTime;
		 }
		 else{
			 
			 return 'N/A';
		 }
			
	  }
	

	
	componentDidMount(){
		
		this.getdata();
		
	}
	
	praydata(){
		 $("#showLoader").show();
		Reqst.GetData('appcontent/gettestimonialbyid/'+this.state.idd).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({
					 showname : res[0]['showname'],
					 prreq_name : res[0]['name'],
					 pray_id : res[0]['id'],
					 prreq_con : res[0]['message'],
					 prreq_mail : res[0]['email'],
					 prreq_mob : res[0]['mobile'],
					 test_status : res[0]['teststatus']
				});
			}
		});
	}
	
	
	removepr(){
		 $("#showLoader").show();
		Reqst.GetData('appcontent/removetestimonial/'+this.state.idd+'/'+this.state.user).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res.status=='1'){
					swal("Success",res.msg,"success");
					this.getdata();
			}
			else{
				
					swal("Error",res['data'].msg,"error");
			}
		});
	}
	
	getdata(){
		 $("#showLoader").show();
		Reqst.GetData('appcontent/gettestimonials/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({testimonials : res});
			}
		});
	}

	render(){
		
	
		
	  if(this.state.userrole!='admin')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
		
		if(this.state.testimonials.length>0){
			
			$(".page_btns").show();
		}
		else{
			$(".page_btns").hide();
		}
		
		  /** set showcount select box **/
	this.shows = [
		{value : '10', name : '10'},
		{value : '20', name : '20'},
		{value : '50', name : '50'},
		{value : '100', name : '100'}
	];
	
		this.praystatus = [
		{value : '', name : '-Select-'},
		{value : 'pending', name : 'Pending'},
		{value : 'published', name : 'Published'},
		{value : 'rejected', name : 'Rejected'}
	];
	
	
	
	// Logic for displaying current todos
	const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
	const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
	const currentTodos = this.state.testimonials.slice(indexOfFirstTodo, indexOfLastTodo);

	const renderTodos = currentTodos.map((item,i) => {
		
	  return <tr key = {item}>
								<td width="20">{i+1}</td>
								<td width="200">{item.name}<br />
									{item.showname == 0 ? '' : '(Hidden)'}
								</td>
								<td width="100">{item.mobile}</td>
								<td width="200">{item.email}</td>
								<td width="100">{this.getdateofdt(item.idate)}</td>
								<td width="100"> 
								{item.teststatus=='published'?
								<span className="label label-success"> {item.teststatus}</span>
							 : item.teststatus=='pending' ?
								<span className="label label-success"> {item.teststatus}</span>
							 :
								<span className="label label-danger"> {item.teststatus}</span>
							}
							</td>
								<td width="100">
									<OverlayTrigger overlay={<Tooltip id="tooltip-top">Edit</Tooltip>}>
									<button name="edithw" className="btn btn-info btn-md" onClick={() => this.handleUpdate(item.id)}  ><i className="fa fa-edit"></i></button>
									</OverlayTrigger>
									&nbsp;&nbsp;
									<OverlayTrigger overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}>
									<button name="removeann" className="btn btn-danger btn-md" onClick={() => this.handleRemove(item.id)}  ><i className="fa fa-remove"></i></button>
									</OverlayTrigger>
								</td>
							</tr>;
		
	});
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | View Testimonials </title>
				</Helmet>
				
				<PageTitle title="View Testimonials" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({addPrayreq:true})}} >
							<i className="fa fa-plus"></i>&nbsp;Add
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
				<Row>
				  <Col md="12">
				 
					<Table responsive hover bordered>
					  <thead>
						<tr>
						  <th>S.No</th>
						  <th>Name</th>
						  <th>Mobile</th>
						  <th>Email</th>
						  <th>Submitted Date</th>
						  <th>Status</th>
						  <th>Manage</th>
						</tr>
					  </thead>
					  <tbody>
					
					  {this.state.testimonials.length>0 ? 
						renderTodos
					  : 
					   <tr><td colSpan="6"><center>No Data Found...</center></td></tr>
					  }
					</tbody>
					</Table>
					
				  </Col>
				  <br />
				  <Col md="4" className="page_btns">
				  <Pagination
							 activePage={this.state.activePage}
							 itemsCountPerPage={this.state.todosPerPage}
							 pageRangeDisplayed={3}
							 totalItemsCount={this.state.testimonials.length}
							 onChange={this.handlePageChange}
							/>
				  </Col>
				  <Col md="2" className="page_btns">
				   <select name="show" onChange={this.onShowChange} id="show" className="form-control" >
								{this.shows.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>&nbsp;
						entries
				  </Col>
				   <Modal show={this.state.addPrayreq} className="modal-popup"   size="md" onHide={()=>{this.setState({addPrayreq:false})}} >
				  <Modal.Header closeButton>
					<Modal.Title>Add Testimonial</Modal.Title>
				  </Modal.Header>
				  <Modal.Body>
						<form action="" method="post" encType="multipart/form-data" id="frmPrreqadd" onSubmit={this.handleAddSubmit} >
				<input type="hidden" name="churchid" value={this.state.company} />
				<input type="hidden" name="userid" value={this.state.user} />
				<input type="hidden" name="pray_showname" value={this.state.showname} />
					<Row>
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" style={{width : '100%'}}>Name<span className="text-danger">*</span>
									<span style={{float : 'right'}}>
										<input type="checkbox" name="showname" checked={this.state.showname == 1 ? true : false} onChange={this.handleChkChange} />&nbsp;Hide Name
									</span>
								</label>
								<input type="text" name="prreq_name" id="prreq_name" className="form-control single-line-input" required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Name', this.state.prreq_name, 'required')}
							</div>
						</Col>
					
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Mobile<span className="text-danger"></span></label>
								<input type="number" name="prreq_mob" id="prreq_mob" className="form-control single-line-input"  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Mobile', this.state.prreq_mob, '')}
							</div>
						</Col>
						
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Email<span className="text-danger"></span></label>
								<input type="email" name="prreq_mail" id="prreq_mail" className="form-control single-line-input"  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Email', this.state.prreq_mail, '')}
							</div>
						</Col>
						
						<Col md={12} >
							<div className="form-group" >
								 <div className="md-form">
								  <textarea type="text" id="form7" className="md-textarea form-control" name="prreq_con" rows="5"  required placeholder="Enter Your Content here.." onChange={this.handleInputChange} onBlur={this.handleInputBlur} ></textarea>
								  {this.validator.message('Content', this.state.prreq_con, 'required')}
								</div>
							</div>
						</Col>
						
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Save" loadingText="Saving.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
				  </Modal.Body>
				</Modal>
				  <Modal show={this.state.editPrayreq} className="modal-popup"   size="md" onHide={()=>{this.setState({editPrayreq:false})}} >
				  <Modal.Header closeButton>
					<Modal.Title>Update Testimonial</Modal.Title>
				  </Modal.Header>
				  <Modal.Body>
						<form action="" method="post" encType="multipart/form-data" id="frmPrreqUp" onSubmit={this.handleUpdateSubmit} >
				<input type="hidden" name="churchid" value={this.state.company} />
				<input type="hidden" name="userid" value={this.state.user} />
				<input type="hidden" name="pray_id" value={this.state.pray_id} />
				<input type="hidden" name="pray_showname" value={this.state.showname} />
					<Row>
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" style={{width : '100%'}}>Name<span className="text-danger">*</span>
									<span style={{float : 'right'}}>
										<input type="checkbox" name="showname" checked={this.state.showname == 1 ? true : false} onChange={this.handleChkChange} />&nbsp;Hide Name
									</span>
								</label>
								<input type="text" name="prreq_name" id="prreq_name" className="form-control single-line-input" value={this.state.prreq_name} required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Name', this.state.prreq_name, 'required')}
							</div>
						</Col>
					
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Mobile<span className="text-danger"></span></label>
								<input type="number" name="prreq_mob" id="prreq_mob" className="form-control single-line-input" value={this.state.prreq_mob}  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Mobile', this.state.prreq_mob, '')}
							</div>
						</Col>
						
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Email<span className="text-danger"></span></label>
								<input type="email" name="prreq_mail" id="prreq_mail" className="form-control single-line-input"  value={this.state.prreq_mail} placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Email', this.state.prreq_mail, '')}
							</div>
						</Col>
						
							
						
						<Col md={12} >
							<div className="form-group" >
								 <div className="md-form">
								  <textarea type="text" id="form7" className="md-textarea form-control" value={this.state.prreq_con} name="prreq_con" rows="5"  required placeholder="Enter Your Content here.." onChange={this.handleInputChange} onBlur={this.handleInputBlur} ></textarea>
								  {this.validator.message('Content', this.state.prreq_con, 'required')}
								</div>
							</div>
						</Col>
						
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Status<span className="text-danger"></span></label>
								 <select name="test_status" id="test_status" className="form-control single-line-input"  value={this.state.test_status} onChange={this.handleInputChange} onBlur={this.handleInputBlur} >
								{this.praystatus.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Email', this.state.test_status, '')}
							</div>
						</Col>
						
						
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Update" loadingText="Updating.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
				  </Modal.Body>
				</Modal>
				</Row>
			</div>
		);
	}
	
}
export default Testimonials;


