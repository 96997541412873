import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PageTitle from '../../PageTitle';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class UpdateMember extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			country : 'India',
			region : 'Tamil Nadu',
			headname : '',
			f_head_name : '',
			f_head_addr : '',
			f_head_age : '',
			f_head_whatsapp : '',
			f_head_area : '',
			f_head_city : '',
			f_head_dob  : '',
			f_head_gen  : '',
			f_head_edu  : '',
			f_head_occ  : '',
			f_head_phone : '',
			f_head_place : '',
			f_head_mail : '',
			f_head_mob : '',
			f_head_pin : '',
			f_head_remarks : '',
			f_mem_relation : '',
			member_id : '',
			company : Reqst.getTokenData('companyid','cp'),
			memberlist : false,
			zonallist : [],
			userrole : Reqst.getTokenData('role','data'),
			user : Reqst.getTokenData('id','data')
		}
		this.validator = new SimpleReactValidator();
		this.handleFormSubmit= this.handleFormSubmit.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	selectCountry (val) {
		this.setState({ country: val });
	}
 
	selectRegion (val) {
		this.setState({ region: val });
	}

	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleFormSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			this.setState({showLoader:true});
			var data = Sanitize.objectifyForm($("#frmMemUp").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('family/updatefamilymember/',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false});	
					swal("Success",res['data'].msg,"success");
					this.getdata();
		            this.zoanldata();
					
				}			
				else
				{
					this.setState({showLoader: false});	
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	componentDidMount(){
		
		this.getdata();
		this.zoanldata();
		
	}
	
	zoanldata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getchurchzonals/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({zonallist : res});
			}
		});
	}
	
	getdata(){
		 $("#showLoader").show();
		Reqst.GetData('family/getchurchfamilyheadbyid/'+this.props.match.params.id).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({
					member_id : res[0]['id'],
					headname : res[0]['headname'],
					f_head_name : res[0]['name'],
					f_head_addr : res[0]['address'],
					f_head_area : res[0]['area'],
					f_head_city : res[0]['city'],
					f_head_dob  : res[0]['dob'],
					f_head_age  : res[0]['age'],
					f_head_gen  : res[0]['gender'],
					f_head_edu  : res[0]['qualification'],
					f_head_occ  : res[0]['occupation'],
					f_head_phone : res[0]['phone'],
					f_head_place : res[0]['place'],
					f_head_mail : res[0]['email'],
					f_head_mob : res[0]['mobile'],
					f_head_pin : res[0]['pincode'],
					f_head_remarks : res[0]['note'],
					f_head_whatsapp : res[0]['whatsapp'],
					country : res[0]['country'],
					f_mem_relation :  res[0]['relationship'],
					region : res[0]['state']
				});
			}
		});
	}
	
	

	render(){
		
		const { country, region } = this.state;
		
		$("#whatsapp_numchange").click(function(){

				    var mobile = $("#f_head_mob").val();
					$("#f_head_whatsapp").val(mobile);
	
			});
		
		
		$("#f_head_dob").change(function(){

				    var dob = $(this).val();
					var fields = dob.split('-');
					var doby = fields[0];
					console.log(doby);
					var today = new Date();
					var dd = today.getDate();
					var mm = today.getMonth()+1; 
					var yyyy = today.getFullYear();
					var age = parseInt(yyyy) - parseInt(doby);
					$("#f_head_age").val(age);
	
			});
		
		this.genders = [
		{value : '', name : '-Select-'},
		{value : 'Male', name : 'Male'},
		{value : 'Female', name : 'Female'}
	   ];
	   
	   this.education = [
		{value : '', name : '-Select-'},
		{value : 'Illiterate', name : 'Illiterate'},
		{value : 'Primary', name : 'Primary'},
		{value : 'Higher', name : 'Higher'},
		{value : 'Secondary', name : 'Secondary'},
		{value : 'Diploma', name : 'Diploma'},
		{value : 'UG', name : 'UG'},
		{value : 'PG', name : 'PG'}
	   ];
	   
	   	this.relation = [
		{value : '', name : '-Select-'},
		{value : 'Husband', name : 'Husband'},
		{value : 'Wife', name : 'Wife'},
		{value : 'Son', name : 'Son'},
		{value : 'Daughter', name : 'Daughter'},
		{value : 'Father', name : 'Father'},
		{value : 'Mother', name : 'Mother'},
		{value : 'Grandson', name : 'Grandson'},
		{value : 'Granddaughter', name : 'Granddaughter'},
		{value : 'Son-In-Law', name : 'Son-In-Law'},
		{value : 'Daughter-In-Law', name : 'Daughter-In-Law'}
	   ];
	   
	   this.occupation = [
		{value : '', name : '-Select-'},
		{value : 'Pastor', name : 'Pastor'},
		{value : 'Business', name : 'Business'},
		{value : 'Doctor', name : 'Doctor'},
		{value : 'Engineer', name : 'Engineer'},
		{value : 'Farmer', name : 'Farmer'},
		{value : 'Govt/Pvt Service', name : 'Govt/Pvt Service'},
		{value : 'Labour', name : 'Labour'},
		{value : 'Lawyer', name : 'Lawyer'},
		{value : 'Professor', name : 'Professor'},
		{value : 'Professional', name : 'Professional'},
		{value : 'Retired', name : 'Retired'},
		{value : 'Teacher', name : 'Teacher'},
		{value : 'Unemployed', name : 'Unemployed'}
	   ];
		
		var areas = this.state.zonallist;
		
		if(this.state.userrole!='admin' && this.state.userrole!='staff')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
	   if(this.state.memberlist)
		{
			return(<Redirect to="/family/memberlist" />);
		}
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Update Member</title>
				</Helmet>
				
				<PageTitle title="Update Member" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({memberlist:true})}} >
							<i className="fa fa-arrow-left"></i> Back
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
				<form action="" method="post" encType="multipart/form-data" id="frmMemUp" onSubmit={this.handleFormSubmit} >
				<input type="hidden" name="userid" value={this.state.user} />
				<input type="hidden" name="memid" value={this.state.member_id} />
				<input type="hidden" name="region" value={this.state.region} />
				<input type="hidden" name="country" value={this.state.country} />
					<Row>
					
					<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Family Head<span className="text-danger"></span></label>
								<input type="text" name="f_head_id" id="f_head_id" className="form-control single-line-input" value={this.state.headname}  readOnly />
								 
							</div>
						</Col>
						
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Name<span className="text-danger">*</span></label>
								<input type="text" name="f_head_name" id="f_head_name" className="form-control single-line-input" value={this.state.f_head_name}   required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Name', this.state.f_head_name, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Relation<span className="text-danger">*</span></label>
								 <select name="f_mem_relation" onChange={this.handleInputChange} value={this.state.f_mem_relation}  id="f_mem_relation" required className="form-control single-line-input" >
								{this.relation.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Relation', this.state.f_mem_relation, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Gender<span className="text-danger">*</span></label>
								 <select name="f_head_gen" onChange={this.handleInputChange} id="f_head_gen" value={this.state.f_head_gen} required className="form-control single-line-input" >
								{this.genders.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Gender', this.state.f_head_gen, 'required')}
							</div>
						</Col>
						<Col md={3} >
							<div className="form-group" >
								<label className="label-text" >DOB<span className="text-danger"></span></label>
								<input type="date" name="f_head_dob" id="f_head_dob" className="form-control single-line-input" value={this.state.f_head_dob}  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('DOB', this.state.f_head_gen, '')}
							</div>
						</Col>
						<Col md={3} >
							<div className="form-group" >
								<label className="label-text" >Age<span className="text-danger"></span></label>
								<input type="number" name="f_head_age" id="f_head_age" className="form-control single-line-input" value={this.state.f_head_age}  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('DOB', this.state.f_head_gen, '')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Education<span className="text-danger"></span></label>
								 <select name="f_head_edu" onChange={this.handleInputChange} id="f_head_edu" value={this.state.f_head_edu} required className="form-control single-line-input" >
								{this.education.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Education', this.state.f_head_edu, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Occupation<span className="text-danger">*</span></label>
								 <select name="f_head_occ" onChange={this.handleInputChange} id="f_head_occ" value={this.state.f_head_occ} required className="form-control single-line-input" >
								{this.occupation.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Occupation', this.state.f_head_occ, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Area<span className="text-danger">*</span></label>
								 <select name="f_head_area" onChange={this.handleInputChange} id="f_head_area" value={this.state.f_head_area} required className="form-control single-line-input" >
								  <option value="">Select Area</option>
									{
										areas.map(function (item) {
											return <option value={item.id }>{item.name}</option>;
										})
									}
							 </select>
								 {this.validator.message('Area', this.state.f_head_area, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Place<span className="text-danger"></span></label>
								<input type="text" name="f_head_place" id="f_head_place" className="form-control single-line-input" value={this.state.f_head_place}  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Place', this.state.f_head_place, '')}
							</div>
						</Col>
						
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >City<span className="text-danger">*</span></label>
								<input type="text" name="f_head_city" id="f_head_city" className="form-control single-line-input"  value={this.state.f_head_city} required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('City', this.state.f_head_city, 'required')}
							</div>
						</Col>
						
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Address<span className="text-danger">*</span></label>
								<input type="text" name="f_head_addr" id="f_head_addr" className="form-control single-line-input" value={this.state.f_head_addr}  required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Address', this.state.f_head_addr, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Pincode<span className="text-danger">*</span></label>
								<input type="number" name="f_head_pin" id="f_head_pin" className="form-control single-line-input" value={this.state.f_head_pin} required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Pincode', this.state.f_head_pin, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Country<span className="text-danger">*</span></label>
								<CountryDropdown value={country} className="form-control single-line-input" onChange={(val) => this.selectCountry(val)} id="con" />
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >State<span className="text-danger">*</span></label>
								<RegionDropdown country={country} className="form-control single-line-input" value={region} onChange={(val) => this.selectRegion(val)} id="con" />
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Mobile<span className="text-danger">*</span></label>
								<input type="number" name="f_head_mob" id="f_head_mob" className="form-control single-line-input" value={this.state.f_head_mob}  required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Mobile', this.state.f_head_mob, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Email<span className="text-danger"></span></label>
								<input type="email" name="f_head_mail" id="f_head_mail" className="form-control single-line-input"  value={this.state.f_head_mail}  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Email', this.state.f_head_mail, '')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Phone<span className="text-danger"></span></label>
								<input type="number" name="f_head_phone" id="f_head_phone" className="form-control single-line-input" value={this.state.f_head_phone}   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Phone', this.state.f_head_phone, '')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Whats App Number <a href="javascript::;" className="text-primary" id="whatsapp_numchange">(Use the Mobile Number)</a></label>
								<input type="number" name="f_head_whatsapp" id="f_head_whatsapp" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Phone', this.state.f_head_whatsapp, '')}
							</div>
						</Col>
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Remarks<span className="text-danger"></span></label>
								<input type="textarea" name="f_head_remarks" id="f_head_remarks" value={this.state.f_head_remarks} className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Website', this.state.f_head_remarks, '')}
							</div>
						</Col>
						
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Update" loadingText="Updating.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
			</div>
		);
	}
	
}
export default UpdateMember;


