import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PageTitle from '../../PageTitle';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class ChurchAdd extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			country : 101,
			region : 1,
			countrylist : [],
			statelist : [],
			distictlist : [],
			churchlist : false,
			userrole : Reqst.getTokenData('role','data'),
			user : Reqst.getTokenData('id','data')
		}
		this.validator = new SimpleReactValidator();
		this.handleFormSubmit= this.handleFormSubmit.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleCountryChange = this.handleCountryChange.bind(this);
		this.handleStateChange = this.handleStateChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	
	handleCountryChange(e){
		this.state.country = e.target.value;
		this.statedata();
	}
	
	handleStateChange(e){
		this.state.region = e.target.value;
		this.districtdata();
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	selectCountry (val) {
		this.setState({ country: val });
	}
 
	selectRegion (val) {
		this.setState({ region: val });
	}

	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleFormSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			this.setState({showLoader:true});
			var data = Sanitize.objectifyForm($("#frmChurchadd").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('setup/addchurchbysuperadmin/',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false});	
					document.getElementById("frmChurchadd").reset();
					swal("Success",res['data'].msg,"success");
					
				}
                else if(res['data'].status=='2')
				{
					this.setState({showLoader: false});	
					swal("Error",res['data'].msg,"error");
					
				}				
				else
				{
					this.setState({showLoader: false});	
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	componentDidMount(){
		
		this.countrydata();
		this.statedata();
		this.districtdata();
		
	}
	
	statedata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getstatebycountry/'+this.state.country).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({statelist : res});
			}
		});
	}
	
	districtdata(){
		 $("#showLoader").show();
		 this.setState({distictlist : []});
		Reqst.GetData('setup/getdistrictbystate/'+this.state.region).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({distictlist : res});
			}
		});
	}
	
	countrydata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getcountries/').then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({countrylist : res});
			}
		});
	}

	render(){
		
		//const { country, region } = this.state;
		
		var clist = this.state.countrylist;
		var slist = this.state.statelist;
		var dlist = this.state.distictlist;
		
		if(this.state.userrole!='superadmin')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
	   if(this.state.churchlist)
		{
			return(<Redirect to="/manage/churches" />);
		}
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Add Church</title>
				</Helmet>
				
				<PageTitle title="Add Church" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({churchlist:true})}} >
							<i className="fa fa-arrow-left"></i> Back
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
				<form action="" method="post" encType="multipart/form-data" id="frmChurchadd" onSubmit={this.handleFormSubmit} >
				<input type="hidden" name="userid" value={this.state.user} />
			
					<Row>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Name of the Church <span className="text-danger">*</span></label>
								<input type="text" name="ch_name" id="ch_name" className="form-control single-line-input"  required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Name', this.state.ch_name, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Place<span className="text-danger"></span></label>
								<input type="text" name="ch_place" id="ch_place" className="form-control single-line-input"  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Place', this.state.ch_place, '')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >City<span className="text-danger">*</span></label>
								<input type="text" name="ch_city" id="ch_city" className="form-control single-line-input"  required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church City', this.state.ch_city, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Address<span className="text-danger">*</span></label>
								<input type="text" name="ch_addr" id="ch_addr" className="form-control single-line-input"  required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Address', this.state.ch_addr, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Pincode<span className="text-danger">*</span></label>
								<input type="number" name="ch_pin" id="ch_pin" className="form-control single-line-input" required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Pincode', this.state.ch_pin, 'required')}
							</div>
						</Col>
						
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Country<span className="text-danger">*</span></label>
								 <select name="country" onChange={this.handleCountryChange} value={this.state.country} id="country" required className="form-control single-line-input" >
								  <option value="">Select Country</option>
									{
										clist.map(function (item) {
											return <option value={item.id }>{item.name}</option>;
										})
									}
							 </select>
								 {this.validator.message('Country', this.state.country, 'required')}
							</div>
						</Col>
						
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >State<span className="text-danger">*</span></label>
								 <select name="region" onChange={this.handleStateChange} value={this.state.region} id="region" required className="form-control single-line-input" >
								  <option value="">Select State</option>
									{
										slist.map(function (item) {
											return <option value={item.id }>{item.name}</option>;
										})
									}
							 </select>
								 {this.validator.message('State', this.state.region, 'required')}
							</div>
						</Col>
						
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >District<span className="text-danger">*</span></label>
								 <select name="ch_dist" onChange={this.handleInputChange} id="ch_dist" required className="form-control single-line-input" >
								  <option value="">Select District</option>
									{
										dlist.map(function (item) {
											return <option value={item.id }>{item.name}</option>;
										})
									}
							 </select>
								 {this.validator.message('District', this.state.ch_dist, 'required')}
							</div>
						</Col>
						
						
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Phone<span className="text-danger">*</span></label>
								<input type="number" name="ch_phone" id="ch_phone" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Phone', this.state.ch_phone, '')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Mobile<span className="text-danger">*</span></label>
								<input type="number" name="ch_mobile" id="ch_mobile" className="form-control single-line-input"  required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Mobile', this.state.ch_mobile, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Email<span className="text-danger">*</span></label>
								<input type="email" name="ch_mail" id="ch_mail" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Email', this.state.ch_mail, '')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Website<span className="text-danger">*</span></label>
								<input type="url" name="ch_web" id="ch_web" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Website', this.state.ch_web, '')}
							</div>
						</Col>
						
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Save" loadingText="Saving.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
			</div>
		);
	}
	
}
export default ChurchAdd;


