import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';

import { Row, Col, Nav, Tab, Table } from 'react-bootstrap';
import SimpleLineIcon from 'react-simple-line-icons';
import * as FeatherIcon from 'react-feather';

import MobilePrepaid from './MobilePrepaid';

const styles = {
	payIconStyle: {
		float: 'right',
		fontSize: '20px',
		fontWeight: '550',
	}
}

class Pay extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
	}
	
	componentDidMount(){
		
	}


	render(){
	
		return(
			<div className="payContainer" >
				<Tab.Container id="left-tabs-example" defaultActiveKey="mobile_prepaid" style={{ width: '100%' }} >
					<Row>
						<Col sm={2}>
						<Nav variant="pills" className="flex-column">
							<Nav.Item>
							<Nav.Link eventKey="mobile_prepaid">
								Mobile Recharge
								<SimpleLineIcon name="screen-smartphone" style={styles.payIconStyle} />
							</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="mobile_postpaid">
									Mobile Postpaid
									<SimpleLineIcon name="ghost" style={styles.payIconStyle} />
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="dth">
									 DTH Recharge
									 <FeatherIcon.Airplay size={20} style={{float:'right' }} />
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="landline">
									Land Line
									<FeatherIcon.Cast size={20} style={{float:'right' }} />
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="broadband">
									Brod Band
									<FeatherIcon.Wifi size={20} style={{float:'right' }} />
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="elect">
									Electricity
									<FeatherIcon.Sliders size={20} style={{float:'right' }} />
								</Nav.Link>
							</Nav.Item>
						</Nav>
						</Col>
						<Col sm={10}>
						<Tab.Content>
							<Tab.Pane eventKey="mobile_prepaid">

								<MobilePrepaid />

							</Tab.Pane>
							<Tab.Pane eventKey="mobile_postpaid">
							
							</Tab.Pane>
						</Tab.Content>
						</Col>
					</Row>
					</Tab.Container>
			</div>
		);
	}
	
}
export default Pay;

