import React, { Component } from 'react';
import * as FeatherIcon from 'react-feather';

const styles = {
	iconStyle: {
		marginTop: '-3px',
		marginRight: '6px'
	}
}

/** menu for dashboard page **/
export const Menu = [
	{
		value:'Dashboard',
		path:'/dashboard',
		icon:  <FeatherIcon.PieChart size={17} style={styles.iconStyle} />
	},
	{
		value:'Church Profile',
		path:'/setup/viewchurch',
		icon: <FeatherIcon.Home size={17} style={styles.iconStyle} />
	},
	{
		value:'Mass Timing',
		path:'/setup/viewmass',
		icon: <FeatherIcon.Music size={17} style={styles.iconStyle} />
	},
	{
		value:'Announcements',
		path:'/appcontent/announcements',
		icon: <FeatherIcon.Mic size={17} style={styles.iconStyle} />
	},
	{
		value:'Event Reports',
		path:'/appcontent/events',
		icon: <FeatherIcon.Archive size={17} style={styles.iconStyle} />
	},
	{
		value:'Today Sharings',
		path:'/appcontent/dailymanna',
		icon: <FeatherIcon.Calendar size={17} style={styles.iconStyle} />
	},
	{
		value:'Prayer Requests',
		path:'/appcontent/prayerrequests',
		icon: <FeatherIcon.Inbox size={17} style={styles.iconStyle} />
	},
	{
		value:'Testimonials',
		path:'/appcontent/testimonials',
		icon: <FeatherIcon.Award size={17} style={styles.iconStyle} />
	},
	{
		value:'Change Password',
		path:'/myprofile',
		icon: <FeatherIcon.Edit size={17} style={styles.iconStyle} />
	}
	/* {
		value:'Users',
		path:'/user',
		icon: <FeatherIcon.User size={17} style={styles.iconStyle} />,
		submenu :[
			{
				value:'Users',
				path:'/user/churchusers'
			}
		]
	} */
];

/** menu for dashboard page **/
export const AdminMenu = [
	{
		value:'Dashboard',
		path:'/dashboard',
		icon:  <FeatherIcon.PieChart size={17} style={styles.iconStyle} />
	},
	{
		value:'Manage',
		path:'/manage',
		icon: <FeatherIcon.Settings size={17} style={styles.iconStyle} />,
		submenu :[
			{
				value:'Add Church',
				path:'/manage/addchurch'
			},
			{
				value:'Churches',
				path:'/manage/churches'
			},
			{
				value:'Add User',
				path:'/manage/adduser'
			},
			{
				value:'Users',
				path:'/manage/users'
			},
		]
	}
];