import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';
import LoadingBtn from '../../../tie/LoadingBtn';
import localIpUrl from 'local-ip-url';

import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
import swal from 'sweetalert';

import './login.css';

class Login extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			isRedirect:false,
		}
		this.validator = new SimpleReactValidator();
		this.handleLoginChange = this.handleLoginChange.bind(this);
		this.handleLoginBlur = this.handleLoginBlur.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		//$("body").css("background", "linear-gradient(90deg,#70d467 0,#1a74bf)");
	}
	
	handleLoginChange(e){
		this.setState({[e.target.name]: e.target.value});
	}

	handleLoginBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}

	/**
	* to handle login form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleFormSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			this.setState({showLoader:true});
			var data = Sanitize.objectifyForm($("#frmLogin").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('login/',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					Sanitize.setStorage('catho_data',res['data'].data);
					Sanitize.setStorage('iscatholic_login','yes');
					this.setState({showLoader: false,isRedirect: true});
				}	
				else
				{
					this.setState({showLoader: false,hasOtpVerify: false});	
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}

	render(){
	
		if(this.state.isRedirect)
		{
			return(<Redirect to="/dashboard" />);
		}
		
		return(
			<div>
			 <div className="container login-container" >
				<div className="row">
				  
				  <div className="col-md-4 mx-auto">
					<Row style={{ marginTop: '55px' }} >
						<Col md={{span:8, offset: 2}} >
							<center>
								<h4>My Catholic Church </h4>
							</center>
						</Col>
					</Row>
					<Row style={{ marginTop: '20px' }} >
						<Col md={{span:8, offset: 2}} >
							
							<h5 className="card-title text-center">Sign In</h5>
						<form id="frmLogin" className="form-signin" method="post" action="" encType="multipart/form-data" onSubmit={this.handleFormSubmit} >
						  <div className="form-label-group">
								<label className="label-text" >Login ID *</label>
								<input type="text" name="i__userName" id="i__userName" className="form-control single-line-input" required placeholder="948xxxxx14" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} />
								 {this.validator.message('Login ID', this.state.i__userName, 'required')}
						  </div>

						  <div className="form-label-group">
								<label className="label-text" >Password *</label>
								<input type="password" name="i__passWord" id="i__passWord" className="form-control single-line-input" required placeholder="********" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur}  />
								{this.validator.message('Password', this.state.i__passWord, 'required')}
								<br/>
								<Link to="/forgot-password" style={{fontSize: '15px', float:'right' }} >Forgot Password?</Link>
								<br/>
						  </div>

					
						  <LoadingBtn className="btn btn-md btn-primary btn-block text-uppercase" type="submit" text="Sign in" loadingText="Authendicating.." loading={this.state.showLoader} >
						  
						  </LoadingBtn>
						  
						
						  {/*<hr className="my-4" />
						  <button className="btn btn-lg btn-google btn-block text-uppercase" type="submit"><i className="fab fa-google mr-2"></i> Sign in with Google</button>
	<button className="btn btn-lg btn-facebook btn-block text-uppercase" type="submit"><i className="fab fa-facebook-f mr-2"></i> Sign in with Facebook</button>*/}
						</form>
						
						</Col>
					</Row>
					<br/>
						<Col md={12} >
						<div className="text-center" >
							<p style={{ fontSize: '13px' }} >
								Powered by <a href="http://peacesoft.in/" target="_blank" > Peace Soft Technologies </a>
							</p>
						</div>
							
						</Col>
					
				  </div>
				</div>
			  </div>
			 </div>
		);
	}
	
}
export default Login;

