import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';

import SimpleLineIcon from 'react-simple-line-icons';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
import Pagination from "react-js-pagination";

import PageTitle from '../PageTitle';
import AddProduct from './AddProduct';

class Products extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			addProduct: false,
		}
		
	}
	
	componentDidMount(){
		
	}


	render(){
	
		return(
			<div className="container payContainer" >
				
				<PageTitle title="Products" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<Link to="/products/category" className="btn btn-sm btn-outline-secondary">
							<FeatherIcon.Server size={14} /> Cateogries
						</Link>
						<Link to="/" className="btn btn-sm btn-outline-secondary">
							<FeatherIcon.ShoppingBag size={14} /> Items
						</Link>
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({addProduct:true})}} >
							<FeatherIcon.CheckSquare size={14} /> Add Product
						</button>
						<button className="btn btn-sm btn-outline-secondary">Export</button>
					  </div>
					  {/*<button className="btn btn-sm btn-outline-secondary dropdown-toggle">
						<span data-feather="calendar"></span>
						This week
					  </button>*/}
					</div>
				</PageTitle>
							  
				<Row>
					<Col md={12} >
						<Table hover bordered  >
							<thead>
								<tr>
									<th>S.No</th>
									<th>Product Id</th>
									<th>Product Name</th>
									<th>Category</th>
									<th>Item</th>
									<th>MRP</th>
									<th>Sale Rate</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1.</td>
									<td>S0001</td>
									<td>Computer Monitor DELL</td>
									<td>Electronics</td>
									<td>Monitors</td>
									<td>7500.00</td>
									<td>7000.00</td>
									<td></td>
								</tr>
								<tr>
									<td>2.</td>
									<td>S0002</td>
									<td>DELL Keyboard & Mouse </td>
									<td>Electronics</td>
									<td>Monitors</td>
									<td>1500.00</td>
									<td>1500.00</td>
									<td></td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
				
				<Modal show={this.state.addProduct} className="modal-popup"   size="lg" onHide={()=>{this.setState({addProduct:false})}} >
				  <Modal.Header closeButton>
					<Modal.Title>Add Product</Modal.Title>
				  </Modal.Header>
				  <Modal.Body>
						<AddProduct />
				  </Modal.Body>
				</Modal>
				
			</div>
		);
	}
	
}
export default Products;

