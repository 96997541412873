import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';
import LoadingBtn from '../../../tie/LoadingBtn';

import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import { Row, Col, Input, Form, Card, InputGroup, Carousel, Modal, Fade, utils } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
import BannerImage from '../../../assets/img/api-integration.png';
import Logo from '../../../assets/img/dbs300.png';
import SliderBackground from '../../../assets/img/slider_350x150.png';

import * as ReviewData from './ReviewData';

const styles={
	firstRowStyle:{
		backgroundImage:'linear-gradient(#f9aa1f , #c7418c)',
		height:'calc(100vh)',
	},
	logoStyle:{
		width: '100px',
		marginBottom: '15px',
	},
	textDiv:{
		color:'#fff',
		marginTop: '6%',
	},
	secondRow: {
		marginTop: '50px',
	},
	dividerStyle: {
		width: '90%',
		marginLeft: '25px',
	},
	btnSignup: {
		marginTop: '80px',
		fontSize: '20px;',
		 width:'300px',
	},
	iconBottom: {
		fontSize: '40px',
		fontWeight: '500',
		color: '#524e4e',
	},
	modalCloseIcon: {
		textAlign:'right',
		marginBottom: '10px',
		cursor: 'pointer',
	},
	resendOtp: {
		float: 'right',
		fontSize: '13px',
	}
}

class LoginBanner extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showModal: false,
		}

	}
	
	componentDidMount(){

	}
	

	render(){

		
	
		return(
			<div>

				<Row>
					<Col md={12} >
						<div className="text-center" style={styles.textDiv} >
								<img src={Logo} style={styles.logoStyle} />
							<h2>What's New?</h2>
							<h5 style={{color:'#86eafb'}} >DBS Wallet</h5>
							<p>Make Recharge & Payments instantly.</p>
							<div className="break_line" style={styles.dividerStyle} >
								<div className="break_line_text">SIGN UP</div>
							</div>
							<p>
								<center>
									<Link to="/register" className="btn btn-info btn-block" style={styles.btnSignup} >
										Sign Up and Start Earning
									</Link>
								</center>
							</p>
						</div>
					</Col>
					<Col md={12} >
						
						<Carousel>
						{
							ReviewData.data.map((item,i)=>{
							  return <Carousel.Item>
								<img className="d-block w-100" src={SliderBackground} />
								<Carousel.Caption>
								  <h3>{item.title}</h3>
								  <p>{item.content}</p>
								</Carousel.Caption>
							  </Carousel.Item>;
							})
						}							  
						</Carousel>
						
					</Col>
					
				</Row>
					
			</div>
		);
	}
	
}
export default LoginBanner;

