import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn';
import noImg from '../../../../assets/img/noimage_200.png';
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PageTitle from '../../PageTitle';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup,OverlayTrigger , Tooltip } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
import {Cropper} from 'react-image-cropper';
import ReactFileReader from 'react-file-reader';


class AddChurch extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			country : 101,
			region : 1,
			company : Reqst.getTokenData('id','data'),
			user : Reqst.getTokenData('id','data'),
			userrole : Reqst.getTokenData('role','data'),
			countrylist : [],
			statelist : [],
			distictlist : [],
			ch_id : '',
			ch_name : '',
			ch_place : '',
			ch_city : '',
			ch_addr : '',
			ch_pin : '',
			ch_phone : '',
			ch_mobile : '',
			ch_mail : '',
			show: false,
			ch_web : '',
			files : '',
			churchview : false,
			photoname : '',
			croppedImage: '',
			ch_mrghall : 0,
			ch_councel : 0,
			ch_legal : 0,
			ch_adorchapel : 0,
			ch_bookstall : 0,
		    ch_med : 0,
			ch_mrgbue : 0,
			files : '',
			photoname : '',
			files1 : '',
			photoname1 : '',
			files2 : '',
			photoname2 : '',
			files3 : '',
			photoname3 : '',
			ispasalica : 0,
			isshrine : 0,
			iscathedral : 0,
			ispc : 0,
			issubsta : 0
		}
		this.validator = new SimpleReactValidator();
		this.handleFiles = this.handleFiles.bind(this);
		this.handleFormSubmit= this.handleFormSubmit.bind(this);
		this.handleCountryChange = this.handleCountryChange.bind(this);
		this.handleStateChange = this.handleStateChange.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleCropChange = this.handleCropChange.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
		this.handleFiles1 = this.handleFiles1.bind(this);
		this.handleFiles2 = this.handleFiles2.bind(this);
		this.handleFiles3 = this.handleFiles3.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handlefileRemove = this.handlefileRemove.bind(this);
		this.handlefileRemove1 = this.handlefileRemove1.bind(this);
		this.handlefileRemove2 = this.handlefileRemove2.bind(this);
		this.handlefileRemove3 = this.handlefileRemove3.bind(this);
		this.handleCheckChange = this.handleCheckChange.bind(this);
		this.handleDatafullRemove = this.handleDatafullRemove.bind(this);
	}
	
	
	handlefileRemove(){
		
		this.setState({ files: '',photoname : ''});
	
	}
	
	handlefileRemove1(){
		
		this.setState({ files1: '',photoname1 : ''});
	
	}
	
	handlefileRemove2(){
		
		this.setState({ files2: '',photoname2 : ''});
	
	}
	
	handlefileRemove3(){
		
		this.setState({ files3: '',photoname3 : ''});
	
	}
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	
	handleFiles(files){
		this.setState({ files: files.base64,photoname : files.fileList[0]['name'],show: true});
	}
	
	handleCropChange(crop){
		this.setState({crop:crop});
	}
	
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	
	handleFiles(files){
		this.setState({ files: files.base64,photoname : files.fileList[0]['name']});
	}
	
	handleFiles1(files){
		this.setState({ files1: files.base64,photoname1 : files.fileList[0]['name']});
	}
	
	handleFiles2(files){
		this.setState({ files2: files.base64,photoname2 : files.fileList[0]['name']});
	}
	
	handleFiles3(files){
		this.setState({ files3: files.base64,photoname3 : files.fileList[0]['name']});
	}
	
	handleCheckChange(e){
		
		if(e.target.checked){
			
			this.setState({[e.target.name]: 1});
			
		}
		else {
			
			this.setState({[e.target.name]: 0});
		
		}
	}
	
	
	handleDatafullRemovefn(field){
		
		this.state.field = field;
		this.removefile();
		
	}
	
	handleDatafullRemove = (field): void => {
		
		 confirmAlert({
		  title: 'Confirm to Delete',
		  message: 'Are you sure to Delete this File ?',
		  buttons: [
			{
			  label: 'No'
			},
			{
			  label: 'Yes',
			  onClick: () => this.handleDatafullRemovefn(field)
			}
		  ]
		})
  };
	
	handleClose(){
		this.setState({show:false,croppedImage: this.cropper.crop()});
	}
	
	handleCountryChange(e){
		this.state.country = e.target.value;
		this.statedata();
	}
	
	handleStateChange(e){
		this.state.region = e.target.value;
		this.districtdata();
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	selectCountry (val) {
		this.setState({ country: val });
	}
 
	selectRegion (val) {
		this.setState({ region: val });
	}

	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleFormSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			this.setState({showLoader:true});
			 $("#showLoader").show();
			var data = Sanitize.objectifyForm($("#frmChurchSet").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('setup/updatechurch/',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false});
                     $("#showLoader").hide();					
					this.setState({ files: '',photoname : ''});
					this.setState({ files1: '',photoname1 : ''});
					this.setState({ files2: '',photoname2 : ''});
					this.setState({ files3: '',photoname3 : ''});
					swal("Success",res['data'].msg,"success");
					this.getdata();
					
				}	
				else
				{
					this.setState({showLoader: false});
                      $("#showLoader").hide();						
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	componentDidMount(){
		
		this.getdata();
		
	}
	
	removefile(){
		 $("#showLoader").show();
		Reqst.GetData('setup/removechurchfile/'+this.state.ch_id+'/'+this.state.user+'/'+this.state.field).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res.status=='1'){
					swal("Success",res.msg,"success");
					this.getdata();
			}
			else{
				
					swal("Error",res['data'].msg,"error");
			}
		});
	}
	
	getdata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getpastorchurchdata/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({
					 ch_name : res[0]['name'],
					 ch_id : res[0]['id'],
					 ch_domain : res[0]['domain'],
					 ch_addr : res[0]['address'],
					 ch_pin : res[0]['pincode'],
					 country : res[0]['country'],
					 ch_dist : res[0]['diociese'],
					 region : res[0]['state'],
					 ch_phone : res[0]['phone'],
					 ch_mobile : res[0]['mobile'],
					 ch_mail : res[0]['email'],
					 ch_web : res[0]['website'],
					 ch_parish : res[0]['parish'],
					 ch_prist : res[0]['parishprist'],
					 ch_asstprist : res[0]['asstparishprist'],
					 ch_fams : res[0]['nooffamilies'],
					 ch_about : res[0]['content'],
					 ch_secmob : res[0]['secondarymobile'],
					 ch_geoip : res[0]['geoip'],
					 ch_fest_month : res[0]['festivaldays'],
					 ch_fest_day : res[0]['churchfestivaldate'],
					 ch_type : res[0]['type'],
					 ch_mrghall : res[0]['mrghall'],
					 ch_councel : res[0]['councelctr'],
					 ch_legal : res[0]['legalctr'],
					 ch_adorchapel : res[0]['adorationchapel'],
					 ch_bookstall : res[0]['bookstall'],
					 ch_med : res[0]['medicalctr'],
					 ch_mrgbue : res[0]['mrgbeuree'],
					 photo : res[0]['cimage1'],
					 photo1 : res[0]['cimage2'],
					 photo2 : res[0]['cimage3'],
					 photo3 : res[0]['cimage4'],
					 ispasalica : res[0]['ispasalica'],
					 isshrine : res[0]['isshrine'],
					 iscathedral : res[0]['iscathedtral'],
					 ispc : res[0]['ispc'],
					 issubsta : res[0]['issubstation']
				});
				
				if(this.state.ch_mrghall == '1'){
					
					$("#ch_mrghall").prop("checked", true);
				}
				
				if(this.state.ch_councel == '1'){
					
					$("#ch_councel").prop("checked", true);
				}
				
				if(this.state.ch_legal == '1'){
					
					$("#ch_legal").prop("checked", true);
				}
				
				if(this.state.ch_adorchapel == '1'){
					
					$("#ch_adorchapel").prop("checked", true);
				}
				if(this.state.ch_bookstall == '1'){
					
					$("#ch_bookstall").prop("checked", true);
				}
				
				if(this.state.ch_med == '1'){
					
					$("#ch_med").prop("checked", true);
				}
				
				if(this.state.ch_mrgbue == '1'){
					
					$("#ch_mrgbue").prop("checked", true);
				}
				
				
				
				if(this.state.ispasalica == '1'){
					
					$("#ispasalica").prop("checked", true);
				}
				
				if(this.state.isshrine == '1'){
					
					$("#isshrine").prop("checked", true);
				}
				
				if(this.state.iscathedral == '1'){
					
					$("#iscathedral").prop("checked", true);
				}
				
				if(this.state.ispc == '1'){
					
					$("#ispc").prop("checked", true);
				}
				
				if(this.state.issubsta == '1'){
					
					$("#issubsta").prop("checked", true);
				}
				
				this.countrydata();
				this.statedata();
				this.districtdata();
			}
		});
	}
	
	statedata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getstatebycountry/'+this.state.country).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({statelist : res});
			}
		});
	}
	
	districtdata(){
		 $("#showLoader").show();
		 this.setState({distictlist : []});
		Reqst.GetData('setup/getdistrictbystate/'+this.state.region).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({distictlist : res});
			}
		});
	}
	
	countrydata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getcountries/').then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({countrylist : res});
			}
		});
	}

	render(){
		
		
		$(".integer").keypress(function(e) {
	
		if (e.which != 8 && (e.which < 48 || e.which > 57) ) {
			//showAdvice(this, "Integer values only");
			return(false);
		}
     });
	 
	 $('.10digitonly').keypress(function(e) {
	
	if(e.which == 8) return true;
	
		var lets = $(this).val();
		if(lets.length ==10 )
			return false;
    }); 
	
		$(".integer").keyup(function(e) {
	
		if (e.which != 8 && (e.which < 48 || e.which > 57) ) {
			//showAdvice(this, "Integer values only");
			return(false);
		}
     });
	 
	 $('.10digitonly').keyup(function(e) {
	
	if(e.which == 8) return true;
	
		var lets = $(this).val();
		if(lets.length ==10 )
			return false;
    }); 
	
	
		$("#ch_mobile").change(function(){

				    var mob = $(this).val();
					var zerorval = mob.replace(/^0+/, "");
					$("#ch_mobile").val(zerorval);
	
			});
		
		
		   $("#ch_fest_day").change(function(){

				    var day = $(this).val();
					
					if(parseInt(day)>31){
						
						alert('Month Cannot be more than 31 Days');
						$("#ch_fest_day").val('');
					}
	
			});
	
		
		
	this.types = [
		{value : '', name : '-Select-'},
		{value : 'Basilica', name : 'Basilica'},
		{value : 'Shrine', name : 'Shrine'},
		{value : 'Cathedral', name : 'Cathedral'},
		{value : 'Parish Church', name : 'Parish Church'},
		{value : 'Sub Station', name : 'Sub Station'}
	   ];
	   
	   this.months = [
		{value : '', name : '-Select-'},
		{value : 'January', name : 'January'},
		{value : 'February', name : 'February'},
		{value : 'March', name : 'March'},
		{value : 'April', name : 'April'},
		{value : 'May', name : 'May'},
		{value : 'June', name : 'June'},
		{value : 'July', name : 'July'},
		{value : 'August', name : 'August'},
		{value : 'September', name : 'September'},
		{value : 'October', name : 'October'},
		{value : 'November', name : 'November'},
		{value : 'December', name : 'December'},
	   ];
		
		//const { country, region } = this.state;
		
		var clist = this.state.countrylist;
		var slist = this.state.statelist;
		var dlist = this.state.distictlist;
		
		if(this.state.churchview)
		{
			return(<Redirect to="/setup/viewchurch" />);
		}
		
		if(this.state.userrole!='admin')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
		let src = (this.state.files) ? this.state.files : 'https://www.freeiconspng.com/uploads/no-image-icon-11.PNG';
		
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Update Church Profile</title>
				</Helmet>
				
				<PageTitle title="Update Church Profile" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({churchview:true})}} >
							<i className="fa fa-arrow-left"></i>&nbsp;Back
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
				<form action="" method="post" encType="multipart/form-data" id="frmChurchSet" onSubmit={this.handleFormSubmit} >
				<input type="hidden" name="ch_id" value={this.state.ch_id} />
				<input type="hidden" name="userid" value={this.state.user} />
				 <input type="hidden" name="files" value={this.state.croppedImage} />
				  <input type="hidden" name="photoname" value={this.state.photoname} />
				  <input type="hidden" name="photo" value={this.state.photo} /> 
				  <input type="hidden" name="ch_mrgbue" value={this.state.ch_mrgbue} />
				 <input type="hidden" name="ch_mrghall" value={this.state.ch_mrghall} />
				  <input type="hidden" name="ch_councel" value={this.state.ch_councel} />
				  <input type="hidden" name="ch_legal" value={this.state.ch_legal} />
				  <input type="hidden" name="ch_adorchapel" value={this.state.ch_adorchapel} />
				  <input type="hidden" name="ch_bookstall" value={this.state.ch_bookstall} />
				  <input type="hidden" name="ch_med" value={this.state.ch_med} />
				   <input type="hidden" name="photoname" value={this.state.photoname} />
				  <input type="hidden" name="files" value={this.state.files} />
				   <input type="hidden" name="photoname1" value={this.state.photoname1} />
				   <input type="hidden" name="files1" value={this.state.files1} />
				   <input type="hidden" name="photoname2" value={this.state.photoname2} />
				   <input type="hidden" name="files2" value={this.state.files2} />
				   <input type="hidden" name="photoname3" value={this.state.photoname3} />
				   <input type="hidden" name="files3" value={this.state.files3} />
				   <input type="hidden" name="ispasalica" value={this.state.ispasalica} />
				 <input type="hidden" name="isshrine" value={this.state.isshrine} />
				  <input type="hidden" name="iscathedral" value={this.state.iscathedral} />
				  <input type="hidden" name="ispc" value={this.state.ispc} />
				  <input type="hidden" name="issubsta" value={this.state.issubsta} />
					
					<Row>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Name of the Church <span className="text-danger">*</span></label>
								<input type="text" name="ch_name" id="ch_name" className="form-control single-line-input" value={this.state.ch_name} required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Name', this.state.ch_name, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Type<span className="text-danger">*</span></label>&nbsp;&nbsp;&nbsp;&nbsp;
								<input type="checkbox" name="ispasalica" id="ispasalica"   placeholder="" onChange={this.handleCheckChange} onBlur={this.handleInputBlur} />	&nbsp;&nbsp;
									<label className="label-text" > Basilica   </label>

								&nbsp;&nbsp;
								<input type="checkbox" name="isshrine" id="isshrine"   placeholder="" onChange={this.handleCheckChange} onBlur={this.handleInputBlur} />
								&nbsp;&nbsp;
								<label className="label-text" > Shrine   </label>
								&nbsp;&nbsp;
								<input type="checkbox" name="iscathedral" id="iscathedral"   placeholder="" onChange={this.handleCheckChange} onBlur={this.handleInputBlur} />
								&nbsp;&nbsp;
								<label className="label-text" > Cathedral   </label>
								&nbsp;&nbsp;
								<input type="checkbox" name="ispc" id="ispc"   placeholder="" onChange={this.handleCheckChange} onBlur={this.handleInputBlur} />
								&nbsp;&nbsp;
								<label className="label-text" > Parish Church   </label>
								&nbsp;&nbsp;
								<input type="checkbox" name="issubsta" id="issubsta"   placeholder="" onChange={this.handleCheckChange} onBlur={this.handleInputBlur} />
								&nbsp;&nbsp;
								<label className="label-text" > Sub Station   </label>
								
								
							</div>
						</Col>
						{/*<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Domain<span className="text-danger"></span></label>
								<input type="text" name="ch_domain" id="ch_domain" className="form-control single-line-input" value={this.state.ch_domain} placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Domain', this.state.ch_domain, '')}
							</div>
						</Col>*/}
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Parish<span className="text-danger">*</span></label>
								<input type="text" name="ch_parish" id="ch_parish" className="form-control single-line-input" value={this.state.ch_parish} required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Parish', this.state.ch_parish, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >No of Families<span className="text-danger"></span></label>
								<input type="text" name="ch_fams" id="ch_fams" className="form-control single-line-input integer" value={this.state.ch_fams} required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Parish', this.state.ch_fams, '')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Parish Priest<span className="text-danger">*</span></label>
								<input type="text" name="ch_prist" id="ch_prist" className="form-control single-line-input" value={this.state.ch_prist} required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Parish Priest', this.state.ch_prist, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Assistant Parish Priest<span className="text-danger"></span></label>
								<input type="text" name="ch_asstprist" id="ch_asstprist" className="form-control single-line-input" value={this.state.ch_asstprist}  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Parish Priest', this.state.ch_asstprist, '')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Address<span className="text-danger">*</span></label>
								<textarea name="ch_addr" id="ch_addr" className="form-control" value={this.state.ch_addr} required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} rows="3" ></textarea>
								 {this.validator.message('Church Address', this.state.ch_addr, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Pincode<span className="text-danger">*</span></label>
								<input type="text" name="ch_pin" id="ch_pin" className="form-control single-line-input" value={this.state.ch_pin} required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Pincode', this.state.ch_pin, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Country<span className="text-danger">*</span></label>
								 <select name="country" onChange={this.handleCountryChange} value={this.state.country} id="country" required className="form-control single-line-input" >
								  <option value="">Select Country</option>
									{
										clist.map(function (item) {
											return <option value={item.id }>{item.name}</option>;
										})
									}
							 </select>
								 {this.validator.message('Country', this.state.country, 'required')}
							</div>
						</Col>
						
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >State<span className="text-danger">*</span></label>
								 <select name="region" onChange={this.handleStateChange} value={this.state.region} id="region" required className="form-control single-line-input" >
								  <option value="">Select State</option>
									{
										slist.map(function (item) {
											return <option value={item.id }>{item.name}</option>;
										})
									}
							 </select>
								 {this.validator.message('State', this.state.region, 'required')}
							</div>
						</Col>
						
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Diocese<span className="text-danger">*</span></label>
								 <select name="ch_dist" onChange={this.handleInputChange} id="ch_dist" value={this.state.ch_dist} required className="form-control single-line-input" >
								  <option value="">Select Diocese</option>
									{
										dlist.map(function (item) {
											return <option value={item.id }>{item.name}</option>;
										})
									}
							 </select>
								 {this.validator.message('Diocese', this.state.ch_dist, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Phone<span className="text-danger">*</span></label>
								<input type="text" name="ch_phone" id="ch_phone" className="form-control single-line-input"  value={this.state.ch_phone} placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Phone', this.state.ch_phone, '')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Primary Mobile No (Used as Login ID) Will not be shown in public<span className="text-danger">*</span></label>
								<input type="text" name="ch_mobile" id="ch_mobile" className="form-control single-line-input integer "  value={this.state.ch_mobile} required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Mobile', this.state.ch_mobile, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Secondary Mobile No (Primary Mobile number may also be used) Will be shown in public<span className="text-danger"></span></label>
								<input type="text" name="ch_secmob" id="ch_secmob" className="form-control single-line-input "  value={this.state.ch_secmob}  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Mobile', this.state.ch_secmob, '')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Email</label>
								<input type="email" name="ch_mail" id="ch_mail" className="form-control single-line-input" value={this.state.ch_mail}  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Email', this.state.ch_mail, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Website<span className="text-danger"></span></label>
								<input type="url" name="ch_web" id="ch_web" className="form-control single-line-input"  value={this.state.ch_web} placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Website', this.state.ch_web, '')}
							</div>
						</Col>
						
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >GEO IP Location<span className="text-danger"></span></label>
								<input type="text" name="ch_geoip" id="ch_geoip" className="form-control single-line-input"  value={this.state.ch_geoip}  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Mobile', this.state.ch_geoip, '')}
							</div>
						</Col>
						
					<Col md={6} >
							<div className="form-group" >
								
							</div>
						</Col>
						
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Facilities / Services<span className="text-danger"></span></label>
							</div>
						</Col>
						
					
						<Col md={2} >
							<div className="form-group" >
							<input type="checkbox" name="ch_adorchapel" id="ch_adorchapel"   placeholder="" onChange={this.handleCheckChange} onBlur={this.handleInputBlur} />&nbsp;&nbsp;
								
								<label className="label-text" > Adoration Chapel   </label>
								
								 {this.validator.message('Church Phone', this.state.ch_adorchapel, '')}
							</div>
						</Col>
						<Col md={2} >
							<div className="form-group" >
							<input type="checkbox" name="ch_bookstall" id="ch_bookstall"   placeholder="" onChange={this.handleCheckChange} onBlur={this.handleInputBlur} />&nbsp;&nbsp;
								
								<label className="label-text" > Book Stall   </label>
								
								 {this.validator.message('Church Phone', this.state.ch_bookstall, '')}
							</div>
						</Col>
						<Col md={2} >
							<div className="form-group" >
							<input type="checkbox" name="ch_councel" id="ch_councel"   placeholder="" onChange={this.handleCheckChange} onBlur={this.handleInputBlur} />&nbsp;&nbsp;
								
								<label className="label-text" > Counceling Centre </label>
								
								 {this.validator.message('Church Phone', this.state.ch_councel, '')}
							</div>
						</Col>
						<Col md={2} >
							<div className="form-group" >
							<input type="checkbox" name="ch_legal" id="ch_legal"   placeholder="" onChange={this.handleCheckChange} onBlur={this.handleInputBlur} />&nbsp;&nbsp;
								<label className="label-text" > Legal Centre   </label>
								
								
								 {this.validator.message('Church Phone', this.state.ch_legal, '')}
							</div>
						</Col>
						
						<Col md={2} >
							<div className="form-group" >
							<input type="checkbox" name="ch_mrgbue" id="ch_mrgbue"   placeholder="" onChange={this.handleCheckChange} onBlur={this.handleInputBlur} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<label className="label-text" > Marriage Bureau </label>
								
								
								 {this.validator.message('Church Phone', this.state.ch_mrgbue, '')}
							</div>
						</Col>
						<Col md={2} >
							<div className="form-group" >
							<input type="checkbox" name="ch_mrghall" id="ch_mrghall"   placeholder="" onChange={this.handleCheckChange} onBlur={this.handleInputBlur} />&nbsp;&nbsp;
								<label className="label-text" > Marriage Hall </label>
								
								
								 {this.validator.message('Church Phone', this.state.ch_mrghall, '')}
							</div>
						</Col>
						
						<Col md={2} >
							<div className="form-group" >
							<input type="checkbox" name="ch_med" id="ch_med"   placeholder="" onChange={this.handleCheckChange} onBlur={this.handleInputBlur} />&nbsp;&nbsp;
								<label className="label-text" > Medical Centre  </label>
								
								
								 {this.validator.message('Church Phone', this.state.ch_med, '')}
							</div>
						</Col>
				
						<Col md={12}>
						<div className="form-group" >
						        <label className="label-text" >History / About Church<span className="text-danger"></span></label>
								 <div className="md-form">
								  <textarea type="text" id="form7" className="md-textarea form-control" name="ch_about" rows="5"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} value={this.state.ch_about}></textarea>
								  {this.validator.message('Content', this.state.ch_about, '')}
								</div>
							</div>
					 </Col>
					 <Col md={12}>
						<b>Recommended Size: 600px x 450px</b>
					 </Col>
					  <Col md={3} >
							<div className="form-group" >
								<label className="label-text" >Photo 1<span className="text-danger"></span></label>
								{this.state.photo!='' && this.state.photo!=null ?
								<OverlayTrigger overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}>
									<span style={{float: 'right'}}
									 className="btn btn-danger btn-xs" onClick={() => this.handleDatafullRemove('cimage1') }  ><i className="fa fa-remove"></i>
									</span>
								</OverlayTrigger>
								: '' }
								<ReactFileReader handleFiles={this.handleFiles} fileTypes='image/*' base64={true}>
							     <img src={this.state.files!='' ? this.state.files : this.state.photo!='' && this.state.photo!=null ? this.state.photo : noImg} style={{border : '1px solid #cccccc',width : '100%',cursor : 'pointer'}} />
						       </ReactFileReader>
							   {this.state.files!='' ?
								  <span className="btn btn-danger btn-xs" onClick={this.handlefileRemove}>Remove</span>
								  : ''								  
								}
							</div>
						</Col>
						
						<Col md={3} >
							<div className="form-group" >
								<label className="label-text" >Photo 2<span className="text-danger"></span></label>
								{this.state.photo1!='' && this.state.photo1!=null ?
								<OverlayTrigger overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}>
									<span style={{float: 'right'}}
									 className="btn btn-danger btn-xs" onClick={() => this.handleDatafullRemove('cimage2') }  ><i className="fa fa-remove"></i>
									</span>
								</OverlayTrigger>
								: '' }
								<ReactFileReader handleFiles={this.handleFiles1} fileTypes='image/*' base64={true}>
							     <img src={this.state.files1!='' ? this.state.files1 : this.state.photo1!='' && this.state.photo1!=null ? this.state.photo1 : noImg} style={{border : '1px solid #cccccc',width : '100%',cursor : 'pointer'}} />
						       </ReactFileReader>
							   {this.state.files1!='' ?
								  <span className="btn btn-danger btn-xs" onClick={this.handlefileRemove1}>Remove</span>
								  : ''								  
								}
							</div>
						</Col>
						
						<Col md={3} >
							<div className="form-group" >
								<label className="label-text" >Photo 3<span className="text-danger"></span></label>
								{this.state.photo2!='' && this.state.photo2!=null ?
								<OverlayTrigger overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}>
									<span style={{float: 'right'}}
									 className="btn btn-danger btn-xs" onClick={() => this.handleDatafullRemove('cimage3') }  ><i className="fa fa-remove"></i>
									</span>
								</OverlayTrigger>
								: '' }
								<ReactFileReader handleFiles={this.handleFiles2} fileTypes='image/*' base64={true}>
							     <img src={this.state.files2!='' ? this.state.files2 : this.state.photo2!='' && this.state.photo2!=null ? this.state.photo2 : noImg} style={{border : '1px solid #cccccc',width : '100%',cursor : 'pointer'}} />
						       </ReactFileReader>
							    {this.state.files2!='' ?
								  <span className="btn btn-danger btn-xs" onClick={this.handlefileRemove2}>Remove</span>
								  : ''								  
								}
							</div>
						</Col>
						
						<Col md={3} >
							<div className="form-group" >
								<label className="label-text" >Photo 4<span className="text-danger"></span></label>
								{this.state.photo3!='' && this.state.photo3!=null ?
								<OverlayTrigger overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}>
									<span style={{float: 'right'}}
									 className="btn btn-danger btn-xs" onClick={() => this.handleDatafullRemove('cimage4') }  ><i className="fa fa-remove"></i>
									</span>
								</OverlayTrigger>
								: '' }
								<ReactFileReader handleFiles={this.handleFiles3} fileTypes='image/*' base64={true}>
							     <img src={this.state.files3!='' ? this.state.files3 : this.state.photo3!='' && this.state.photo3!=null ? this.state.photo3 : noImg} style={{border : '1px solid #cccccc',width : '100%',cursor : 'pointer'}} />
						       </ReactFileReader>
							   {this.state.files3!='' ?
								  <span className="btn btn-danger btn-xs" onClick={this.handlefileRemove3}>Remove</span>
								  : ''								  
								}
							</div>
						</Col>
						
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Update" loadingText="Updating.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
			</div>
		);
	}
	
}
export default AddChurch;


