import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import noImg from '../../../../assets/img/noimage_200.png';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PageTitle from '../../PageTitle';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
import ReactFileReader from 'react-file-reader';
import CKEditor from 'ckeditor4-react';




class AddEvent extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			files : '',
			photoname : '',
			files1 : '',
			photoname1 : '',
			 editorContent : '',
			files2 : '',
			photoname2 : '',
			files3 : '',
			photoname3 : '',
			company : Reqst.getTokenData('id','data'),
			evelist : false,
			userrole : Reqst.getTokenData('role','data'),
			user : Reqst.getTokenData('id','data')
		}
		this.validator = new SimpleReactValidator();
		this.handleFormSubmit= this.handleFormSubmit.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
		this.handleEditorChange = this.handleEditorChange.bind(this);
		this.handleFiles1 = this.handleFiles1.bind(this);
		this.handlefileRemove = this.handlefileRemove.bind(this);
		this.handlefileRemove1 = this.handlefileRemove1.bind(this);
		this.handlefileRemove2 = this.handlefileRemove2.bind(this);
		this.handlefileRemove3 = this.handlefileRemove3.bind(this);
		this.handleFiles2 = this.handleFiles2.bind(this);
		this.handleFiles3 = this.handleFiles3.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	
	handleEditorChange(v){
		 var newContent = v.editor.getData();
		this.setState({editorContent:newContent});
	}
	
	
	handlefileRemove(){
		
		this.setState({ files: '',photoname : ''});
	
	}
	
	handlefileRemove1(){
		
		this.setState({ files1: '',photoname1 : ''});
	
	}
	
	handlefileRemove2(){
		
		this.setState({ files2: '',photoname2 : ''});
	
	}
	
	handlefileRemove3(){
		
		this.setState({ files3: '',photoname3 : ''});
	
	}
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	
	
	handleFiles(files){
		this.setState({ files: files.base64,photoname : files.fileList[0]['name']});
	}
	
	handleFiles1(files){
		this.setState({ files1: files.base64,photoname1 : files.fileList[0]['name']});
	}
	
	handleFiles2(files){
		this.setState({ files2: files.base64,photoname2 : files.fileList[0]['name']});
	}
	
	handleFiles3(files){
		this.setState({ files3: files.base64,photoname3 : files.fileList[0]['name']});
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	
	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleFormSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			$("#showLoader").show();
			var data = Sanitize.objectifyForm($("#frmEveadd").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('appcontent/addevent',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					$("#showLoader").hide();
					document.getElementById("frmEveadd").reset();
					this.setState({ files: '',photoname : ''});
					this.setState({ files1: '',photoname1 : ''});
					this.setState({ files2: '',photoname2 : ''});
					this.setState({ files3: '',photoname3 : ''});
					swal("Success",res['data'].msg,"success");
				}			
				else
				{
					$("#showLoader").hide();
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	componentDidMount(){
		
		
		
	}
	
	

	render(){
		
		
	
		
		const { country, region } = this.state;
		
		
		
		
		if(this.state.userrole!='admin' && this.state.userrole!='staff')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
	   if(this.state.evelist)
		{
			return(<Redirect to="/appcontent/events" />);
		}
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Add Event Report</title>
				</Helmet>
				
				<PageTitle title="Add Event Report" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({evelist:true})}} >
							<i className="fa fa-arrow-left"></i> Back
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
				<form action="" method="post" encType="multipart/form-data" id="frmEveadd" onSubmit={this.handleFormSubmit} >
				<input type="hidden" name="userid" value={this.state.user} />
				<input type="hidden" name="churchid" value={this.state.company} />
				  <input type="hidden" name="photoname" value={this.state.photoname} />
				  <input type="hidden" name="files" value={this.state.files} />
				   <input type="hidden" name="photoname1" value={this.state.photoname1} />
				   <input type="hidden" name="files1" value={this.state.files1} />
				   <input type="hidden" name="photoname2" value={this.state.photoname2} />
				   <input type="hidden" name="files2" value={this.state.files2} />
				   <input type="hidden" name="photoname3" value={this.state.photoname3} />
				   <input type="hidden" name="files3" value={this.state.files3} />
				   <input type="hidden" value={this.state.editorContent} id="eve_content" name="eve_content" />
					<Row>
					
						
						<Col md={9} >
							<div className="form-group" >
								<label className="label-text" >Title<span className="text-danger">*</span></label>
								<input type="text" name="eve_tit" id="eve_tit" className="form-control single-line-input"  required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Title', this.state.eve_tit, 'required')}
							</div>
						</Col>
						<Col md={3} >
							<div className="form-group" >
								<label className="label-text" >Event Date<span className="text-danger">*</span></label>
								<input type="date" name="eve_date" id="eve_date" className="form-control single-line-input"  required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Event On', this.state.eve_date, 'required')}
							</div>
						</Col>
						<Col md={12}>
						<div className="form-group" >
								               <CKEditor 
												  data={this.state.editorContent} 
												  config={{extraPlugins :'font,colorbutton,colordialog,iframe',allowedContent:true,extraAllowedContent:'*{*},*{*}'}}
												  onChange = {this.handleEditorChange}
												  
												 />
							</div>
					 </Col>
					
						
						
						 <Col md={12} ><span className="text-danger">Recommended Size: 600px X 450px (W : H = 4:3 )</span></Col>
						 <Col md={3} >
							<div className="form-group" >
								<label className="label-text" >Photo 1<span className="text-danger"></span></label>
								<ReactFileReader handleFiles={this.handleFiles} fileTypes='image/*' base64={true}>
							     <img src={this.state.files!='' ? this.state.files : noImg} style={{border : '1px solid #cccccc',width : '100%',cursor : 'pointer'}} />
						       </ReactFileReader>
							   {this.state.files!='' ?
								  <span className="btn btn-danger btn-xs" onClick={this.handlefileRemove}>Remove</span>
								  : ''								  
								}
							</div>
						</Col>
						
						<Col md={3} >
							<div className="form-group" >
								<label className="label-text" >Photo 2<span className="text-danger"></span></label>
								<ReactFileReader handleFiles={this.handleFiles1} fileTypes='image/*' base64={true}>
							     <img src={this.state.files1!='' ? this.state.files1 : noImg} style={{border : '1px solid #cccccc',width : '100%',cursor : 'pointer'}} />
						       </ReactFileReader>
							   {this.state.files1!='' ?
								  <span className="btn btn-danger btn-xs" onClick={this.handlefileRemove1}>Remove</span>
								  : ''								  
								}
							</div>
						</Col>
						
						<Col md={3} >
							<div className="form-group" >
								<label className="label-text" >Photo 3<span className="text-danger"></span></label>
								<ReactFileReader handleFiles={this.handleFiles2} fileTypes='image/*' base64={true}>
							     <img src={this.state.files2!='' ? this.state.files2 :  noImg} style={{border : '1px solid #cccccc',width : '100%',cursor : 'pointer'}} />
						       </ReactFileReader>
							   {this.state.files2!='' ?
								  <span className="btn btn-danger btn-xs" onClick={this.handlefileRemove2}>Remove</span>
								  : ''								  
								}
							</div>
						</Col>
						
						<Col md={3} >
							<div className="form-group" >
								<label className="label-text" >Photo 4<span className="text-danger"></span></label>
								<ReactFileReader handleFiles={this.handleFiles3} fileTypes='image/*' base64={true}>
							     <img src={this.state.files3!='' ? this.state.files3 :  noImg} style={{border : '1px solid #cccccc',width : '100%',cursor : 'pointer'}} />
						       </ReactFileReader>
							   {this.state.files3!='' ?
								  <span className="btn btn-danger btn-xs" onClick={this.handlefileRemove3}>Remove</span>
								  : ''								  
								}
							</div>
						</Col>
						
				
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Save" loadingText="Saving.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
			</div>
		);
	}
	
}
export default AddEvent;


