
export const API_URL = "https://member.mycatholicchurch.com/services/";
//export const APIURL = "http://rcapi.nellaitoday.com/";

export const APPTITLE = 'My Catholic Church';

export const SITEURL = 'https://peacesoft.in/';

//export const BODYCOLOR = '#f6fcff';
export const BODYCOLOR = '#f0f2f5';

export const HEADERBG = 'linear-gradient(-90deg, rgb(82, 12, 95), rgb(166, 59, 185))';

export const SITELOGO = 'http://dbswallet.in/images/logo.png';
