import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Pagination from "react-js-pagination";
import PageTitle from '../../PageTitle';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class Groups extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			company : Reqst.getTokenData('companyid','cp'),
			user : Reqst.getTokenData('id','data'),
			userrole : Reqst.getTokenData('role','data'),
			grouplist : [],
			currentPage: 1,
			addGroup : false,
			zonalid : '',
			idd : '',
			zo_name : '',
			zo_charge : '',
			zo_mail  : '',
			zo_mob  : '',
			UpGroup : false,
			memview : false,
			todosPerPage: 20,
			activePage : 1
		}
		this.validator = new SimpleReactValidator();
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.handleView = this.handleView.bind(this);
		this.onShowChange = this.onShowChange.bind(this);
		this.handleAddSubmit= this.handleAddSubmit.bind(this);
		this.handleUpdateSubmit = this.handleUpdateSubmit.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	
	
	handlePageChange(pageNumber) {
		
		this.setState({activePage: pageNumber});
		this.state.currentPage = Number(pageNumber);
	}
	
	onShowChange(e){
		this.state.todosPerPage = e.target.value;
		
			
			this.getdata();
		
	}
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	
	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleAddSubmit(e){
		e.preventDefault();
		//if (this.validator.allValid()) {
			this.setState({showLoader:true});
			var data = Sanitize.objectifyForm($("#frmGroupadd").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('group/addgroup',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false});	
					swal("Success",res['data'].msg,"success");
					this.setState({addGroup: false});
					this.getdata();
					
				}	
				else
				{
					this.setState({showLoader: false});	
					swal("Error",res['data'].msg,"error");
				}
			});
		/* }
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		} */
	}
	
	handleUpdate(id){
		
		this.state.idd = id;
		this.setState({UpGroup: true});	
		this.groupdata();
		
	}
	
	handleView(id){
		
		this.state.idd = id;
		this.setState({memview : true});
	
	}
	
	handleRemovefn(id){
		
		this.state.idd = id;
		this.removegroup();
		
	}
	
	handleRemove = (dd): void => {
		
		this.state.idd = dd;
		 confirmAlert({
		  title: 'Confirm to Delete',
		  message: 'Are you sure to Delete this Group ?',
		  buttons: [
			{
			  label: 'No'
			},
			{
			  label: 'Yes',
			  onClick: () => this.handleRemovefn(dd)
			}
		  ]
		})
  };
	
	handleUpdateSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			this.setState({showLoader:true});
			var data = Sanitize.objectifyForm($("#frmGroupUpdate").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('group/updategroup/',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false});	
					swal("Success",res['data'].msg,"success");
					this.setState({UpGroup: false});
					this.getdata();
					
				}	
				else
				{
					this.setState({showLoader: false});	
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	componentDidMount(){
		
		this.getdata();
		
	}
	
	groupdata(){
		 $("#showLoader").show();
		Reqst.GetData('group/getchurchgroupsbyid/'+this.state.idd).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({
					 gr_name : res[0]['name'],
					 groupid : res[0]['id'],
					 gr_desc : res[0]['description'],
					 gr_status : res[0]['groupstatus']
				});
			}
		});
	}
	
	removegroup(){
		 $("#showLoader").show();
		Reqst.GetData('group/removegroup/'+this.state.idd+'/'+this.state.user).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res.status=='1'){
					swal("Success",res.msg,"success");
					this.getdata();
			}
			else{
				
					swal("Error",res['data'].msg,"error");
			}
		});
	}
	
	getdata(){
		 $("#showLoader").show();
		Reqst.GetData('group/getchurchgroups/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({grouplist : res});
			}
		});
	}

	render(){
		
		const { country, region } = this.state;
		
	   if(this.state.userrole!='admin' && this.state.userrole!='staff')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
	if(this.state.memview){
		  
		return (<Redirect to={'/group/viewmembers/'+ this.state.idd} />);

	  }
		
		if(this.state.grouplist.length>0){
			
			$(".page_btns").show();
		}
		else{
			$(".page_btns").hide();
		}
		
		this.grstatus = [
		{value : '', name : '-Select-'},
		{value : 'active', name : 'Active'},
		{value : 'inactive', name : 'Inactive'}
	   ];
		
		  /** set showcount select box **/
	this.shows = [
		{value : '10', name : '10'},
		{value : '20', name : '20'},
		{value : '50', name : '50'},
		{value : '100', name : '100'}
	];
	
	// Logic for displaying current todos
	const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
	const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
	const currentTodos = this.state.grouplist.slice(indexOfFirstTodo, indexOfLastTodo);

	const renderTodos = currentTodos.map((item,i) => {
		
	  return <tr key = {item}>
								<td width="20">{i+1}</td>
								<td width="100" style={{textTransform:'capitalize'}}>{item.name}</td>
								<td width="100">{item.description}</td>
								<td width="100"><span className="badge">{item.groupmemberscount}</span>&nbsp;
								<span name="edithw" onClick={() => this.handleView(item.id)}  ><i className="fa fa-eye"></i></span></td>
								<td width="100" style={{textTransform:'capitalize'}}>{item.groupstatus=='active'?
								<span className="label label-success">{item.groupstatus}</span>
								: 
								<span className="label label-danger">{item.groupstatus}</span>
								}
								</td>
								<td width="30">
									<button name="edithw" className="btn btn-info btn-sm" onClick={() => this.handleUpdate(item.id)}  ><i className="fa fa-edit"></i></button>
									&nbsp;&nbsp;
									<button name="removeann" className="btn btn-danger btn-sm" onClick={() => this.handleRemove(item.id)}  ><i className="fa fa-remove"></i></button>
								</td>
							</tr>;
		
	});
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Groups</title>
				</Helmet>
				
				<PageTitle title="Groups" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({addGroup:true})}} >
							<FeatherIcon.CheckSquare size={14} /> Add Group
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
				<Row>
				  <Col md="12">
				 
					<Table responsive hover bordered>
					  <thead>
						<tr>
						  <th>S.No</th>
						  <th>Name</th>
						  <th>Description</th>
						  <th>Members</th>
						  <th>Status</th>
						  <th>Manage</th>
						</tr>
					  </thead>
					  <tbody>
					  {this.state.grouplist.length>0 ? 
						renderTodos
					  : 
					  <tr><td colSpan="6"><center>No Data Found...</center></td></tr>
					  }
					</tbody>
					</Table>
				  </Col>
				  <Col md="4" className="page_btns">
				  <Pagination
							 activePage={this.state.activePage}
							 itemsCountPerPage={this.state.todosPerPage}
							 pageRangeDisplayed={3}
							 totalItemsCount={this.state.grouplist.length}
							 onChange={this.handlePageChange}
							/>
				  </Col>
				  <Col md="2" className="page_btns">
				   <select name="show" onChange={this.onShowChange} id="show" class="form-control" >
								{this.shows.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>&nbsp;
						entries
				  </Col>
				  <Modal show={this.state.addGroup} className="modal-popup"   size="md" onHide={()=>{this.setState({addGroup:false})}} >
				  <Modal.Header closeButton>
					<Modal.Title>Add Group</Modal.Title>
				  </Modal.Header>
				  <Modal.Body>
						<form action="" method="post" encType="multipart/form-data" id="frmGroupadd" onSubmit={this.handleAddSubmit} >
				<input type="hidden" name="company" value={this.state.company} />
				<input type="hidden" name="userid" value={this.state.user} />
					<Row>
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Group Name<span className="text-danger">*</span></label>
								<input type="text" name="gr_name" id="gr_name" className="form-control single-line-input" required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Group Name', this.state.gr_name, 'required')}
							</div>
						</Col>
						
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Description<span className="text-danger"></span></label>
								<input type="textarea" name="gr_desc" id="gr_desc" className="form-control single-line-input"  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								{this.validator.message('Incharge Name', this.state.gr_desc, '')}
							</div>
						</Col>
						
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Status<span className="text-danger"></span></label>
								<select name="gr_status" onChange={this.handleInputChange} id="gr_status"  className="form-control single-line-input" >
								{this.grstatus.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								{this.validator.message('Incharge Name', this.state.gr_status, '')}
							</div>
						</Col>
						
				
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Save" loadingText="Saving.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
				  </Modal.Body>
				</Modal>
				<Modal show={this.state.UpGroup} className="modal-popup"   size="md" onHide={()=>{this.setState({UpGroup:false})}} >
				  <Modal.Header closeButton>
					<Modal.Title>Update Group</Modal.Title>
				  </Modal.Header>
				  <Modal.Body>
						<form action="" method="post" encType="multipart/form-data" id="frmGroupUpdate" onSubmit={this.handleUpdateSubmit} >
				      <input type="hidden" name="groupid" value={this.state.groupid} />
				       <input type="hidden" name="userid" value={this.state.user} />
					<Row>
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Group Name<span className="text-danger">*</span></label>
								<input type="text" name="gr_name" id="gr_name" className="form-control single-line-input" value={this.state.gr_name} required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Group Name', this.state.gr_name, 'required')}
							</div>
						</Col>
						
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Description<span className="text-danger"></span></label>
								<input type="textarea" name="gr_desc" id="gr_desc" className="form-control single-line-input" value={this.state.gr_desc}  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								{this.validator.message('Incharge Name', this.state.gr_desc, '')}
							</div>
						</Col>
						
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Status<span className="text-danger"></span></label>
								<select name="gr_status" onChange={this.handleInputChange} id="gr_status" value={this.state.gr_status}  className="form-control single-line-input" >
								{this.grstatus.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								{this.validator.message('Incharge Name', this.state.gr_status, '')}
							</div>
						</Col>
						
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Update" loadingText="Updating.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
				  </Modal.Body>
				</Modal>
				</Row>
			</div>
		);
	}
	
}
export default Groups;


