/*****
Loading Submit Button
Required :- 	1.	fa icon
				2.	Bootstrap
			
Use	:-
	<Jbutton className="btn btn-success" loading={this.state.showLoader} text="Save" loadinText="Saving..." />
****/

import React, { Component } from 'react';
import {Button} from 'react-bootstrap';

class LoadingBtn extends Component {
	
	constructor(props){
		super(props);
		this.state = {
		
		}		
	}
	
	render() {	  
	
		const style = (this.props.style) ? this.props.style : {textAlign:'center'};
		
		return (
			<Button type="submit" variant={(this.props.variant) ? this.props.variant : 'primary'} className={(this.props.className) ? this.props.className : 'btn'} style={style} disabled={this.props.loading} >
				{this.props.loading ? <i className="fa fa-spinner fa-spin" style={{fontSize:'15px',marginRight:'5px'}} ></i> :  <i className="fa fa-check" style={{fontSize:'15px',marginRight:'5px'}} ></i>}
				{this.props.loading ? 
					(this.props.loadingText) ? this.props.loadingText : 'Loading..' :
					(this.props.text) ? this.props.text : 'Submit'}
			</Button>				
		);
	}
}

export default LoadingBtn;