import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';
import LoadingBtn from '../../../tie/LoadingBtn';

import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { Row, Col, Input, Form, Card, InputGroup, Carousel, Modal, Fade, utils } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
import BannerImage from '../../../assets/img/api-integration.png';
import Logo from '../../../assets/img/logo.png';
import SliderBackground from '../../../assets/img/slider_350x150.png';

import LoginBanner from './LoginBanner';
import Services from './Services';


const styles={
	firstRowStyle:{
		backgroundImage:'linear-gradient(#f9aa1f , #c7418c)',
		height:'calc(100vh)',
	},
	textDiv:{
		color:'#fff',
		marginTop: '20%',
	},
	secondRow: {
		marginTop: '50px',
	},
	dividerStyle: {
		width: '90%',
		marginLeft: '25px',
	},
	btnSignup: {
		marginTop: '80px',
		fontSize: '20px;',
		 width:'300px',
	},
	resendOtp: {
		float: 'right',
		fontSize: '13px',
	}
}

class Login extends Component{
	constructor(props){
		super(props);
		this.state = {
			redirect: false,
			showLoader: false,
			showModal: false,
			modalTitle: '',
			aboutComp: 'recharge',
			hasOtpVerify: false,
		}
		this.validator = new SimpleReactValidator();
		this.handleLoginChange = this.handleLoginChange.bind(this);
		this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
		this.handleLoginBlur = this.handleLoginBlur.bind(this);
		this.handleOtpFormSubmit = this.handleOtpFormSubmit.bind(this);
	}
	
	componentDidMount(){

		$("body").css('background-color','#fff');
	}
	
	
	handleLoginChange(e){
		this.setState({[e.target.name]: e.target.value});
	}

	handleLoginBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	handleLoginSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			/*** Handle Login ****/
			this.setState({showLoader:true});
			var data = Sanitize.objectifyForm($("#frmLogin").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('login/',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					Sanitize.setStorage('dbs_retailer_data',res['data'].data);
					Sanitize.setStorage('dbs_retailer_wldata',res['data'].wl);
					Sanitize.setStorage('dbs_retailer_login','yes');
					this.setState({showLoader: false,hasOtpVerify: true});
				}	
				else
				{
					this.setState({showLoader: false,hasOtpVerify: false});	
					swal("Error",res['data'].msg,"error");
				}
			});
			
		} else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
		}
	}
	
	/**
	* handleOtpFormSubmit
	* to validate user otp 
	* @since 1.0
	* @DT : 05/02/2019
	*/
	handleOtpFormSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			this.setState({showLoader:true});
			var data = [];
			data.push({i__otp: this.state.i__otpNo,
						i__userId: Reqst.getTokenData('id'),
						i__userCode: Reqst.getTokenData('userid')
					});
			data = Reqst.Token(data);
			Reqst.axPost('login/checkotp/',{token:data}).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({redirect: true});	
					//this.dialog.showAlert(res['data'].msg);
					//swal(res['data'].msg);
				}
				else
				{
					this.setState({showLoader: false});	
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	render(){

		if(this.state.redirect)
		{
			return(<Redirect to="/dashboard" />);
		}
	
		return(
			<div className="container " style={{ maxWidth:'100%' }} >
				<Helmet>
					<title>{GC.APPTITLE} | User Login</title>
				</Helmet>
				
				<Row>
					<Col md={5} style={styles.firstRowStyle} >
						
						<LoginBanner />
						
					</Col>
					<Col md={7} >
						<Row style={styles.secondRow} >
							<Col md={12} >
								<h4 className="text-center">{this.state.hasOtpVerify ? 'Verify' : 'SIGN IN'}</h4>
							</Col>
							<Col md={{ span: 6, offset: 3 }} >
								<Row>
								<Col md={12} >
									{this.state.hasOtpVerify ? 
									<div style={{ minHeight: '198px' }} >
										<form action="" method="post" id="frmOtp" encType="mutlipart/form-data" onSubmit={this.handleOtpFormSubmit} >
											<Col md={12} >
												
											</Col>
											<Col md={12} >
												<div className="form-group" style={{ marginBottom: '0px' }} >
													<label className="label-text" >Otp *</label>
													<input type="text" name="i__otpNo" id="i__otpNo" className="form-control single-line-input" required placeholder="xxxx" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} onKeyPress={Sanitize.integerKeyPress} />
														{/*<a href="javascript:;" style={styles.resendOtp} >Resend Otp</a>*/}
													 {this.validator.message('Otp', this.state.i__otpNo, 'required')}		
													<div style={{ fontSize: '12px', color:'grey' }} >We are sending an one time password to your registered mobile number.</div>
													</div>
											</Col>
											<Col md={12} style={{ marginTop: '30px' }} >
												<LoadingBtn type="submit" text="Verify" className="btn btn-warning btn-block" loading={this.state.showLoader} loadingText="Verify..." >
													Verify
												</LoadingBtn>
											</Col>
											
										</form>
									</div>
									:
									<form action="" id="frmLogin" method="post" role="form" encType="multipart/form-data" onSubmit={this.handleLoginSubmit} >
										<Row>
											<Col md={12} >
												<div className="form-group" style={{ marginBottom: '0px' }} >
													<label className="label-text" >Username</label>
													<input type="text" name="i__userName" id="i__userName" className="form-control single-line-input" required placeholder="948xxxxx14" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} />
													 {this.validator.message('Username', this.state.i__userName, 'required')}
												</div>
											</Col>
											<Col md={12} >
												<div className="form-group" style={{ marginBottom: '0px' }} >
													<label className="label-text" >Password</label>
													<input type="password" name="i__passWord" id="i__passWord" className="form-control single-line-input" required placeholder="********" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur}  />
													<Link to="/forgot-password" style={{fontSize: '12px', float:'right' }} >Forgot Password?</Link>
													{this.validator.message('Password', this.state.i__passWord, 'required')}
												</div>
											</Col>
											<Col md={12} style={{ marginTop: '20px' }} >
												<LoadingBtn className="btn btn-warning btn-block" loading={this.state.showLoader} loadingText="Validating..." >
													Sign In
												</LoadingBtn>
											</Col>
										</Row>
									</form>
									}
								</Col>
								</Row>
							</Col>
							<Col md={{span: 8, offset: 2}} >
								
								<Services />
								
							</Col>
						</Row>
					</Col>
				</Row>
				
				

			</div>
		);
	}
	
}
export default Login;

