import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';

import SimpleLineIcon from 'react-simple-line-icons';
import * as FeatherIcon from 'react-feather';

import * as Menus from './Menus';

const styles = {
	iconStyle: {
		marginTop: '-3px',
		marginRight: '8px'
	}
}

class MenuLi extends Component{
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
			menuOpen:false,
		}
		this.handleMenuOpen = this.handleMenuOpen.bind(this);
		this.handleMenuClick = this.handleMenuClick.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	handleMenuOpen(e){
		e.preventDefault();
		this.setState({menuOpen:!this.state.menuOpen});
	}
	
	handleMenuClick(e){
		$("#ps-sidebar").toggle();
	}
	
	render(){
		
		/* $('li.nav-item').click(function () {
			$("html, body").animate({
				scrollTop: 0
			}, "fast");
			
			$(this).siblings('li').find('ul').hide();
			$("#ps-sidebar").toggle();
		}); */

		let activeMenu = '/'+window.location.hash.split( '/' )[1];
		let _activeMenu = '/'+window.location.hash.split( '/' );
		_activeMenu = _activeMenu.replace(/,/g, '/').slice(2);
	
		return(
			<li  
				className={(this.state.menuOpen || this.props.to==activeMenu) ? 
							"nav-item side-menu-dropdown-open" 
							: "nav-item side-menu-dropdown"}  >
							
				{this.props.submenu ?
					<div id="ps_sidebar_div">
						<a 
							href="javascript:;" 
							className={this.props.to==activeMenu ? 'nav-link active' : 'nav-link'} 
							onClick={this.handleMenuOpen} >
							
							{this.props.icon ? 
								this.props.icon 
								: <FeatherIcon.FileText size={17} style={styles.iconStyle} /> 
							}
								{this.props.title}
								{this.state.menuOpen ? 
									<FeatherIcon.ArrowDown size={15} style={{ float:'right' }}  /> 
									: null 
								}
						</a>
						
						<ul  style={{ display: (this.state.menuOpen || this.props.to==activeMenu) ? 'block' : 'none' }}  >
							{
								this.props.submenu.map((item,i)=>{
									return <li className={_activeMenu==item.path ? 'active' : ''} >
										<Link to={item.path} onClick={this.handleMenuClick} >
											{item.value}
										</Link>
									</li>;
								})
							}
						</ul>
						
					</div>
					: 
					<Link
					to={this.props.to} 
					className={this.props.to==activeMenu ? 'nav-link active' : 'nav-link'} 
					onClick={this.handleMenuClick} >
						{this.props.icon}
							{this.props.title} 
					</Link>
				}
				
			</li>

		);
	}
	
}
export default MenuLi;

