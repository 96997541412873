import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PageTitle from '../../PageTitle';
import noImg from '../../../../assets/img/noimage_200.png';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
const tabletd ={
	
	fontSize: '13px',fontWeight: 'bold',color : '#1d4f71'
};


class ViewProfileCard extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			company : Reqst.getTokenData('companyid','cp'),
			memberlist : false,
			userrole : Reqst.getTokenData('role','data'),
			user : Reqst.getTokenData('id','data')
		}
		this.validator = new SimpleReactValidator();
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.printDocument = this.printDocument.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	
	
	printDocument() {
		
		//$(".print-report").hide();
		var content = document.getElementById("print_report");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	

	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	
	
	componentDidMount(){
		
		this.getdata();
		this.churchdata();
		
	}
	
	churchdata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getpastorchurchdata/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({
					 ch_name : res[0]['name'],
					 ch_city : res[0]['city'],
					 ch_addr : res[0]['address'],
					 ch_pin : res[0]['pincode'],
					 country : res[0]['country'],
					 region : res[0]['state'],
					 ch_phone : res[0]['phone'],
					 ch_mobile : res[0]['mobile'],
					 ch_mail : res[0]['email'],
					 photo : res[0]['logo'],
					 ch_web : res[0]['website']
				});
			}
		});
	}
	
	getdata(){
		 $("#showLoader").show();
		Reqst.GetData('family/getchurchfamilyheadbyid/'+this.props.match.params.id).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({
					member_id : res[0]['memberid'],
					fid : res[0]['familyid'],
					headname : res[0]['headname'],
					f_head_name : res[0]['name'],
					f_head_addr : res[0]['address'],
					f_head_area : res[0]['area'],
					f_head_city : res[0]['city'],
					f_head_dob  : res[0]['dob'],
					f_head_age  : res[0]['age'],
					f_head_gen  : res[0]['gender'],
					f_head_edu  : res[0]['qualification'],
					f_head_occ  : res[0]['occupation'],
					f_head_phone : res[0]['phone'],
					f_head_place : res[0]['place'],
					f_head_mail : res[0]['email'],
					f_head_mob : res[0]['mobile'],
					f_head_pin : res[0]['pincode'],
					f_head_remarks : res[0]['note'],
					f_head_whatsapp : res[0]['whatsapp'],
					country : res[0]['country'],
					f_mem_relation :  res[0]['relationship'],
					region : res[0]['state']
				});
			}
		});
	}
	
	

	render(){
		
		
		
		if(this.state.userrole!='admin' && this.state.userrole!='staff')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
	   if(this.state.memberlist)
		{
			return(<Redirect to="/family/memberlist" />);
		}
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | View Profile</title>
				</Helmet>
				
				<PageTitle title="View Profile" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
					  <button className="btn btn-sm btn-danger" onClick={() => this.printDocument() }><i className="fa fa-print"></i> Print</button>
					  &nbsp;
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({memberlist:true})}} >
							<i className="fa fa-arrow-left"></i> Back
						</button>
					
					  </div>
					</div>
				</PageTitle>
				<Col md={12} sm={12} >
					<div id="print_report">
		             <table id="" align="center">
						<tbody>
						       <tr>
								<td rowSpan="4" style={{marginLeft: 'auto',marginRight : 'auto',textAlign : 'center'}}><img src={this.state.photo!='' ? this.state.photo : noImg} width="70" className="img-avatar" /></td>
								</tr>
								<tr>
									<td  style={{marginLeft: 'auto',marginRight : 'auto',textAlign : 'center',fontSize: '18px',fontWeight: 'bold',color : '#8a260e'}} >{this.state.ch_name}</td>
								</tr>
								<tr>
									<td  style={{marginLeft: 'auto',marginRight : 'auto',textAlign : 'center',fontSize: '14px',fontWeight: 'bold',color : '#1d4f71'}}>{this.state.ch_addr} , {this.state.ch_city} - {this.state.ch_pin}</td>
								</tr>
								<tr>
									<td  style={{marginLeft: 'auto',marginRight : 'auto',textAlign : 'center',fontSize: '14px',fontWeight: 'bold',color : '#1d4f71'}}>Mob : {this.state.ch_mobile} Mail : {this.state.ch_mail}</td>
								</tr>
						</tbody>
					  </table>
					  <br/>
					  <Col md={12} sm={12} style={{textAlign:'center'}}>
					  <Row>
						   <table width="50%" id="" align="">
						   <tbody>
							<tr>
									<td style={tabletd}>Member Id</td>
									<td style={tabletd}> : </td>
									<td style={tabletd}>{this.state.member_id}</td>
								</tr>
								<tr>
									<td style={tabletd}>Family Id</td>
									<td style={tabletd}> : </td>
									<td style={tabletd}>{this.state.fid}</td>
								</tr>
								<tr>
									<td style={tabletd}>Gender</td>
									<td style={tabletd}> : </td>
									<td style={tabletd}>{this.state.f_head_gen}</td>
								</tr>
								<tr>
									<td style={tabletd}>Education</td>
									<td style={tabletd}> : </td>
									<td style={tabletd}>{this.state.f_head_edu}</td>
								</tr>
								<tr>
									<td style={tabletd}>Place</td>
									<td style={tabletd}> : </td>
									<td style={tabletd}>{this.state.f_head_place}</td>
								</tr>
								<tr>
									<td style={tabletd}>Address</td>
									<td style={tabletd}> : </td>
									<td style={tabletd}>{this.state.f_head_addr} - {this.state.f_head_pin}</td>
								</tr>
								<tr>
									<td style={tabletd}>Email</td>
									<td style={tabletd}> : </td>
									<td style={tabletd}>{this.state.f_head_mail}</td>
								</tr>
								<tr>
									<td style={tabletd}>WhatsApp</td>
									<td style={tabletd}> : </td>
									<td style={tabletd}>{this.state.f_head_whatsapp}</td>
								</tr>
					 		</tbody>
					    </table>
					    
						  <table id="" width="50%" align="">
						   <tbody>
							<tr>
									<td style={tabletd}>Name</td>
									<td style={tabletd}> : </td>
									<td style={tabletd}>{this.state.f_head_name}</td>
							</tr>
							<tr>
									<td style={tabletd}>Family Head</td>
									<td style={tabletd}> : </td>
									<td style={tabletd}>{this.state.headname}</td>
							</tr>
							<tr>
									<td style={tabletd}>DOB</td>
									<td style={tabletd}> : </td>
									<td style={tabletd}>{this.state.f_head_dob}</td>
							</tr>
							<tr>
									<td style={tabletd}>Occupation</td>
									<td style={tabletd}> : </td>
									<td style={tabletd}>{this.state.f_head_occ}</td>
							</tr>
							<tr>
									<td style={tabletd}>City</td>
									<td style={tabletd}> : </td>
									<td style={tabletd}>{this.state.f_head_city}</td>
							</tr>
							<tr>
									<td style={tabletd}>Mobile</td>
									<td style={tabletd}> : </td>
									<td style={tabletd}>{this.state.f_head_mob}</td>
							</tr>
							<tr>
									<td style={tabletd}>Phone</td>
									<td style={tabletd}> : </td>
									<td style={tabletd}>{this.state.f_head_phone}</td>
							</tr>
							</tbody>
					    </table>
			
					</Row>
					  </Col>
					  </div>
				</Col>
				  <iframe name="print_frame" id="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
			</div>
		);
	}
	
}
export default ViewProfileCard;


