import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import noImg from '../../../../assets/img/noimage_200.png';
import audiopng from '../../../../assets/img/audio.jpg';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PageTitle from '../../PageTitle';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
import ReactFileReader from 'react-file-reader';
import ReactAudioPlayer from 'react-audio-player';


class ViewMass extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			files : '',
			photoname : '',
			files1 : '',
			photoname1 : '',
			files2 : '',
			photoname2 : '',
			files3 : '',
			photoname3 : '',
			company : Reqst.getTokenData('id','data'),
			evelist : false,
			mannid : '',
			eve_tit : '',
			eve_content : '',
			photo : '',
			audiourl : '',
			eve_date : '',
			audiosize : '',
			audiorealsize : '',
			masstabid : '',
			massedit : false,
			masstimings : [],
			userrole : Reqst.getTokenData('role','data'),
			user : Reqst.getTokenData('id','data')
		}
		this.validator = new SimpleReactValidator();
		this.handleFormSubmit= this.handleFormSubmit.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
		this.handleFiles1 = this.handleFiles1.bind(this);
		this.handleFiles2 = this.handleFiles2.bind(this);
		this.handleFiles3 = this.handleFiles3.bind(this);
	    this.handlefileRemove = this.handlefileRemove.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	
	handleFiles(files){
		this.setState({ files: files.base64,photoname : files.fileList[0]['name']});
	}
	
	handleFiles1(files){
		this.setState({ files1: files.base64,photoname1 : files.fileList[0]['name']});
	}
	
	handleFiles2(files){
		this.setState({ files2: files.base64,photoname2 : files.fileList[0]['name']});
	}
	
	handleFiles3(files){
		this.setState({ files3: files.base64,photoname3 : files.fileList[0]['name']});
	}
	


	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	
	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleFormSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			$("#showLoader").show();
			var data = Sanitize.objectifyForm($("#frmEvtImgadd").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('setup/addchurchimages',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					$("#showLoader").hide();
					//document.getElementById("frmManadd").reset();
					this.getdata();
					this.getmassdata();
					this.setState({ files: '',photoname : ''});
					this.setState({ files1: '',photoname1 : ''});
					this.setState({ files2: '',photoname2 : ''});
					this.setState({ files3: '',photoname3 : ''});
					swal("Success",res['data'].msg,"success");
				}			
				else
				{
					$("#showLoader").hide();
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	getdateofdt(date){
		      
			  if(date!='' && date!=null){		
			  var date1 = new Date(date);
		      var year = date1.getFullYear();
			  var month = (date1.getMonth()+1);
			  var day   = date1.getDate()
			  var strTime = day + '-' + month + '-' + year;
			  return strTime;
		 }
		 else{
			 
			 return 'N/A';
		 }
			
	  }
	
	handlefileRemove(){
		
		this.setState({ files1: '',audioname : ''});
		this.setState({ audiosize: '',audiorealsize : ''});
		 $(".btn_div").show();
		
	}
	
	componentDidMount(){
		
		this.getdata();
		this.getmassdata();
		this.getmassnewdata();
		
	}
	
	getdata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getpastorchurchdata/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({
					 ch_id : res[0]['id'],
					 ch_name : res[0]['name'],
					 ch_addr : res[0]['address'],
					 ch_pin : res[0]['pincode'],
					 ch_mobile : res[0]['mobile'],
					 ch_mail : res[0]['email'],
					 ch_web : res[0]['website'],
					 ch_parish : res[0]['parish'],
					 ch_prist : res[0]['parishprist'],
					 ch_fest_month : res[0]['festivaldays']
				});
				
			}
		});
	}
	
	
	getmassdata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getmassdata/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res.length>0){
				this.setState({
					masstabid : res[0]['id'],
					ch_sun : res[0]['sunday'],
					ch_mon : res[0]['monday'],
					ch_tue : res[0]['tuesday'],
					ch_wed : res[0]['wednesday'],
					ch_thu : res[0]['thursday'],
					ch_fri : res[0]['friday'],
					ch_sat : res[0]['saturday'],
					ch_spmass : res[0]['specialmass']
				});	
			}
		});
	}
	
	
	getmassnewdata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getmassnewdata/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res.length>0){
				this.setState({
					masstimings : res
				});	
			}
		});
	}
	
	render(){
		
	
		if(this.state.userrole!='admin' && this.state.userrole!='staff')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
	  if(this.state.massedit)
		{
			return(<Redirect to="/setup/mass" />);
		}
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | View Mass Timings </title>
				</Helmet>
				
				<PageTitle title="View Mass Timings" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({massedit:true})}} >
							<i className="fa fa-edit"></i>&nbsp;Edit
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
			<div className="list-group-item" style={{textAlign: 'center',borderLeft: '2px solid #4CAF50',marginBottom: '8px'}}>
			<Row>
				<Col md="12" sm="12">
			    <h4 className="list-group-item-heading" style={{wordBreak: 'break-all'}} >{this.state.ch_name}</h4>
				
				{/*<p className="list-group-item-text">{this.state.ch_addr} - {this.state.ch_pin} , Mob :  {this.state.ch_mobile}, Mail : {this.state.ch_mail}</p>*/}
				 
				 <p className="list-group-item-text">Parish : {this.state.ch_parish}</p>
			 </Col>
			</Row>
	     </div>
		 
		 <br />
		 
		 <Col md="12" sm="12">
	  
				<form action="" method="post" encType="multipart/form-data" id="frmEvtImgadd" onSubmit={this.handleFormSubmit} >
				<input type="hidden" name="userid" value={this.state.user} />
				<input type="hidden" name="churchid" value={this.state.company} />
				<input type="hidden" name="masstabid" value={this.state.masstabid} />
				 <input type="hidden" name="photoname" value={this.state.photoname} />
				  <input type="hidden" name="files" value={this.state.files} />
				   <input type="hidden" name="photoname1" value={this.state.photoname1} />
				   <input type="hidden" name="files1" value={this.state.files1} />
				   <input type="hidden" name="photoname2" value={this.state.photoname2} />
				   <input type="hidden" name="files2" value={this.state.files2} />
				   <input type="hidden" name="photoname3" value={this.state.photoname3} />
				   <input type="hidden" name="files3" value={this.state.files3} />
				   
				    <Row>
						<Col md={12}>
							<Table>
								<thead>
									<tr>
										<th>S.No</th>
										<th>Day</th>
										<th>Time</th>
										<th>Language</th>
										<th>Type of Service</th>
									</tr>
								</thead>
								<tbody>
									{this.state.masstimings.map((item,i)=>{
										return <tr>
											<td>{i+1}</td>
											<td>{item.serviceday}</td>
											<td>{item.servicetime}</td>
											<td>{item.langname}</td>
											<td>{item.typeofservice}</td>
										</tr>
									})}
								</tbody>
							</Table>
						</Col>
					</Row>
					<Row>
					
					{/*<Col md={6}>
						<div className="form-group" >
						        <label className="label-text" >Sunday<span className="text-danger"></span></label>
								 <div className="md1-form">
								  <textarea type="text" id="form7" className="md1-textarea form-control single-line-input form_view" readOnly name="ch_sun" rows="5"   placeholder="Enter Masstime and Mass Language" onChange={this.handleInputChange} onBlur={this.handleInputBlur} value={this.state.ch_sun}></textarea>
								  {this.validator.message('Content', this.state.ch_sun, '')}
								</div>
							</div>
					 </Col>
					 
					 <Col md={6}>
						<div className="form-group" >
						        <label className="label-text" >Monday<span className="text-danger"></span></label>
								 <div className="md1-form">
								  <textarea type="text" id="form7" className="md1-textarea form-control single-line-input form_view" readOnly name="ch_mon" rows="5"   placeholder="Enter Masstime and Mass Language" onChange={this.handleInputChange} onBlur={this.handleInputBlur} value={this.state.ch_mon}></textarea>
								  {this.validator.message('Content', this.state.ch_mon, '')}
								</div>
							</div>
					 </Col>
					 
					 <Col md={6}>
						<div className="form-group" >
						        <label className="label-text" >Tuesday<span className="text-danger"></span></label>
								 <div className="md1-form">
								  <textarea type="text" id="form7" className="md1-textarea form-control single-line-input form_view" readOnly name="ch_tue" rows="5"   placeholder="Enter Masstime and Mass Language" onChange={this.handleInputChange} onBlur={this.handleInputBlur} value={this.state.ch_tue}></textarea>
								  {this.validator.message('Content', this.state.ch_tue, '')}
								</div>
							</div>
					 </Col>
					 
					 <Col md={6}>
						<div className="form-group" >
						        <label className="label-text" >Wednesday<span className="text-danger"></span></label>
								 <div className="md1-form">
								  <textarea type="text" id="form7" className="md1-textarea form-control single-line-input form_view" readOnly name="ch_wed" rows="5"   placeholder="Enter Masstime and Mass Language" onChange={this.handleInputChange} onBlur={this.handleInputBlur} value={this.state.ch_wed}></textarea>
								  {this.validator.message('Content', this.state.ch_wed, '')}
								</div>
							</div>
					 </Col>
					 
					 <Col md={6}>
						<div className="form-group" >
						        <label className="label-text" >Thursday<span className="text-danger"></span></label>
								 <div className="md1-form">
								  <textarea type="text" id="form7" className="md1-textarea form-control single-line-input form_view" readOnly name="ch_thu" rows="5"   placeholder="Enter Masstime and Mass Language" onChange={this.handleInputChange} onBlur={this.handleInputBlur} value={this.state.ch_thu}></textarea>
								  {this.validator.message('Content', this.state.ch_thu, '')}
								</div>
							</div>
					 </Col>
					 
					
					 
					 <Col md={6}>
						<div className="form-group" >
						        <label className="label-text" >Friday<span className="text-danger"></span></label>
								 <div className="md1-form">
								  <textarea type="text" id="form7" className="md1-textarea form-control single-line-input form_view" readOnly name="ch_fri" rows="5"   placeholder="Enter Masstime and Mass Language" onChange={this.handleInputChange} onBlur={this.handleInputBlur} value={this.state.ch_fri}></textarea>
								  {this.validator.message('Content', this.state.ch_fri, '')}
								</div>
							</div>
					 </Col>
					 
					 <Col md={6}>
						<div className="form-group" >
						        <label className="label-text" >Saturday<span className="text-danger"></span></label>
								 <div className="md1-form">
								  <textarea type="text" id="form7" className="md1-textarea form-control single-line-input form_view" readOnly name="ch_sat" rows="5"   placeholder="Enter Masstime and Mass Language" onChange={this.handleInputChange} onBlur={this.handleInputBlur} value={this.state.ch_sat}></textarea>
								  {this.validator.message('Content', this.state.ch_sat, '')}
								</div>
							</div>
					</Col>*/}
					 
					  <Col md={6}>
						<div className="form-group" >
						        <label className="label-text" >Special Mass<span className="text-danger"></span></label>
								 <div className="md1-form">
								  <textarea type="text" id="form7" className="md1-textarea form-control single-line-input form_view" readOnly name="ch_sat" rows="5"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} value={this.state.ch_spmass}></textarea>
								  {this.validator.message('Content', this.state.ch_spmass, '')}
								</div>
							</div>
					 </Col>
					 
					  <Col md={6}>
						<div className="form-group" >
						        <label className="label-text" >Church Festival Dates<span className="text-danger"></span></label>
								 <div className="md1-form">
								  <textarea type="text" id="form7" className="md1-textarea form-control single-line-input form_view" readOnly name="ch_sat" rows="5"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} value={this.state.ch_fest_month}></textarea>
								  {this.validator.message('Content', this.state.ch_fest_month, '')}
								</div>
							</div>
					 </Col>
						
					</Row>	
					
				</form>
				</Col>
			</div>
		);
	}
	
}
export default ViewMass;


