import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';
import localIpUrl from 'local-ip-url';

import SimpleLineIcon from 'react-simple-line-icons';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class Dashboard extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			userrole : Reqst.getTokenData('role','data'),
			churchid : Reqst.getTokenData('id','data'),
			validupto : Reqst.getTokenData('validupto','data'),
			parishprist : Reqst.getTokenData('parishprist','data'),
			prcount : 0,
			waittestcount : 0,
			totusers : 0,
			todayusers : 0,
			lad : 'Loading..',
			lmd : 'Loading..'
		}
		
	}
	
	componentDidMount(){
		
		this.getprcount();
		this.getwaittestcount();
		this.getlastann();
		this.getlastmanna();
		this.getuserscount();
		
	}
	
	getlastann(){
		 $("#showLoader").show();
		 this.setState({lad : 'Loading..'});
		Reqst.GetData('appcontent/getrecentannouncement/'+this.state.churchid).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({lad : res.msg});
			}
		});
	}
	
	getlastmanna(){
		 $("#showLoader").show();
		this.setState({lmd : 'Loading..'});
		Reqst.GetData('appcontent/getrecentmanna/'+this.state.churchid).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({lmd : res.msg});
			}
		});
	}
	
	getprcount(){
		 $("#showLoader").show();
		 this.setState({prcount : 0});
		Reqst.GetData('appcontent/getwaitingprayerreqcount/'+this.state.churchid).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({prcount : res.count});
			}
		});
	}
	
	getuserscount(){
		 $("#showLoader").show();
		 this.setState({prcount : 0});
		Reqst.GetData('appcontent/getappuserscount/'+this.state.churchid).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res.status=='1'){
				this.setState({totusers : res.totalcount,todayusers:res.todaycount});
			}
		});
	}
	
	getwaittestcount(){
		 $("#showLoader").show();
		 this.setState({waittestcount : 0});
		Reqst.GetData('appcontent/getwaitingtestimoncount/'+this.state.churchid).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({waittestcount : res.count});
			}
		});
	}
	
	

	render(){
	
		return(
			<div className="container payContainer" >
				<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
					<h1 className="h2">Dashboard</h1>
						
				  </div>
				{this.state.userrole=='admin' || this.state.userrole=='staff' ?			  
				<Row>
				<Col md={12} >
				<div style={{textAlign: 'center'}}>
				   <span style={{wordBreak: 'break-word',textTransform:'capitalize',fontSize:'20px'}}><b style={{color:'#007bff'}}>{Reqst.getTokenData('name','data')} / {Reqst.getTokenData('parish','data')}</b></span>
				  </div>
				<hr/>
				<span style={{wordBreak: 'break-word',textTransform:'capitalize',fontSize:'15px'}}>Welcome <b style={{color:'#007bff'}}>{this.state.parishprist}</b> </span>
				<span style={{float:'right',color:'#007bff',wordBreak: 'break-word',textTransform:'capitalize',fontSize:'15px'}}><b>Subscription Valid for Life Time</b> </span>
				<hr/>
				 </Col>
				 <br/> <br/>
					<div className="col-md-6 grid-margin stretch-card">
					  <div className="card" style={{borderLeft: '2px solid #4CAF50',marginBottom: '10px'}}>
						<div className="card-body">
						  <div className="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
							<h3 className="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0" style={{wordBreak: 'break-word'}}>Prayer Requests &nbsp;<a href="#/appcontent/prayerrequests"><span className="badge">{this.state.prcount}</span></a></h3>
							<i className="fa fa-list-alt fa-lg"></i>
						  </div>  
						  <br/>
						  <p className="card-title text-md-center text-xl-left">Waiting for Admin Approval</p>
						  <p className="mb-0 mt-2 text-primary" style={{textTransform:'capitalize'}}></p>
						</div>
					  </div>
					</div>
					
					<div className="col-md-6 grid-margin stretch-card">
					  <div className="card" style={{borderLeft: '2px solid #4CAF50',marginBottom: '10px'}}>
						<div className="card-body">
						  <div className="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
							<h3 className="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0" style={{wordBreak: 'break-word'}}>Testimonials &nbsp;<a href="#/appcontent/testimonials"><span className="badge">{this.state.waittestcount}</span></a></h3>
							<i className="fa fa-list-alt fa-lg"></i>
						  </div> 
						  <br/>
						  <p className="card-title text-md-center text-xl-left">Waiting for Admin Approval</p>
						  <p className="mb-0 mt-2 text-primary" style={{textTransform:'capitalize'}}></p>
						</div>
					  </div>
					</div>
					
					
					<div className="col-md-6 grid-margin stretch-card">
					  <div className="card" style={{borderLeft: '2px solid #4CAF50',marginBottom: '10px'}}>
						<div className="card-body">
						  <p className="card-title text-md-center text-xl-left"></p>
						  <div className="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
							<h3 className="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0" style={{wordBreak: 'break-word'}}>Announcement</h3>
							<i className="fa fa-bell-o fa-lg"></i>
						  </div>  
						  <br />
						   <p className="card-title text-md-center text-xl-left">Recent Announcement on  {this.state.lad}</p>
						   
						   <p className="card-title text-md-center text-xl-left"> Add New Announcement <a href="#/appcontent/addannouncement"><i className="fa fa-edit fa-lg"></i></a></p>
						</div>
					  </div>
					</div>
					
					<div className="col-md-6 grid-margin stretch-card">
					  <div className="card" style={{borderLeft: '2px solid #4CAF50',marginBottom: '10px'}}>
						<div className="card-body">
						  <p className="card-title text-md-center text-xl-left"></p>
						  <div className="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
							<h3 className="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0" style={{wordBreak: 'break-word'}}>Daily Sharings</h3>
							<i className="fa fa-calendar fa-lg"></i>
						  </div>  
						  <br />
						   <p className="card-title text-md-center text-xl-left">Recent Daily Sharing on  {this.state.lmd}</p>
						   
						   <p className="card-title text-md-center text-xl-left"> Add New Daily Sharings <a href="#/appcontent/adddailymanna"><i className="fa fa-edit fa-lg"></i></a></p>
						</div>
					  </div>
					</div>
					
					
					<div className="col-md-12 grid-margin stretch-card">
					  <div className="card" style={{borderLeft: '2px solid #4CAF50',marginBottom: '10px'}}>
						<div className="card-body">
						  <p className="card-title text-md-center text-xl-left"></p>
						  <div className="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
							<h3 className="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0" style={{wordBreak: 'break-word'}}>Statistics </h3>
							<i className="fa fa-bar-chart fa-lg"></i>
						  </div>  
						  <br />
						   <p className="card-title text-md-center text-xl-left">Total Users : {this.state.totusers}</p>
						   
						   <p className="card-title text-md-center text-xl-left">Today’s Visits : {this.state.todayusers}</p>
						</div>
					  </div>
					</div>
					
					
					
					<Col md={12} >
					
						
						<Row style={{ marginTop: '30px' }} >
							
						</Row>
						
						<Row>
							<Col md={12} >
								<ListGroup>
									<ListGroup.Item>IP : <b><i>{localIpUrl('public')}</i></b></ListGroup.Item>
									<ListGroup.Item>Last Login : {Reqst.getTokenData('lastlogin')}</ListGroup.Item>
									<ListGroup.Item>Last Ip : {Reqst.getTokenData('lastip')}</ListGroup.Item>
								</ListGroup>
							</Col>
						</Row>
				
					</Col>
					
				
				
				</Row>
				: 
				<Row>
				
					<div className="col-md-3 grid-margin stretch-card">
					  <div className="card">
						<div className="card-body">
						  <p className="card-title text-md-center text-xl-left" style={{textTransform:'capitalize'}}>Welcome {this.state.userrole} </p>
						  <div className="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
							<h3 className="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{Reqst.getTokenData('name','data')}</h3>
							<i className="ti-calendar icon-md text-muted mb-0 mb-md-3 mb-xl-0"></i>
						  </div>  
						  <p className="mb-0 mt-2 text-primary"><span className="text-black ml-1"></span></p>
						</div>
					  </div>
					</div>
					
					<Col md={12} >
					
						
						<Row style={{ marginTop: '30px' }} >
							
						</Row>
						
						<Row>
							<Col md={12} >
								<ListGroup>
									<ListGroup.Item>IP : <b><i>{localIpUrl('public')}</i></b></ListGroup.Item>
									<ListGroup.Item>Last Login : {Reqst.getTokenData('lastlogin')}</ListGroup.Item>
									<ListGroup.Item>Last Ip : {Reqst.getTokenData('lastip')}</ListGroup.Item>
								</ListGroup>
							</Col>
						</Row>
				
					</Col>
					
				
				
				</Row>
				}
			</div>
		);
	}
	
}
export default Dashboard;

