import React, { Component } from 'react';
import { BrowserRouter, Link, HashRouter, Switch  } from 'react-router-dom';
import * as Reqst from '../tie/Reqst';
import Sanitize from '../tie/Sanitize';
import { Router, Route } from 'react-router';

import ReatilerLayout from '../pages/Retailer/Layout';
import BillingLayout from '../pages/Billing/Layout';

import Home from '../pages/Home';
import Login from '../pages/Retailer/Login';
import Register from '../pages/Retailer/Login/Register';
import ForgotPassword from '../pages/Billing/Login/ForgotPassword';

import Billing_Login from '../pages/Billing/Login';
import Billing_Dashboard from '../pages/Billing/Dashboard';
import ErrorPage from '../pages/Billing/ErrorPage';
import Billing_Products from '../pages/Billing/Products';
import Billing_Product_Category from '../pages/Billing/Products/Category';

import Retailer_Dashboard from '../pages/Retailer/Dashboard';
import Retailer_Pay from '../pages/Retailer/Pay';
import Retailer_TransactionHistory from '../pages/Retailer/TransactionHistory';

import ChurchSetup from '../pages/Billing/Views/Setup/AddChurch';
import ViewChurch from '../pages/Billing/Views/Setup/ViewChurch';
import Areas from '../pages/Billing/Views/Setup/Zonals';
import AddChurch from '../pages/Billing/Views/Admin/ChurchAdd';
import Churches from '../pages/Billing/Views/Admin/Churches';
import UpdateChurch from '../pages/Billing/Views/Admin/UpdateChurch';
import AddUser from '../pages/Billing/Views/Admin/UserAdd';
import Users from '../pages/Billing/Views/Admin/Users';
import UpdateUser from '../pages/Billing/Views/Admin/UserEdit';
import ChurchUsers from '../pages/Billing/Views/Users/ChurchUsers';
import AddChurchUser from '../pages/Billing/Views/Users/AddChurchUser';
import UpdateChurchUser from '../pages/Billing/Views/Users/UpdateChurchUser';
import HeadList from '../pages/Billing/Views/Family/HeadList';
import AddHead from '../pages/Billing/Views/Family/AddHead';
import UpdateHead from '../pages/Billing/Views/Family/UpdateHead';
import Memberlist from '../pages/Billing/Views/Family/Memberlist';
import AddMember from '../pages/Billing/Views/Family/AddMember';
import ViewProfileCard from '../pages/Billing/Views/Family/ViewProfileCard';
import UpdateMember from '../pages/Billing/Views/Family/UpdateMember';
import AddSupporter from '../pages/Billing/Views/Supporters/AddSupporter';
import SupporterList from '../pages/Billing/Views/Supporters/SupporterList';
import UpdateSupporter from '../pages/Billing/Views/Supporters/UpdateSupporter';
import Groups from '../pages/Billing/Views/Groups/Groups';
import AddGroupMember from '../pages/Billing/Views/Groups/AddGroupMember';
import ViewGroupMembers from '../pages/Billing/Views/Groups/ViewGroupMembers';
import BulkSms from '../pages/Billing/Views/Communication/GroupSms';
import UpdateProfile from '../pages/Billing/Views/Admin/ProfileUpdate';
import Announcement from '../pages/Billing/Views/Appcontent/Announcement';
import AddEvent from '../pages/Billing/Views/Appcontent/AddEvent';
import EventList from '../pages/Billing/Views/Appcontent/EventList';
import UpdateEvent from '../pages/Billing/Views/Appcontent/UpdateEvent';
import AddManna from '../pages/Billing/Views/Appcontent/AddManna';
import AddDailyManna from '../pages/Billing/Views/Appcontent/AddDailyManna';
import Mannas from '../pages/Billing/Views/Appcontent/Mannas';
import AddPage from '../pages/Billing/Views/Appcontent/AddPage';
import Pages from '../pages/Billing/Views/Appcontent/PageList';
import UpdatePage from '../pages/Billing/Views/Appcontent/UpdatePage';
import MemberReports from '../pages/Billing/Views/Family/MemberReports';
import PrayerRequests from '../pages/Billing/Views/Appcontent/PrayerRequests';
import Testimonials from '../pages/Billing/Views/Appcontent/Testimonials';
import AllAnnouncements from '../pages/Billing/Views/Appcontent/AllAnnoncements';
import AddAnnouncement from '../pages/Billing/Views/Appcontent/AddAnnouncement';
import ViewEvent from '../pages/Billing/Views/Appcontent/EventImages';
import ChurchMass from '../pages/Billing/Views/Setup/ChurchSetup';
import ViewMass from '../pages/Billing/Views/Setup/ViewMass';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
)



export default () => (
	
<HashRouter>
	

		<Switch>
			 
			<Route path="/" exact component={Billing_Login}    />
			<AppRoute path="/dashboard" exact layout={BillingLayout} component={Billing_Dashboard}    />
			<AppRoute path="/invalidaccess" exact layout={BillingLayout} component={ErrorPage}    />
			<AppRoute path="/products" exact layout={BillingLayout} component={Billing_Products}    />
			<AppRoute path="/products/category" exact layout={BillingLayout} component={Billing_Product_Category}    />
			
			<Route path="/login" component={Login} />
			<Route path="/register" component={Register} />
			<Route path="/forgot-password" component={ForgotPassword} />
	

			<AppRoute path="/dash" layout={ReatilerLayout} component={Retailer_Dashboard} />
			<AppRoute path="/pay" layout={ReatilerLayout} component={Retailer_Pay} />
			<AppRoute path="/transaction-history" layout={ReatilerLayout} component={Retailer_TransactionHistory} />
			<AppRoute path="/setup/church" exact layout={BillingLayout} component={ChurchSetup}  />
			<AppRoute path="/setup/viewchurch" exact layout={BillingLayout} component={ViewChurch}  />
			<AppRoute path="/setup/viewmass" exact layout={BillingLayout} component={ViewMass}  />
			<AppRoute path="/setup/mass" exact layout={BillingLayout} component={ChurchMass}  />
			<AppRoute path="/setup/areas" exact layout={BillingLayout} component={Areas}    />
			<AppRoute path="/manage/addchurch" exact layout={BillingLayout} component={AddChurch}   />
			<AppRoute path="/manage/churches" exact layout={BillingLayout} component={Churches}    />
			<AppRoute path="/manage/editchurch/:id" exact layout={BillingLayout} component={UpdateChurch}  />
			<AppRoute path="/manage/adduser" exact layout={BillingLayout} component={AddUser}   />
			<AppRoute path="/manage/users" exact layout={BillingLayout} component={Users}   />
			<AppRoute path="/manage/updateuser/:id" exact layout={BillingLayout} component={UpdateUser}  />
			<AppRoute path="/user/churchusers" exact layout={BillingLayout} component={ChurchUsers}  />
			<AppRoute path="/user/addchurchuser" exact layout={BillingLayout} component={AddChurchUser}  />
			<AppRoute path="/user/updatechurchuser/:id" exact layout={BillingLayout} component={UpdateChurchUser}  />
			<AppRoute path="/family/list" exact layout={BillingLayout} component={HeadList}  />
			<AppRoute path="/family/addhead" exact layout={BillingLayout} component={AddHead}  />
			<AppRoute path="/family/updatehead/:id" exact layout={BillingLayout} component={UpdateHead}  />
			<AppRoute path="/family/memberlist" exact layout={BillingLayout} component={Memberlist}  />
			<AppRoute path="/family/addmember" exact layout={BillingLayout} component={AddMember}  />
			<AppRoute path="/family/reports" exact layout={BillingLayout} component={MemberReports}  />
			<AppRoute path="/supporter/add" exact layout={BillingLayout} component={AddSupporter}  />
			<AppRoute path="/supporter/list" exact layout={BillingLayout} component={SupporterList}  />
			<AppRoute path="/supporter/updatesupporter/:id" exact layout={BillingLayout} component={UpdateSupporter}  />
			<AppRoute path="/family/updatemember/:id" exact layout={BillingLayout} component={UpdateMember}  />
			<AppRoute path="/family/viewprofile/:id" exact layout={BillingLayout} component={ViewProfileCard}  />
			<AppRoute path="/group/list" exact layout={BillingLayout} component={Groups}  />
			<AppRoute path="/group/addgroupmember" exact layout={BillingLayout} component={AddGroupMember}  />
			<AppRoute path="/group/viewmembers/:id" exact layout={BillingLayout} component={ViewGroupMembers}  />
			<AppRoute path="/sms/bulk" exact layout={BillingLayout} component={BulkSms}  />
			<AppRoute path="/myprofile" exact layout={BillingLayout} component={UpdateProfile}  />
			<AppRoute path="/appcontent/updateannouncement/:id" exact layout={BillingLayout} component={Announcement}  />
			<AppRoute path="/appcontent/announcements" exact layout={BillingLayout} component={AllAnnouncements}  />
			<AppRoute path="/appcontent/addannouncement" exact layout={BillingLayout} component={AddAnnouncement}  />
			<AppRoute path="/appcontent/addevent" exact layout={BillingLayout} component={AddEvent}  />
			<AppRoute path="/appcontent/events" exact layout={BillingLayout} component={EventList}  />
			<AppRoute path="/appcontent/updateevent/:id" exact layout={BillingLayout} component={UpdateEvent}  />
			<AppRoute path="/appcontent/updatedailymanna/:id" exact layout={BillingLayout} component={AddManna}  />
			<AppRoute path="/appcontent/adddailymanna" exact layout={BillingLayout} component={AddDailyManna}  />
			<AppRoute path="/appcontent/dailymanna" exact layout={BillingLayout} component={Mannas}  />
			<AppRoute path="/appcontent/addpage" exact layout={BillingLayout} component={AddPage}  />
			<AppRoute path="/appcontent/pages" exact layout={BillingLayout} component={Pages}  />
			<AppRoute path="/appcontent/prayerrequests" exact layout={BillingLayout} component={PrayerRequests}  />
			<AppRoute path="/appcontent/updatepage/:id" exact layout={BillingLayout} component={UpdatePage}  />
			<AppRoute path="/appcontent/testimonials" exact layout={BillingLayout} component={Testimonials}  />
			<AppRoute path="/appcontent/viewevent/:id" exact layout={BillingLayout} component={ViewEvent}  />
			 			 
		</Switch>
	

</HashRouter>

);