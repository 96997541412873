import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';
import Logo from '../../../assets/img/dbs300.png';
import swal from 'sweetalert';

import {Row, Col, Nav, Navbar, Dropdown,  NavDropdown,Form, FormControl, Button } from 'react-bootstrap';
import SimpleLineIcon from 'react-simple-line-icons';
import * as FeatherIcon from 'react-feather';

import * as Menus from './Menus';
import MenuLi from './MenuLi';

const styles = {
	iconStyle: {
		marginTop: '-3px',
		marginRight: '6px'
	}
}

class Sidebar extends Component{
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
			showLoader: false,
			userrole : Reqst.getTokenData('role','data')
		}
		this.handleLogOut = this.handleLogOut.bind(this);
	}
	
	componentDidMount(){
		
		
			$('body,html').click(function (e) {
				if ($(window).width() < 600) {
					var container = $("#ps-sidebar");

					if (!container.is(e.target) && container.has(e.target).length === 0) {
						container.hide(80);

					}
				}
			});

	}

	handleLogOut(){
		if(window.confirm('Do you want to logout?')){
			Sanitize.setStorage('iscatholic_login','no');
			Sanitize.setStorage('catho_data','noooooo');
			this.setState({isRedirect:true});
		}
	}
	
	render(){
		
		if(this.state.isRedirect)
		{
			return (<Redirect to="/" />);
		}
	
		let activeMenu = '/'+window.location.hash.split( '/' )[1];
		
		return(
			 	<nav className="col-md-2 d-md-block bg-light sidebar" id="ps-sidebar">
				  <div className="sidebar-sticky scrollbar" id="style-3" >
					<ul className="nav flex-column">
					{this.state.userrole=='admin' || this.state.userrole=='staff' ?		
					
						Menus.Menu.map((item,i)=>{
						
						return <MenuLi 
								title={item.value} 
								icon={item.icon} 
								submenu={item.submenu ? item.submenu : null}
								to={item.path} />;
						
						
						 {/*return <li className="nav-item">
						 <Link className={item.path==activeMenu ? 'nav-link active' : 'nav-link'} to={item.path} >
								{item.icon}
									{item.value} 
							</Link>
						 </li>;*/}
					  
						})
					:
					
					Menus.AdminMenu.map((item,i)=>{
						
						return <MenuLi 
								title={item.value} 
								icon={item.icon} 
								submenu={item.submenu ? item.submenu : null}
								to={item.path} />;
						
						
						 {/*return <li className="nav-item">
						 <Link className={item.path==activeMenu ? 'nav-link active' : 'nav-link'} to={item.path} >
								{item.icon}
									{item.value} 
							</Link>
						 </li>;*/}
					  
						})
					
					
					}
					</ul>

					
					<ul className="nav flex-column mb-2">
					  <li className="nav-item">
						<a className="nav-link" href="javascript:;" onClick={this.handleLogOut} >
						  <FeatherIcon.LogOut size={17} style={styles.iconStyle} />
						  Logout
						</a>
					  </li>
					</ul>
					
				  </div>
				</nav>
		);
	}
	
}
export default Sidebar;

