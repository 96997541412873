import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';

import { Row, Col, Nav, Tab, Table } from 'react-bootstrap';


class RechargeTransaction extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
	}
	
	componentDidMount(){
		
	}


	render(){
	
		return(
			<div>
				<Helmet>
					<title>{GC.APPTITLE} | Recharge Transaction History</title>
				</Helmet>
				<Row>
					<Col md={12}>
						<h4 className="my-title" >Recharge Transaction History</h4>
						<Table hover bordered>
							<thead>
								<tr>
									<th>S.No</th>
									<th>Operator</th>
									<th>Mobile Number</th>
									<th>Amount</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1.</td>
									<td>Airtel</td>
									<td>9952667533</td>
									<td>3500.00</td>
									<td>Success</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
			</div>
		);
	}
	
}
export default RechargeTransaction;

