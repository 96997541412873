import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import noImg from '../../../../assets/img/noimage_200.png';
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PageTitle from '../../PageTitle';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
const tabletd ={
	
	border : '1px solid #063977',borderCollapse : 'collapse',textAlign: 'center',color : '#063977',textTransform:'capitalize'
};


class MemberReports extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			company : Reqst.getTokenData('companyid','cp'),
			memberlist : false,
			members : [],
			grouplist : [],
			memlist : [],
			zonallist : [],
			fview : 2,
			view : 0,
			userrole : Reqst.getTokenData('role','data'),
			user : Reqst.getTokenData('id','data')
		}
		this.validator = new SimpleReactValidator();
		this.handleFormSubmit= this.handleFormSubmit.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.printDocument = this.printDocument.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	
	printDocument() {
		
		//$(".print-report").hide();
		var content = document.getElementById("print_report");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	onSelectMem(id,e){
		
		if(e.target.checked){
			
			if(!this.checkidExists(id)){
				this.state.memlist.push({"id":id});
			}
			console.log(this.state.memlist);
		}
		else{
			
			
			if(this.checkidExists(id)){
				var json = this.state.memlist;
				var index = this.state.memlist.findIndex(function(obj){
		        return (obj.id == id); });
                json[index]['id']='';
			}
			
		}
		
		
		
	}
	
	checkidExists(id){
		
		var bstate=false;
		var needle = id;
			for (var i = 0; i < this.state.memlist.length; i++){
			  // look for the entry with a matching `code` value
			  if (this.state.memlist[i].id == needle){
				 // we found it
				// obj[i].name is the matched result
					bstate=true;
				break;
			  }
			else{
				bstate= false;
			 }
		}
		return bstate;
	}
	
	 showFilter() {
       
	   if(this.state.fview==2 || this.state.fview==1){
		   this.setState({fview:0});
	   }
	   else{
		   
		   this.setState({fview:1});
	   }
    }
	
	
	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleFormSubmit(e){
		e.preventDefault();
		//if (this.validator.allValid()) {
		    $("#showLoader").show();
		   this.setState({showLoader:true});
			this.setState({members:[]});
			var data = Sanitize.objectifyForm($("#frmMemSearch").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('group/membersearch/',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					$("#showLoader").hide();
					this.setState({showLoader:false});
					document.getElementById("frmMemSearch").reset();
					this.setState({
						members : res['data'].data,
						view : 1,
						fview : 0
					});
					
				}			
				else
				{
					$("#showLoader").hide();
                    this.setState({showLoader:false});					
					swal("Error",res['data'].msg,"error");
				}
			});
		/* }
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		} */
	}
	
	componentDidMount(){
		
		this.zoanldata();
		this.groupdata();
		this.churchdata();
		
	}
	
	churchdata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getpastorchurchdata/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({
					 ch_name : res[0]['name'],
					 ch_city : res[0]['city'],
					 ch_addr : res[0]['address'],
					 ch_pin : res[0]['pincode'],
					 country : res[0]['country'],
					 region : res[0]['state'],
					 ch_phone : res[0]['phone'],
					 ch_mobile : res[0]['mobile'],
					 ch_mail : res[0]['email'],
					 photo : res[0]['logo'],
					 ch_web : res[0]['website']
				});
			}
		});
	}
	
	groupdata(){
		 $("#showLoader").show();
		Reqst.GetData('group/getchurchgroups/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({grouplist : res});
			}
		});
	}
	
	zoanldata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getchurchzonals/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({zonallist : res});
			}
		});
	}

	render(){
		

		
		const { country, region } = this.state;
		
		
		
		var areas = this.state.zonallist;
		var groups = this.state.grouplist;
		
		this.genders = [
		{value : '', name : '-Select-'},
		{value : 'Male', name : 'Male'},
		{value : 'Female', name : 'Female'}
	   ];
	   
	   this.types = [
		{value : '', name : '-Select-'},
		{value : 'head', name : 'Head'},
		{value : 'member', name : 'Member'}
	   ];
	  
	   
	    this.education = [
		{value : '', name : '-Select-'},
		{value : 'Illiterate', name : 'Illiterate'},
		{value : 'Primary', name : 'Primary'},
		{value : 'Higher', name : 'Higher'},
		{value : 'Secondary', name : 'Secondary'},
		{value : 'Diploma', name : 'Diploma'},
		{value : 'UG', name : 'UG'},
		{value : 'PG', name : 'PG'}
	   ];
	   
	   this.occupation = [
		{value : '', name : '-Select-'},
		{value : 'Pastor', name : 'Pastor'},
		{value : 'Business', name : 'Business'},
		{value : 'Doctor', name : 'Doctor'},
		{value : 'Engineer', name : 'Engineer'},
		{value : 'Farmer', name : 'Farmer'},
		{value : 'Govt/Pvt Service', name : 'Govt/Pvt Service'},
		{value : 'Labour', name : 'Labour'},
		{value : 'Lawyer', name : 'Lawyer'},
		{value : 'Professor', name : 'Professor'},
		{value : 'Professional', name : 'Professional'},
		{value : 'Retired', name : 'Retired'},
		{value : 'Teacher', name : 'Teacher'},
		{value : 'Unemployed', name : 'Unemployed'}
	   ];
		
		 if(this.state.view == 1){
		
		   $(".tab_data").show();
		}
		else{
			
			$(".tab_data").hide();
		}
	
	 if(this.state.fview == 2 || this.state.fview == 1){
		
		$(".filter_area").show();
	 }
	 else{
			
		$(".filter_area").hide();
	 }
		
		if(this.state.userrole!='admin' && this.state.userrole!='staff')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
	   if(this.state.memberlist)
		{
			return(<Redirect to="/family/memberlist" />);
		}
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Member Reports </title>
				</Helmet>
				
				<PageTitle title="Member Reports" >
					<div className="btn-toolbar mb-2 mb-md-0">
					<div className="btn-group mr-2">
					 <button className="btn btn-sm btn-danger" onClick={() => this.printDocument() }><i className="fa fa-print"></i> Print</button>
					  &nbsp;
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.showFilter()}} >
							<i className="fa fa-search"></i>&nbsp;Show / Hide Filters
						</button>
					
					  </div>
					</div>
				</PageTitle>
				<Row className="filter_area">
				  <Col md="12">
				<form action="" method="post" encType="multipart/form-data" id="frmMemSearch" onSubmit={this.handleFormSubmit} >
				<input type="hidden" name="userid" value={this.state.user} />
				<input type="hidden" name="companyid" value={this.state.company} />
					<Row>
					
						<Col md={4} >
							<div className="form-group" >
								<label className="label-text" >Family Id<span className="text-danger"></span></label>
								<input type="text" name="f_head_id" id="f_head_id" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Name', this.state.f_head_name, '')}
							</div>
						</Col>
						<Col md={4} >
							<div className="form-group" >
								<label className="label-text" >Gender<span className="text-danger"></span></label>
								 <select name="f_head_gen" onChange={this.handleInputChange} id="f_head_gen"  className="form-control single-line-input" >
								{this.genders.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Gender', this.state.f_head_gen, '')}
							</div>
						</Col>
					

						<Col md={4} >
							<div className="form-group" >
								<label className="label-text" >Education<span className="text-danger"></span></label>
								 <select name="f_head_edu" onChange={this.handleInputChange} id="f_head_edu"  className="form-control single-line-input" >
								{this.education.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Education', this.state.f_head_edu, '')}
							</div>
						</Col>
						
						<Col md={4} >
							<div className="form-group" >
								<label className="label-text" >Occupation<span className="text-danger"></span></label>
								 <select name="f_head_occ" onChange={this.handleInputChange} id="f_head_occ"  className="form-control single-line-input" >
								{this.occupation.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Occupation', this.state.f_head_occ, '')}
							</div>
						</Col>
						<Col md={4} >
							<div className="form-group" >
								<label className="label-text" >Area<span className="text-danger"></span></label>
								 <select name="f_head_area" onChange={this.handleInputChange} id="f_head_area"  className="form-control single-line-input" >
								  <option value="">Select Area</option>
									{
										areas.map(function (item) {
											return <option value={item.id }>{item.name}</option>;
										})
									}
							 </select>
								 {this.validator.message('Area', this.state.f_head_area, '')}
							</div>
						</Col>
						<Col md={4} >
							<div className="form-group" >
								<label className="label-text" >Type<span className="text-danger"></span></label>
								 <select name="f_mem_type" onChange={this.handleInputChange} id="f_mem_type"  className="form-control single-line-input" >
								{this.types.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Gender', this.state.f_mem_type, '')}
							</div>
						</Col>
						<Col md={3} >
							<div className="form-group" >
								<label className="label-text" >Age From<span className="text-danger"></span></label>
								<input type="number" name="mem_age_from" id="mem_age_from" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Name', this.state.f_head_name, '')}
							</div>
						</Col>
						<Col md={3} >
							<div className="form-group" >
								<label className="label-text" >Age To<span className="text-danger"></span></label>
								<input type="number" name="mem_age_to" id="mem_age_to" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Name', this.state.f_head_name, '')}
							</div>
						</Col>

						
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Search" loadingText="Searching.." className="btn btn-sm btn-success" loading={this.state.showLoader} > 
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
				 </Col>
			</Row>
			<br />
			<br />
				<Row className="tab_data">
				 <Col md="12">
				<div id="print_report">
		             <table id="" align="center">
						<tbody>
						       <tr>
								<td rowSpan="4" style={{marginLeft: 'auto',marginRight : 'auto',textAlign : 'center'}}><img src={this.state.photo!='' || this.state.photo!=null  ? this.state.photo : noImg} width="70" className="img-avatar" /></td>
								</tr>
								<tr>
									<td  style={{marginLeft: 'auto',marginRight : 'auto',textAlign : 'center',fontSize: '18px',fontWeight: 'bold',color : '#8a260e'}} >{this.state.ch_name}</td>
								</tr>
								<tr>
									<td  style={{marginLeft: 'auto',marginRight : 'auto',textAlign : 'center',fontSize: '14px',fontWeight: 'bold',color : '#1d4f71'}}>{this.state.ch_addr} , {this.state.ch_city} - {this.state.ch_pin}</td>
								</tr>
								<tr>
									<td  style={{marginLeft: 'auto',marginRight : 'auto',textAlign : 'center',fontSize: '14px',fontWeight: 'bold',color : '#1d4f71'}}>Mob : {this.state.ch_mobile} Mail : {this.state.ch_mail}</td>
								</tr>
						</tbody>
					  </table>
					  <br/>
					<table id="" align="" width="100%">
						<tbody>
						 
								<tr>
									<td rowSpan="3" style={{fontSize: '18px',fontWeight: 'bold',color : '#8a260e'}} ></td>
									<td style={{fontSize: '18px',fontWeight: 'bold',textAlign : 'center',color : '#8a260e'}} >Total Records : {this.state.members.length}</td>
									<td  style={{fontSize: '18px',fontWeight: 'bold',color : '#8a260e',float:'right'}} ></td>
								</tr>
								
								
						</tbody>
					  </table>
					  <br />
				  <Col md="12">
					<Table responsive style={{border : '1px solid black',borderCollapse : 'collapse'}} width="100%" align="center" id="table-to-xls_report">
					  <thead>
						<tr>
						  <th style={tabletd}>S.No</th>
						  <th style={tabletd}>Member Id</th>
						  <th style={tabletd}>Family Id</th>
						  <th style={tabletd}>Name</th>
						  <th style={tabletd}>Mobile</th>
						  <th style={tabletd}>Email</th>
						</tr>
					  </thead>
					  <tbody>
						{this.state.members.map((item,index) =>
					 
									<tr key = {item}>
										<td  style={tabletd}>{index+1}</td>
										<td style={tabletd}>{item.memberid}</td>
										<td style={tabletd}>{item.familyid}</td>
										<td style={tabletd}>{item.name}</td>
										<td style={tabletd}>{item.mobile}</td>
										<td style={tabletd}>{item.email}</td>
										
									</tr>
								)}
					</tbody>
					</Table>
				    </Col>
				     </div>
					 </Col>
				    <iframe name="print_frame" id="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
					
					</Row>
			</div>
		);
	}
	
}
export default MemberReports;


