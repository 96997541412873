import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PageTitle from '../../PageTitle';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class UserEdit extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			churchlist : [],
			userlist : false,
			user_tab_id : '',
			ch_id : '',
			use_name : '',
			use_gen : '',
			viewpass : false,
			user_mail : '',
			use_mobile : '',
			user_pass : '',
			userrole : Reqst.getTokenData('role','data'),
			user : Reqst.getTokenData('id','data')
		}
		this.validator = new SimpleReactValidator();
		this.handleFormSubmit= this.handleFormSubmit.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	
	viewPassword(){
		this.setState({viewpass : !this.state.viewpass});
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	


	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleFormSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			this.setState({showLoader:true});
			var data = Sanitize.objectifyForm($("#frmUserUpdate").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('setup/updateuser/',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false});	
					swal("Success",res['data'].msg,"success");
					this.getchurches();
					this.getdata();
					
				}			
				else
				{
					this.setState({showLoader: false});	
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	componentDidMount(){
		
		this.getchurches();
		this.getdata();
		
	}
	
	getdata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getusersbyid/'+this.props.match.params.id).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({
					 user_tab_id : res[0]['id'],
					 use_name : res[0]['name'],
					 ch_id : res[0]['companyid'],
					 use_gen : res[0]['gender'],
					 use_mobile : res[0]['mobile'],
					 user_mail : res[0]['email'],
					 user_pass : res[0]['password']
				});
			}
		});
	}
	
	getchurches(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getchurchesallocate/').then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({churchlist : res});
			}
		});
	}
	

	render(){
		
		if(this.state.userrole!='superadmin')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
		if(this.state.userlist)
		{
			return(<Redirect to="/manage/users" />);
		}
		
		var churches = this.state.churchlist;
		
		this.genders = [
		{value : '', name : '-Select-'},
		{value : 'Male', name : 'Male'},
		{value : 'Female', name : 'Female'}
	   ];
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Update User</title>
				</Helmet>
				
				<PageTitle title="Update User" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({userlist:true})}} >
							<i className="fa fa-arrow-left"></i> Back
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
				<form action="" method="post" encType="multipart/form-data" id="frmUserUpdate" onSubmit={this.handleFormSubmit} >
				<input type="hidden" name="userid" value={this.state.user} />
				<input type="hidden" name="user_tab_id" value={this.state.user_tab_id} />
					<Row>
					<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Church<span className="text-danger">*</span></label>
								<select name="ch_id" onChange={this.handleInputChange} value={this.state.ch_id} onBlur={this.handleInputBlur} className="form-control single-line-input" required id="ch_id">
									<option value="">Select Church</option>
									{
										churches.map(function (item) {
											return <option value={item.companyid }>{item.name}</option>;
										})
									}
								</select>
								 {this.validator.message('Church', this.state.ch_id, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Name<span className="text-danger">*</span></label>
								<input type="text" name="use_name" id="use_name" className="form-control single-line-input"  value={this.state.use_name} required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('User Name', this.state.use_name, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Gender<span className="text-danger">*</span></label>
								 <select name="use_gen" onChange={this.handleInputChange} id="use_gen" value={this.state.use_gen} required className="form-control single-line-input" >
								{this.genders.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Gender', this.state.use_gen, 'required')}
							</div>
						</Col>
						
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Mobile (Used as Username)<span className="text-danger">*</span></label>
								<input type="number" name="use_mobile" id="use_mobile" value={this.state.use_mobile} className="form-control single-line-input"  required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('User Mobile', this.state.use_mobile, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Email<span className="text-danger"></span></label>
								<input type="email" name="user_mail" id="user_mail" value={this.state.user_mail} className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('User Email', this.state.user_mail, '')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Password<span className="text-danger">*</span></label>
								<input type={this.state.viewpass == true ? "text" : "password"} name="user_pass" id="user_pass" value={this.state.user_pass} className="form-control single-line-input"  required  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								<span className="text-primary"  onClick={this.viewPassword.bind(this)} style={{position : 'absolute',top : '22px',right : '15px'}}><i className="fa fa-eye fa-lg" ></i></span>
								 {this.validator.message('Password', this.state.user_pass, 'required')}
							</div>
						</Col>
						
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Update" loadingText="Updating.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
			</div>
		);
	}
	
}
export default UserEdit;


