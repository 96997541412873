import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Pagination from "react-js-pagination";
import PageTitle from '../../PageTitle';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class Zonals extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			company : Reqst.getTokenData('companyid','cp'),
			user : Reqst.getTokenData('id','data'),
			userrole : Reqst.getTokenData('role','data'),
			zonallist : [],
			currentPage: 1,
			addZonal : false,
			zonalid : '',
			idd : '',
			zo_name : '',
			zo_charge : '',
			zo_mail  : '',
			zo_mob  : '',
			UpZonal : false,
			todosPerPage: 20,
			activePage : 1
		}
		this.validator = new SimpleReactValidator();
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.onShowChange = this.onShowChange.bind(this);
		this.handleAddSubmit= this.handleAddSubmit.bind(this);
		this.handleUpdateSubmit = this.handleUpdateSubmit.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	
	
	handlePageChange(pageNumber) {
		
		this.setState({activePage: pageNumber});
		this.state.currentPage = Number(pageNumber);
	}
	
	onShowChange(e){
		this.state.todosPerPage = e.target.value;
		
			
			this.getdata();
		
	}
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	
	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleAddSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			this.setState({showLoader:true});
			var data = Sanitize.objectifyForm($("#frmZonaladd").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('setup/addzonal/',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false});	
					swal("Success",res['data'].msg,"success");
					this.setState({addZonal: false});
					this.getdata();
					
				}	
				else
				{
					this.setState({showLoader: false});	
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	handleUpdate(id){
		
		this.state.idd = id;
		this.setState({UpZonal: true});	
		this.zonaldata();
		
	}
	
	handleRemovefn(id){
		
		this.state.idd = id;
		this.removezonal();
		
	}
	
	handleRemove = (dd): void => {
		
		this.state.idd = dd;
		 confirmAlert({
		  title: 'Confirm to Delete',
		  message: 'Are you sure to Delete this Area ?',
		  buttons: [
			{
			  label: 'No'
			},
			{
			  label: 'Yes',
			  onClick: () => this.handleRemovefn(dd)
			}
		  ]
		})
  };
	
	handleUpdateSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			this.setState({showLoader:true});
			var data = Sanitize.objectifyForm($("#frmZonalUpdate").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('setup/updatearea/',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false});	
					swal("Success",res['data'].msg,"success");
					this.setState({UpZonal: false});
					this.getdata();
					
				}	
				else
				{
					this.setState({showLoader: false});	
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	componentDidMount(){
		
		this.getdata();
		
	}
	
	zonaldata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getchurchzonalbyid/'+this.state.idd).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({
					 zo_name : res[0]['name'],
					 zonalid : res[0]['id'],
					 zo_charge : res[0]['areaincharge'],
					 zo_mail : res[0]['email'],
					 zo_mob : res[0]['mobile']
				});
			}
		});
	}
	
	removezonal(){
		 $("#showLoader").show();
		Reqst.GetData('setup/removearea/'+this.state.idd+'/'+this.state.user).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res.status=='1'){
					swal("Success",res.msg,"success");
					this.getdata();
			}
			else{
				
					swal("Error",res['data'].msg,"error");
			}
		});
	}
	
	getdata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getchurchzonals/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({zonallist : res});
			}
		});
	}

	render(){
		
		const { country, region } = this.state;
		
	   if(this.state.userrole!='admin' && this.state.userrole!='staff')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
		if(this.state.zonallist.length>0){
			
			$(".page_btns").show();
		}
		else{
			$(".page_btns").hide();
		}
		
		  /** set showcount select box **/
	this.shows = [
		{value : '10', name : '10'},
		{value : '20', name : '20'},
		{value : '50', name : '50'},
		{value : '100', name : '100'}
	];
	
	// Logic for displaying current todos
	const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
	const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
	const currentTodos = this.state.zonallist.slice(indexOfFirstTodo, indexOfLastTodo);

	const renderTodos = currentTodos.map((item,i) => {
		
	  return <tr key = {item}>
								<td width="20">{i+1}</td>
								<td width="100">{item.name}</td>
								<td width="100">{item.areaincharge}</td>
								<td width="100">{item.mobile}</td>
								<td width="100">{item.email}</td>
								<td width="30">
									<button name="edithw" className="btn btn-info btn-sm" onClick={() => this.handleUpdate(item.id)}  ><i className="fa fa-edit"></i></button>
									&nbsp;&nbsp;
									<button name="removeann" className="btn btn-danger btn-sm" onClick={() => this.handleRemove(item.id)}  ><i className="fa fa-remove"></i></button>
								</td>
							</tr>;
		
	});
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Areas</title>
				</Helmet>
				
				<PageTitle title="Areas" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({addZonal:true})}} >
							<FeatherIcon.CheckSquare size={14} /> Add Area
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
				<Row>
				  <Col md="12">
				 
					<Table responsive hover bordered>
					  <thead>
						<tr>
						  <th>S.No</th>
						  <th>Name</th>
						  <th>Incharge</th>
						  <th>Mobile</th>
						  <th>Email</th>
						  <th>Manage</th>
						</tr>
					  </thead>
					  <tbody>
					  {this.state.zonallist.length>0 ? 
						renderTodos
					  : 
					  <tr><td colSpan="6"><center>No Data Found...</center></td></tr>
					  }
					</tbody>
					</Table>
				  </Col>
				  <Col md="4" className="page_btns">
				  <Pagination
							 activePage={this.state.activePage}
							 itemsCountPerPage={this.state.todosPerPage}
							 pageRangeDisplayed={3}
							 totalItemsCount={this.state.zonallist.length}
							 onChange={this.handlePageChange}
							/>
				  </Col>
				  <Col md="2" className="page_btns">
				   <select name="show" onChange={this.onShowChange} id="show" class="form-control" >
								{this.shows.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>&nbsp;
						entries
				  </Col>
				  <Modal show={this.state.addZonal} className="modal-popup"   size="md" onHide={()=>{this.setState({addZonal:false})}} >
				  <Modal.Header closeButton>
					<Modal.Title>Add Area</Modal.Title>
				  </Modal.Header>
				  <Modal.Body>
						<form action="" method="post" encType="multipart/form-data" id="frmZonaladd" onSubmit={this.handleAddSubmit} >
				<input type="hidden" name="company" value={this.state.company} />
				<input type="hidden" name="userid" value={this.state.user} />
					<Row>
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Area Name<span className="text-danger">*</span></label>
								<input type="text" name="zo_name" id="zo_name" className="form-control single-line-input" required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Area Name', this.state.zo_name, 'required')}
							</div>
						</Col>
						
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Incharge Name<span className="text-danger"></span></label>
								<input type="text" name="zo_charge" id="zo_charge" className="form-control single-line-input"  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Incharge Name', this.state.zo_charge, '')}
							</div>
						</Col>
						
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Mobile<span className="text-danger"></span></label>
								<input type="number" name="zo_mob" id="zo_mob" className="form-control single-line-input"  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Mobile', this.state.zo_mob, '')}
							</div>
						</Col>
						
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Email<span className="text-danger"></span></label>
								<input type="email" name="zo_mail" id="zo_mail" className="form-control single-line-input"  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Email', this.state.zo_mail, '')}
							</div>
						</Col>
						
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Save" loadingText="Saving.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
				  </Modal.Body>
				</Modal>
				<Modal show={this.state.UpZonal} className="modal-popup"   size="md" onHide={()=>{this.setState({UpZonal:false})}} >
				  <Modal.Header closeButton>
					<Modal.Title>Update Area</Modal.Title>
				  </Modal.Header>
				  <Modal.Body>
						<form action="" method="post" encType="multipart/form-data" id="frmZonalUpdate" onSubmit={this.handleUpdateSubmit} >
				      <input type="hidden" name="zonalid" value={this.state.zonalid} />
				       <input type="hidden" name="userid" value={this.state.user} />
					<Row>
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Area Name<span className="text-danger">*</span></label>
								<input type="text" name="zo_name" id="zo_name" className="form-control single-line-input" value={this.state.zo_name} required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Area Name', this.state.zo_name, 'required')}
							</div>
						</Col>
						
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Incharge Name<span className="text-danger"></span></label>
								<input type="text" name="zo_charge" id="zo_charge" className="form-control single-line-input" value={this.state.zo_charge}  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Incharge Name', this.state.zo_charge, '')}
							</div>
						</Col>
						
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Mobile<span className="text-danger"></span></label>
								<input type="number" name="zo_mob" id="zo_mob" className="form-control single-line-input" value={this.state.zo_mob}  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Mobile', this.state.zo_mob, '')}
							</div>
						</Col>
						
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Email<span className="text-danger"></span></label>
								<input type="email" name="zo_mail" id="zo_mail" className="form-control single-line-input" value={this.state.zo_mail}  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Email', this.state.zo_mail, '')}
							</div>
						</Col>
						
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Update" loadingText="Updating.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
				  </Modal.Body>
				</Modal>
				</Row>
			</div>
		);
	}
	
}
export default Zonals;


