import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';
import LoadingBtn from '../../../tie/LoadingBtn';

import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import { Row, Col, Input, Form, Card, InputGroup, Carousel, Modal, Fade, utils } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import BannerImage from '../../../assets/img/api-integration.png';
import Logo from '../../../assets/img/logo.png';
import SliderBackground from '../../../assets/img/slider_350x150.png';


import LoginBanner from './LoginBanner';
import Captcha from '../Captcha';

const styles={
	firstRowStyle:{
		backgroundImage:'linear-gradient(#f9aa1f , #c7418c)',
		height:'calc(100vh)',
	},
	textDiv:{
		color:'#fff',
		marginTop: '20%',
	},
	secondRow: {
		marginTop: '50px',
	},
	dividerStyle: {
		width: '90%',
		marginLeft: '25px',
	},
	btnSignup: {
		marginTop: '80px',
		fontSize: '20px;',
		 width:'300px',
	},
	iconBottom: {
		fontSize: '40px',
		fontWeight: '500',
		color: '#524e4e',
	},
	modalCloseIcon: {
		textAlign:'right',
		marginBottom: '10px',
		cursor: 'pointer',
	},
	resendOtp: {
		float: 'right',
		fontSize: '13px',
	},
	backtoLogin: {
		float: 'right',
		marginTop: '-20px'
	}
}

class Register extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showModal: false,
			modalTitle: '',
			aboutComp: 'recharge',
			hasOtpVerify: false,
			userCountry: 'India',
			userState: '',
		}
		this.validator = new SimpleReactValidator();
		this.handleLoginChange = this.handleLoginChange.bind(this);
		this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
		this.handleCheckedChange = this.handleCheckedChange.bind(this);
		this.handleLoginBlur = this.handleLoginBlur.bind(this);
		this.selectRegion = this.selectRegion.bind(this);
	}
	
	componentDidMount(){

	}
	
	handleLoginChange(e){

		this.setState({[e.target.name]: e.target.value});
	}
	
	handleCheckedChange(e){
		
		this.setState({[e.target.name]: e.target.checked});
	}

	handleLoginBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	handleLoginSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			/*** Handle Login ****/
			this.setState({showLoader:true});
			var data = Sanitize.objectifyForm($("#frmLogin").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('login/',{token: data}).then(res=>{
				console.log(res);
				this.setState({showLoader: false,hasOtpVerify: true});
			});
			
		} else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
		}
	}
	
	selectRegion(val){
		this.setState({userState:val, i__outState: val});
	}

	render(){

		
	
		return(
			<div className="container " style={{ maxWidth:'100%' }} >
				<Helmet>
					<title>{GC.APPTITLE} | User Registration</title>
				</Helmet>
				<Row>
					<Col md={5} style={styles.firstRowStyle} >
						
						<LoginBanner />
						
					</Col>
					<Col md={7} >
						<Row style={styles.secondRow} >
							<Col md={12} >
								<Link to="/login" className="btn btn-info btn-sm" style={styles.backtoLogin} >
									<FeatherIcon.ArrowLeft /> Back to Login
								</Link>
								<h4 className="text-center">SIGN UP</h4>
							</Col>
							<Col md={{ span: 10, offset: 1 }} >
								<Row>
								<Col md={12} >
									<form action="" id="frmLogin" method="post" role="form" encType="multipart/form-data" onSubmit={this.handleLoginSubmit} >
										<Row>
											<Col md={6} >
												<div className="form-group" style={{ marginBottom: '0px' }} >
													<label className="label-text" >Outlet Name *</label>
													<input type="text" name="i__outName" id="i__outName" className="form-control single-line-input input-small" required placeholder="Your Business Name" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} />
													 {this.validator.message('Outlet', this.state.i__outName, 'required')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" style={{ marginBottom: '0px' }} >
													<label className="label-text" >Primary Mobile *</label>
													<input type="text" name="i__outMobile" id="i__outMobile" className="form-control single-line-input input-small" required placeholder="Your Business Mobile" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} />
													 {this.validator.message('Primary mobile', this.state.i__outMobile, 'required|numeric|min:10|max:10')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" style={{ marginBottom: '0px' }} >
													<label className="label-text" >Primary Email *</label>
													<input type="email" name="i__outEmail" id="i__outEmail" className="form-control single-line-input input-small" required placeholder="Your Business Email" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} />
													 {this.validator.message('Primary Email', this.state.i__outEmail, 'required|email')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" style={{ marginBottom: '0px' }} >
													<label className="label-text" >Address *</label>
													<input type="text" name="i__outAddress" id="i__outAddress" className="form-control single-line-input input-small" required placeholder="Your Business Address" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} />
													 {this.validator.message('Address', this.state.i__outAddress, 'required')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" style={{ marginBottom: '0px' }} >
													<label className="label-text" >Place *</label>
													<input type="text" name="i__outPlace" id="i__outPlace" className="form-control single-line-input input-small" required placeholder="Your Business Place" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} />
													 {this.validator.message('Place', this.state.i__outPlace, 'required')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" style={{ marginBottom: '0px' }} >
													<label className="label-text" >City *</label>
													<input type="text" name="i__outCity" id="i__outCity" className="form-control single-line-input input-small" required placeholder="Your Business City" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} />
													 {this.validator.message('City', this.state.i__outCity, 'required')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" style={{ marginBottom: '0px' }} >
													<label className="label-text" >Country *</label>
													<input type="text" name="i__outCountry" id="i__outCountry" className="form-control single-line-input input-small" required placeholder="Your Business City" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} value="India" readOnly />
													 {/*this.validator.message('City', this.state.i__outCity, 'required')*/}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" style={{ marginBottom: '0px' }} >
													<label className="label-text" >State *</label>
													{/*
													<input type="text" name="i__outState" id="i__outState" className="form-control single-line-input input-small" required placeholder="State" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} />
													*/}
													<input type="hidden" name="i__state" value={this.state.i__outState} />
													 <RegionDropdown country={this.state.userCountry} value={this.state.userState} onChange={(val) => this.selectRegion(val)} className="form-control single-line-input input-small" required />
													 {this.validator.message('State', this.state.i__outState, 'required')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" style={{ marginBottom: '0px' }} >
													<label className="label-text" >Pincode *</label>
													<input type="text" name="i__outPin" id="i__outPin" className="form-control single-line-input input-small" required placeholder="Pincode" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} />
													 {this.validator.message('Pincode', this.state.i__outMobile, 'required|numeric|min:6|max:6')}
												</div>
											</Col>
											<Col md={6} >
												<Row>
													<Col md={6} >
														<Captcha />
													</Col>
													<Col md={6} >
														<div className="form-group" style={{ marginBottom: '0px' }} >
															<label className="label-text" >Captcha *</label>
															<input type="text" name="i__captcha" id="i__captcha" className="form-control single-line-input input-small" required placeholder="Captcha" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} />
															 {this.validator.message('Captcha', this.state.i__captcha, 'required')}
														</div>
													</Col>
												</Row>
											</Col>
											
											<Col md={12} >
												<div className="form-groups" style={{ marginBottom: '0px' }} >
													<input type="checkbox" name="i__agreeTerms" id="i__agreeTerms" className="css-checkbox"  onChange={this.handleCheckedChange} />
													<label for="i__agreeTerms" name="checkbox2_lbl" className="css-label lite-blue-check" style={{  fontSize: '13px' }} >
														I agree to the <a href="" target="_blank" >Terms of Use</a> and 
														<a href="" target="_blank"> Privacy Policy</a>
													</label>
													{this.validator.message('Agree', this.state.i__agreeTerms, 'accepted')}
													
												</div>
											</Col>
											
											<Col md={12} style={{ marginTop: '30px' }} >
												<LoadingBtn className="btn btn-warning btn-block" loading={this.state.showLoader} loadingText="Validating..." >
													Sign In
												</LoadingBtn>
											</Col>
										</Row>
									</form>
								</Col>
								</Row>
							</Col>
							
						</Row>
					</Col>
				</Row>
				
			


			</div>
		);
	}
	
}
export default Register;

