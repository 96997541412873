
import axios from 'axios';
import JWT from 'jwt-simple';

const BaseURL = 'https://member.mycatholicchurch.com/services/';
//const BaseURL = 'http://rcapi.nellaitoday.com/';

// *******************************
// Don't change this Secret key
export const PSJSC = 'B175491D4E911CaABbDAF98e13BC9';
// Please keep it to working fine
// *******************************


export function GetData(type, userData) {

	return new Promise((resolve, reject) =>{
		fetch(BaseURL+type, {
			method: 'GET',
			body: JSON.stringify(userData)
		})
		.then((response) => response.json())
		.then((res) => {
			resolve(res);
		})
		.catch((error) => {
			reject(error);
		});
	});
}

export function PostData(type, userData) {
	
	return new Promise((resolve, reject) =>{
		fetch(BaseURL+type, {
			method: 'POST',
			body: JSON.stringify(userData)
		})
		.then((response) => response.json())
		.then((res) => {
			resolve(res);
		})
		.catch((error) => {
			reject(error);
		});
	});
}

export function axGet(type){
	
	return new Promise((resolve, reject) =>{
		axios.get(BaseURL+type, {
			method: 'GET'
		})
		.then((response) =>  {
			resolve(response);
		})
		.catch((error) => {
			reject(error);
		});
	});

}

export function axPost(type,userData){
	
		
	if(navigator.onLine){
		
		return new Promise((resolve, reject) =>{
			axios.post(BaseURL+type,userData).then((response) =>  {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
		});
		
	}
}


export function Token(data){
	var token = JWT.encode(data, PSJSC);
	return token;
}

export function TokenData(data){
	var token = JWT.decode(data, PSJSC);
	return token;
}

export function getTokenData(fieldName,fromData='own'){
	
	var data = localStorage.getItem('catho_data');
	
	
	if(!data) return false;
	
	let s = JWT.decode(data, PSJSC);
	
	if(Object.keys(s).length>0)
	{
		return (s[fieldName]) ? s[fieldName] : false;
	}
	else
	{
		return false;
	}
}