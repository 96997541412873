
/** menu for dashboard page **/
export const Menu = [
	{
		value:'Dashboard',
		path:'/dashboard'
	},
	{
		value:'Pay',
		path:'/pay'
	},
	{
		value:'Transaction History',
		path:'/transaction-history'
	},
	{
		value:'Complaint Management',
		path:'/complaint-management'
	}
];