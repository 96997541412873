import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import SpinnerImg from '../../../assets/img/spinner-rotate.gif';
import Sanitize from '../../../tie/Sanitize';

import {Row, Col, Nav, Navbar, NavDropdown,Form, FormControl, Button } from 'react-bootstrap';
import SimpleLineIcon from 'react-simple-line-icons';
import * as FeatherIcon from 'react-feather';

import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

const styles = {
	firstHeader: {
		padding:'4px 10px',
    	border: '1px solid grey',
	}
}
const loaderInner = {
    marginRight: 'auto',
    marginLeft: 'auto'
}

class Layout extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
	}
	
	componentDidMount(){
		$("body").css('background-color','#fff');	
		 $("#showLoader").hide();
	}


	render(){
	
		return(
			<div style={{ overflowX: 'hidden' }} >
				
				<Header />
				
				 <div className="container-fluid">
					<div className="row">
							
						<Sidebar />
						
						 <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4" style={{ minHeight: 'calc(100vh)'}} >
						 
						  <div id="showLoader"  >
							<div id="showLoaderInner"  >
								<div style={loaderInner} >
									<img src={SpinnerImg} alt="Loading.." style={{ width: '80px' }} />
								</div>						
							</div>
						</div>
							

							{this.props.children}
							
							
						 </main>						 
						 
						 <Footer />
							
					</div>
				</div>
				
			</div>
		);
	}
	
}
export default Layout;

