import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PageTitle from '../../PageTitle';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class GroupSMS extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			smssendto : '',
			count : 0,
			selareas : [],
			areas : [],
			mem_age_from : 0,
			mem_age_to : 150,
			ars : [],
			mem_gen : 'both',
			selgroups : [],
			grouplist : [],
			resgroups : [],
			seledu : [],
			resedu : [],
			selocc : [],
			resocc : [],
			isUnicode : 0,
			smscount : 0,
			company : Reqst.getTokenData('companyid','cp'),
			smsapi : Reqst.getTokenData('smsapi','cp'),
			smssenderid : Reqst.getTokenData('senderid','cp'),
			userrole : Reqst.getTokenData('role','data'),
			user : Reqst.getTokenData('id','data')
		}
		this.validator = new SimpleReactValidator();
		this.handleFormSubmit= this.handleFormSubmit.bind(this);
		this.onOptChange = this.onOptChange.bind(this);
		this.onChkChange = this.onChkChange.bind(this);
		this.handleAreaChange = this.handleAreaChange.bind(this);
		this.handleGroupChange = this.handleGroupChange.bind(this);
		this.handleEduChange = this.handleEduChange.bind(this);
		this.handleOccChange = this.handleOccChange.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		
		this.handleAgeFromChange = this.handleAgeFromChange.bind(this);
		this.handleAgeToChange = this.handleAgeToChange.bind(this);
		this.handleGenChange = this.handleGenChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	
	handleAgeFromChange(e){
		
		if(e.target.value==''){
			this.state.mem_age_from = 0;
		}
		
		this.state.mem_age_from = e.target.value;
		if(e.target.value!=''){
			this.getallcountbyagegender();
		}
	}
	
	handleAgeToChange(e){
		
		if(e.target.value==''){
			this.state.mem_age_to = 200;
		}
		
		this.state.mem_age_to = e.target.value;
		
		if(e.target.value!=''){
		 this.getallcountbyagegender();
		}
	}
	
	handleGenChange(e){
		this.state.mem_gen = e.target.value;
	  if(e.target.value!=''){
		this.getallcountbyagegender();
	  }
	}
	
	handleAreaChange(value){

		this.state.ars = [];
		{value.map(item =>
			
			this.state.ars.push(item.value)
		)}
		this.setState({selareas : value});
		
		if(this.state.ars.length>0){	
		  this.getallcountbyareas();
		}
		else{
		  
	 	  this.setState({count : 0});
	    }
	}
	
	onChkChange(e){
		
		if(e.target.checked){
			
			this.setState({isUnicode : 1});
		}
		else {
			
			this.setState({isUnicode : 0});
		}
		
	}
	

	
	handleGroupChange(value){
		
		this.state.resgroups = [];
		{value.map(item =>
			
			this.state.resgroups.push(item.value)
		)}
		this.setState({selgroups : value});
	   if(this.state.resgroups.length>0){
		this.getallcountbygroups();
	   }
	   else{
		  
		  this.setState({count : 0});
	   }
	}
	
	handleEduChange(value){
		
		this.state.resedu = [];
		{value.map(item =>
			
			this.state.resedu.push(item.value)
		)}
		this.setState({seledu : value});
	   if(this.state.resedu.length>0){
		this.getallcountbyeducation();
	   }
	   else{
		  
		  this.setState({count : 0});
	   }
	}
	
	handleOccChange(value){
		
		this.state.resocc = [];
		{value.map(item =>
			
			this.state.resocc.push(item.value)
		)}
		this.setState({selocc : value});
	   if(this.state.resocc.length>0){
		this.getallcountbyoccupation();
	   }
	   else{
		  
		  this.setState({count : 0});
	   }
	}
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	
	onOptChange(e){
		this.setState({smssendto: e.target.value});
		
		if(e.target.value=='1'){
			
			this.getallcount();
			$(".count_area").show();
			$(".area_div").hide();
			$(".custom_div").hide();
			$(".occ_div").hide();
			$(".edu_div").hide();
			$(".group_div").hide();
		}
		else if(e.target.value=='2'){
			
			this.getallfamcount();
			$(".count_area").show();
			$(".area_div").hide();
			$(".edu_div").hide();
			$(".occ_div").hide();
			$(".custom_div").hide();
			$(".group_div").hide();
		}
		else if(e.target.value=='3'){
			
			this.getallsupcount();
			$(".count_area").show();
			$(".area_div").hide();
			$(".occ_div").hide();
			$(".custom_div").hide();
			$(".edu_div").hide();
			$(".group_div").hide();
		}
		else if(e.target.value=='4'){
			this.setState({selareas : []});
			$(".area_div").show();
			$(".count_area").hide();
			$(".occ_div").hide();
			$(".custom_div").hide();
			$(".edu_div").hide();
			$(".group_div").hide();
		}
		else if(e.target.value=='5'){
			this.setState({selgroups : []});
			$(".area_div").hide();
			$(".edu_div").hide();
			$(".occ_div").hide();
			$(".custom_div").hide();
			$(".count_area").hide();
			$(".group_div").show();
		}
		else if(e.target.value=='6'){
			this.setState({seledu : []});
			$(".area_div").hide();
			$(".edu_div").show();
			$(".custom_div").hide();
			$(".occ_div").hide();
			$(".count_area").hide();
			$(".group_div").hide();
		}
		else if(e.target.value=='7'){
			this.setState({selocc : []});
			$(".area_div").hide();
			$(".edu_div").hide();
			$(".occ_div").show();
			$(".custom_div").hide();
			$(".count_area").hide();
			$(".group_div").hide();
		}
		else if(e.target.value=='8'){
			
			this.state.mem_age_to = 0;
			this.state.mem_age_to = 150;
			this.state.mem_gen = 'both';
			
			$("#mem_age_from").val('');
			$("#mem_age_to").val('');
			$("#mem_gen").val('');
			
			$(".area_div").hide();
			$(".edu_div").hide();
			$(".occ_div").hide();
			$(".custom_div").show();
			$(".count_area").hide();
			$(".group_div").hide();
		}
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	
	clearData(){
		
		this.setState({
		    smssendto : '',
			count : 0,
			areas : [],
			mem_age_from : 0,
			mem_age_to : 150,
			ars : [],
			mem_gen : 'both',
			selgroups : [],
			resgroups : [],
			selareas : [],
			seledu : [],
			resedu : [],
			selocc : [],
			resocc : [],
			isUnicode : 0
		});
		
	}

	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleFormSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			 $("#showLoader").show();
			var data = Sanitize.objectifyForm($("#frmSMSBulk").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('sms/sendbulksms/',{token: data}).then(res=>{
				
				document.getElementById("frmSMSBulk").reset();
				this.clearData();
				$(".count_area").hide();
				
				$("#btn_div").hide();
			    $("#time").show();
					  
			var timeLeft = 10;
			var elem = document.getElementById('time');
			var timerId = setInterval(countdown, 1000);
			function countdown() {
						  if (timeLeft == 0) {
							clearTimeout(timerId);
							$("#btn_div").show();
                             $("#time").hide();
							 alert('SMS Requests Posted Successfully!..');
						  } else {
							elem.innerHTML = timeLeft + ' seconds remaining';
							timeLeft--;
						  }
			}
			
			 $("#showLoader").hide();
			 
				if(res['data'].status=='1')
				{
					// $("#showLoader").hide();
					//document.getElementById("frmHeadadd").reset();
					//swal("Success",res['data'].msg,"success");
					alert(res['data'].msg);
				}			
				else
				{
					 $("#showLoader").hide();
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	componentDidMount(){
		
		$(".count_area").hide();
		$(".area_div").hide();
		$(".group_div").hide();
		$(".custom_div").hide();
		$(".edu_div").hide();
		$(".occ_div").hide();
		this.getareas();
		this.getgroups();
		this.getsmscount();
		
	}
	
	getsmscount(){
	
		
		 if(this.state.smsapi!='' && this.state.smsapi!=null){
			 $("#showLoader").show();
			 $.get('http://bulksms.resellerindia.in/api/balance.php?authkey='+this.state.smsapi+'&type=4',function(o){
				
				$("#smscount").val(o);
				$("#sms_count_head").html('SMS Balance : '+o);
				
				 $("#showLoader").hide();
				
			});
		
		 }
	
    }
	
	getareas(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getchurchzonals/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({areas : res});
			}
		});
	}
	
	getgroups(){
		 $("#showLoader").show();
		Reqst.GetData('group/getchurchgroups/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({grouplist : res});
			}
		});
	}
	
	getallcount(){
		 $("#showLoader").show();
		 this.setState({count : 0});
		Reqst.GetData('sms/getallmemberscount/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({count : res.count});
			}
		});
	}
	
	getallcountbyareas(){
		 $("#showLoader").show();
		 $(".count_area").show();
		 this.setState({count : 0});
		Reqst.GetData('sms/getallmemberscountbyarea/'+this.state.company+'/'+this.state.ars).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({count : res.count});
			}
		});
	}
	
	getallcountbygroups(){
		 $("#showLoader").show();
		 $(".count_area").show();
		 this.setState({count : 0});
		Reqst.GetData('sms/getallmemberscountbygroup/'+this.state.company+'/'+this.state.resgroups).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({count : res.count});
			}
		});
	}
	
	getallcountbyeducation(){
		 $("#showLoader").show();
		 $(".count_area").show();
		 this.setState({count : 0});
		Reqst.GetData('sms/getallmemberscountbyeducation/'+this.state.company+'/'+this.state.resedu).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({count : res.count});
			}
		});
	}
	
	getallcountbyoccupation(){
		 $("#showLoader").show();
		 $(".count_area").show();
		 this.setState({count : 0});
		Reqst.GetData('sms/getallmemberscountbyoccupation/'+this.state.company+'/'+this.state.resocc).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({count : res.count});
			}
		});
	}
	
	getallcountbyagegender(){
		 $("#showLoader").show();
		 $(".count_area").show();
		 this.setState({count : 0});
		 
		 if(this.state.mem_age_from==''){
			 this.state.mem_age_from = 0;
		 }
		 if(this.state.mem_age_to==''){
			 
			 this.state.mem_age_to = 200;
		 }
		Reqst.GetData('sms/getallmemberscountbyagegender/'+this.state.company+'/'+this.state.mem_age_from+'/'+this.state.mem_age_to+'/'+this.state.mem_gen).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({count : res.count});
			}
		});
	}
	
	
	
	getallfamcount(){
		 $("#showLoader").show();
		 this.setState({count : 0});
		Reqst.GetData('sms/getallfamiliescount/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({count : res.count});
			}
		});
	}
	
	getallsupcount(){
		 $("#showLoader").show();
		 this.setState({count : 0});
		Reqst.GetData('sms/getallsupporterscount/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({count : res.count});
			}
		});
	}
	
	

	render(){
		
		this.options = [
		{value : '', name : '-Select-'},
		{value : '1', name : 'All Members'},
		{value : '2', name : 'All Families'},
		{value : '3', name : 'All Supporters'},
		{value : '4', name : 'Area'},
		{value : '5', name : 'Group'},
		{value : '6', name : 'Education'},
		{value : '7', name : 'Occupation'},
		{value : '8', name : 'Age & Gender'}
	   ];
	   
	   this.genders = [
		{value : 'both', name : '-Select-'},
		{value : 'Male', name : 'Male'},
		{value : 'Female', name : 'Female'}
	   ];
	   
	   this.education = [
		{value : '', label : '-Select-'},
		{value : 'Illiterate', label : 'Illiterate'},
		{value : 'Primary', label : 'Primary'},
		{value : 'Higher', label : 'Higher'},
		{value : 'Secondary', label : 'Secondary'},
		{value : 'Diploma', label : 'Diploma'},
		{value : 'UG', label : 'UG'},
		{value : 'PG', label : 'PG'}
	   ];
	   
	   this.occupation = [
		{value : '', label : '-Select-'},
		{value : 'Pastor', label : 'Pastor'},
		{value : 'Business', label : 'Business'},
		{value : 'Doctor', label : 'Doctor'},
		{value : 'Engineer', label : 'Engineer'},
		{value : 'Farmer', label : 'Farmer'},
		{value : 'Govt/Pvt Service', label : 'Govt/Pvt Service'},
		{value : 'Labour', label : 'Labour'},
		{value : 'Lawyer', label : 'Lawyer'},
		{value : 'Professor', label : 'Professor'},
		{value : 'Professional', label : 'Professional'},
		{value : 'Retired', label : 'Retired'},
		{value : 'Teacher', label : 'Teacher'},
		{value : 'Unemployed', label : 'Unemployed'}
	   ];
	   
	   
	   $('#form7').keyup(function(){

		var smscount = $("#smscount").val();

		var totalmsgs = 0;
		
		var totmembers = $("#smsmsgcount").val();
		totalmsgs = parseInt(totmembers);
		
		if(parseInt(totalmsgs)>=smscount){
			
			alert('Your Total Messages Exceeds your SMS Balance Credit Your Account Before Send SMS');
			$("#btn_div").hide();
		}
		else{
			
			$("#btn_div").show();
		}
    });
		
		var are = this.state.areas.map(function(e,key) {
		   return (
			 {value : e.id, label : e.name}
		   );
		});

		var grps = this.state.grouplist.map(function(e,key) {
		   return (
			 {value : e.id, label : e.name}
		   );
		});
			
		if(this.state.userrole!='admin' && this.state.userrole!='staff')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
	   if(this.state.headlist)
		{
			return(<Redirect to="/family/headlist" />);
		}
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Bulk SMS </title>
				</Helmet>
				
				<PageTitle title="Bulk SMS" >
					<h5 className="pull-right"><span id="sms_count_head" style={{fontWeight:'bold'}}></span></h5>
				</PageTitle>
				{this.state.smsapi!='' && this.state.smsapi!=null ?
					
				<form action="" method="post" encType="multipart/form-data" id="frmSMSBulk" onSubmit={this.handleFormSubmit} >
				<input type="hidden" name="userid" value={this.state.user} />
				<input type="hidden" name="companyid" value={this.state.company} />
				<input type="hidden" name="ch_smsapi" value={this.state.smsapi} />
				<input type="hidden" name="smscount" id="smscount"  />
				<input type="hidden" name="smsmsgcount" id="smsmsgcount" value={this.state.count} />
				<input type="hidden" name="ch_smssenderid" value={this.state.smssenderid} />
				<input type="hidden" name="smssendto" value={this.state.smssendto} />
				<input type="hidden" name="selareas" value={this.state.ars} />
				<input type="hidden" name="selgroups" value={this.state.resgroups} />
				<input type="hidden" name="seleduaction" value={this.state.resedu} />
				<input type="hidden" name="seloccupation" value={this.state.resocc} />
				<input type="hidden" name="agefrom" value={this.state.mem_age_from} />
				<input type="hidden" name="memgender" value={this.state.mem_gen} />
				<input type="hidden" name="ageto" value={this.state.mem_age_to} />
				<input type="hidden" name="isunicode" value={this.state.isUnicode} />
					<Row>
					
					 <Col md={12} className="form-group">
						
								 <select name="optradio" onChange={this.onOptChange} id="optradio" required className="form-control single-line-input" >
								 {this.options.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
						</Col>
							
						
					<Col md={12} className="area_div">
							<div className="form-group" >
								<label className="label-text" >Area</label>
								<Select value={this.state.selareas} id="areaids" onChange={this.handleAreaChange}  isMulti={true}  options={are}/>
							</div>
						</Col>
					<Col md={12} className="group_div">
							<div className="form-group" >
								<label className="label-text" >Group</label>
								<Select value={this.state.selgroups} id="groupids" onChange={this.handleGroupChange}  isMulti={true}  options={grps}/>
							</div>
					</Col>
					<Col md={12} className="edu_div">
							<div className="form-group" >
								<label className="label-text" >Eduaction</label>
								<Select value={this.state.seledu} id="groupids" onChange={this.handleEduChange}  isMulti={true}  options={this.education}/>
							</div>
					</Col>
					<Col md={12} className="occ_div">
							<div className="form-group" >
								<label className="label-text" >Occupation</label>
								<Select value={this.state.selocc} id="groupids" onChange={this.handleOccChange}  isMulti={true}  options={this.occupation}/>
							</div>
					</Col>
					<Col md={4} className="custom_div">
							<div className="form-group" >
								<label className="label-text" >Age From<span className="text-danger"></span></label>
								<input type="number" name="mem_age_from" id="mem_age_from" className="form-control single-line-input"   placeholder="" onChange={this.handleAgeFromChange}  />
							</div>
						
					</Col>
						<Col md={4} className="custom_div">
							<div className="form-group" >
								<label className="label-text" >Age To<span className="text-danger"></span></label>
								<input type="number" name="mem_age_to" id="mem_age_to" className="form-control single-line-input"   placeholder="" onChange={this.handleAgeToChange}  />
							</div>
						</Col>
						<Col md={4} className="custom_div">
							<div className="form-group" >
								<label className="label-text" >Gender<span className="text-danger"></span></label>
								<select name="mem_gen" onChange={this.handleGenChange} id="mem_gen" className="form-control single-line-input" >
								{this.genders.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
							</div>
						</Col>
		
						 <Col md={12} className="count_area" style={{textAlign:'center'}}>
					  <span className="text-success">{this.state.count} Members Selected</span>
					 </Col>
					<br />
					
					 <Col md={12}>
						<div className="form-group" >
								 <div className="md-form">
								  <textarea type="text" id="form7" className="md-textarea form-control" name="content_sms" rows="5"  required placeholder="Enter Message here....." onChange={this.handleInputChange} onBlur={this.handleInputBlur}></textarea>
								  {this.validator.message('Content', this.state.content_sms, 'required')}
								</div>
								<div className="check inline">
										<label className="form-check-label" check htmlFor="inline-checkbox1">Is Tamil ?</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										<input className="form-check-input" type="checkbox" id="isUnicode"  name="isUnicode" onChange={this.onChkChange}/>
									</div>
							</div>
					 </Col>
					
					</Row>	
					<Row>
					<Col md={12} Col xs={12} id="btn_div">
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Send" loadingText="Sending.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					 </Col>
						<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<span id="time"></span>
							
						</div>
						
					</Col>
					</Row>
				</form>
				: 
				<p><center>SMS Account Not Created Yet....</center></p>
				}
			</div>
		);
	}
	
}
export default GroupSMS;


