import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import LoadingBtn from '../../../../tie/LoadingBtn';

import SimpleLineIcon from 'react-simple-line-icons';
import { Row, Col, Input, Form, Card, InputGroup, Carousel, Modal, Fade, utils } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
import Logo from '../../../../assets/img/logo.png';
import TitleLogo from '../../../../assets/img/multicharge.png';

const styles = {
	rowStyle: {
		lineHeight: '27px',
		fontSize: '15px',
	}
}

class Recharge extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showModal: false,
			modalTitle: '',
		}
		
	}
	
	

	render(){

		
	
		return(
			<div>
				<Row style={styles.rowStyle} >
					
					<Col md={12} className="text-center" >
						<h4 style={{ fontWeight: '330' }} >Recharge</h4>
					</Col>
					
					<Col md={12} >
						&emsp; <b>DBS Wallet</b> offers the new and unique business opportunity for recharge solution at affordable cost. Now you can recharge Mobile using only one SIM & single handset through SMS at any time at any places. As per our business name DBSWallet, It's really quick and Easy.
						<br />
						<br />
					</Col>
					<Col md={12} className="text-center" >
						<img src={TitleLogo} />
					</Col>
					<Col md={12} >
						&emsp; Our expert professionals are highly enriched with experiences and they can easily make a better solution that your mobile recharging business will find more prosperity. API partnership or distributorship is not a matter of additional headache nowadays, but these are the efficient platforms that you will be able to achieve business goals in a faster way. For more information and API packages, just link with our professionals available for 24X7, and you will find varied business options with a mobile recharge multi distributorship.
						<br />
						<br />
						<div className="faq-title">Mobile Multi Recharge Distributorship - Who Can Join?</div>
						<ul className="faq-content" >
							<li>A Businessman</li>
							<li>A housewife</li>
							<li>Any retired personals</li>
							<li>A employed, unemployed and under employed</li>
							<li>Students Also</li>
						</ul>
					</Col>
				</Row>
			</div>
		);
	}
	
}
export default Recharge;

