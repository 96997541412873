import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';
import localIpUrl from 'local-ip-url';

import SimpleLineIcon from 'react-simple-line-icons';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class ErrorPage extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			userrole : Reqst.getTokenData('role','data'),
		}
		
	}
	
	componentDidMount(){
		
	}


	render(){
	
		return(
			<div className="container payContainer" >
				<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
					<h1 className="h2">Access Denied</h1>
						
				  </div>
				 <p><center>Access Denied...</center></p>
			</div>
		);
	}
	
}
export default ErrorPage;

