import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';
import LoadingBtn from '../../../tie/LoadingBtn';

import { Row, Col, Nav, Tab, Table } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import SelectSearch from 'react-select-search'
import 'react-select-search/style.css'

class MobilePrepaid extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			selectedProvider: '',
			selectedCircle: '',
			providers: [],
			circles: [],
			showLoaders: [],
		}
		this.validator = new SimpleReactValidator();
		this.handleProviderChange = this.handleProviderChange.bind(this);
		this.handleCircleChange = this.handleCircleChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		this.loadProviders();
		this.loadCircles();
	}

	/**
	* loadProviders
	* to load providers list from service
	* @since 1.0
	* @DT : 07/02/2019
	*/
	loadProviders(){
		let l = this.state.showLoaders;
		l.providers=true;
		this.setState({showLoaders:l});
		Reqst.axGet('prepaid/getproviders/').then(res=>{
			let d = res['data'].data.data;
			l.providers=false;
			this.setState({showLoaders: l, providers: d});
		});	
	}
	
	/**
	* loadCircles
	* to load circles list from service
	* @since 1.0
	* @DT : 07/02/2019
	*/
	loadCircles(){
		let l = this.state.showLoaders;
		l.circles = true;
		this.setState({showLoaders:l});
		Reqst.axGet('prepaid/getcircles/').then(res=>{
			let d = res['data'].data.data;
			l.circles=false;
			this.setState({showLoaders: l, circles: d});
		});
	}
	
	/**
	* to load provider image infront of provider name
	* using in selectbox
	* @since 1.0
	* @DT : 07/02/2019
	*/
	renderFriend(option) {
		const imgStyle = {
			borderRadius: '50%',
			verticalAlign: 'middle',
			marginRight: 10,
		};

		return (<span><img alt="" style={imgStyle} width="40" height="40" src={option.photo} /><span>{option.name}</span></span>);
	}

	handleProviderChange(e){
		//console.log(e);	
		this.setState({selectedProvider: e.value, i__operator: e.value});
	}
	
	handleCircleChange(e){
		//console.log(e);	
		this.setState({selectedCircle: e.value, i__circle: e.value});
	}
	
	handleInputChange(e){
		
		this.setState({[e.target.name]: e.target.value});
	}
	
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	/**
	* handleFormSubmit
	* to handle user recharge
	* @since 1.0
	* @DT : 07/02/2019
	*/
	handleFormSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			
		}
		else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
		}
	}
	
	render(){

		const providers = [];
			//{name: 'AIRTEL', value: '1', photo: 'https://www.pay2all.in/provider_icons/airtel.png'},
		const circles = [];
		
		if(this.state.providers.length>0){
			let _providers = JSON.parse(this.state.providers).providers;
			_providers.map((item,i)=>{
				if(item.provider_image.length>0 && item.provider_image!='https://www.pay2all.in/')
				providers.push({ value: item.provider_id, name: item.provider_name, code: item.provider_code, serviceid: item.service_id, photo: item.provider_image});
			})
		}
		
		if(this.state.circles.length>0){
			let _circles = JSON.parse(this.state.circles).circles;
			_circles.map((item,i)=>{
				circles.push({value: item.circle_id, name:item.circle_name});
			})
		}
		
		return(
			<div>
				<Helmet>
					<title>{GC.APPTITLE} | Mobile Prepaid Recharge</title>
				</Helmet>
				<Row>
					<Col md={4} >
						<form action="" method="post" role="form" encType="multipart/form-data" onSubmit={this.handleFormSubmit} >
							<Row>
								<Col md={12} >
									<div className="form-group">
										<input type="number" name="i__mobileNumber" className="form-control single-line-input input-small" required placeholder="Mobile Number *" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
										 {this.validator.message('Mobile', this.state.i__mobileNumber, 'required|numeric|min:10|max:10')}		
									</div>
								</Col>
							</Row>
							<Row>
								<Col md={12} >
									<div className="form-group">
										<div className={this.state.showLoaders.providers ? "inline-text-loader-show" : "inline-text-loader-hide"} >
											<i className="fa fa-spin fa-spinner"></i>
										</div>
										 <SelectSearch options={providers} value={this.state.selectedProvider} name="i__operator"  placeholder="Select Operator" renderOption={this.renderFriend} onChange={this.handleProviderChange} onBlur={this.handleInputBlur} />
										  {this.validator.message('Operator', this.state.i__operator, 'required')}
									</div>
								</Col>
							</Row>
							<Row>
								<Col md={12} >
									<div className={this.state.showLoaders.circles ? "inline-text-loader-show" : "inline-text-loader-hide"} >
											<i className="fa fa-spin fa-spinner"></i>
										</div>
									<div className="form-group">
										 <SelectSearch options={circles} value={this.state.selectedCircle} name="i__circle"  placeholder="Provider Circle"  onChange={this.handleCircleChange} onBlur={this.handleInputBlur} />
										 {this.validator.message('Circle', this.state.i__circle, 'required')}
									</div>
								</Col>
							</Row>
							<Row>
								<Col md={12} >
									<div className="form-group">
										<input type="number" name="i__amount" className="form-control single-line-input input-small" required placeholder="Recharge Amount *" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
										{this.validator.message('Amount', this.state.i__amount, 'required|currency')}
									</div>
								</Col>
							</Row>
							<Row>
								<Col md={12} className="text-right" >
									<LoadingBtn text="Recharge" className="btn btn-warning btn-gradiant-warning" loading={this.state.showLoader} loadingText="Verify..." >
										Verify
									</LoadingBtn>
								</Col>
							</Row>	
						</form>
					</Col>
					<Col md={8} >
						<img className="pay-right-ad" src="https://res.cloudinary.com/dzyixy4du/image/upload/v1549271388/dbs/ad/ad_550x300.png" />
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<h4 className="my-title" >Recent Transaction History</h4>
						<Table hover bordered>
							<thead>
								<tr>
									<th>S.No</th>
									<th>Operator</th>
									<th>Mobile Number</th>
									<th>Amount</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1.</td>
									<td>Airtel</td>
									<td>9952667533</td>
									<td>3500.00</td>
									<td>Success</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
			</div>
		);
	}
	
}
export default MobilePrepaid;

