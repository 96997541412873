import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import Logo from '../../../assets/img/dbs300.png';

import {Row, Col, Nav, Navbar, NavDropdown,Form, FormControl, Button } from 'react-bootstrap';
import SimpleLineIcon from 'react-simple-line-icons';
import * as FeatherIcon from 'react-feather';

const styles = {
	firstHeader: {
		padding:'4px 10px',
    	border: '1px solid grey',
	}
}

class Footer extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
	}
	
	componentDidMount(){
		
	}


	render(){
	
		return(
			<div style={{borderTop:'1px solid #c1bfba'}} >
				<Row style={{ paddingTop: '5px' }} >
					<Col md={12} >
						<div className="text-center" >
							<p style={{ fontSize: '13px' }} >
								<Link to="">Privacy Policy</Link> &emsp;|&emsp;
								<Link to="">Terms & Conditions</Link> &emsp;|&emsp;
								<Link to="">Help</Link> &emsp;|&emsp;
								<Link to="">FAQ</Link>
								<br />
								Copyright &copy; 2018 Digital Banking Services (DBS Wallet). All rights reserved.
								<br/>
								Powered by <a href="http://peacesoft.in" target="_blank">Peace Soft</a>
							</p>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
	
}
export default Footer;

