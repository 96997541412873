import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import LoadingBtn from '../../../tie/LoadingBtn';
import * as Reqst from '../../../tie/Reqst';

import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class AddCategory extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		this.validator = new SimpleReactValidator();
		this.handleFormSubmit= this.handleFormSubmit.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}

	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleFormSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}

	render(){
	
		return(
			<div>		
			
				<form action="" method="post" encType="multipart/form-data" id="frmCat" onSubmit={this.handleFormSubmit} >
					<Row>
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Category Name *</label>
								<input type="text" name="i__catName" id="i__catName" className="form-control single-line-input" required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Category Name', this.state.i__catName, 'required')}
							</div>
						</Col>
						<Col md={3} >
							<LoadingBtn text="Save" loadingText="Saving.." className="btn btn-sm btn-success btn-block" >
							
							</LoadingBtn>
						</Col>
						<Col md={3} >
							<button type="reset" className="btn btn-sm btn-danger" >
								<FeatherIcon.X size={18} /> Cancel 
							</button>
						</Col>
					</Row>
				</form>
				
			</div>
		);
	}
	
}
export default AddCategory;

