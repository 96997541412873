import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';
import Logo from '../../../assets/img/dbs300.png';
import swal from 'sweetalert';

import {Row, Col, Nav, Navbar, Dropdown,DropdownButton,  NavDropdown,Form, FormControl, Button } from 'react-bootstrap';
import SimpleLineIcon from 'react-simple-line-icons';
import * as FeatherIcon from 'react-feather';

import * as Menus from './Menus';

const styles = {
	firstHeader: {
		padding:'4px 10px',
    	border: '1px solid grey',
	}
}


class Header extends Component{
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
			showLoader: false,
		}
		
		this.handleLogOut = this.handleLogOut.bind(this);
		this.redirectAndLogout = this.redirectAndLogout.bind(this);
		this.handleMenuClick = this.handleMenuClick.bind(this);
	}
	
	componentDidMount(){
		
		$("body").css('background-color','#fff');	
		if ($(window).width() < 600) {
			$("#ps-sidebar").toggle();
		}
	}

	handleLogOut(){
		
		swal("Do you want to Logout?",{
			buttons:{
				cancel:'No',
				default:'Yes'
			}
		}).then((value)=>{
			switch(value){
				case "default":
					this.redirectAndLogout();
					break;
				default: console.log('---');
			}
		});
		/*Sanitize.setStorage('dbs_retailer_login','no');
		Sanitize.setStorage('dbs_retailer_data','noooooo');
		this.setState({isRedirect:true});*/
	}
	
	redirectAndLogout(){
		Sanitize.setStorage('dbs_retailer_login','no');
		Sanitize.setStorage('dbs_retailer_data','noooooo');
		this.setState({isRedirect:true});
	}
	
	handleMenuClick(e){
		e.preventDefault();
		$("#ps-sidebar").toggle();
		console.log('Clicked');
	}
	
	render(){
	
		if( Sanitize.getStoreageItem('iscatholic_login')!='yes')
		{
			return(<Redirect to="/" />);
		}
		
		let activeMenu = '/'+window.location.hash.split( '/' )[1];
		
		let profile = <FeatherIcon.User />;
		
		return(
			 <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
			  <a className="navbar-brand col-sm-3 col-md-2 mr-0" href="#/dashboard">
				<div className="ps-navbar-icon">
					<FeatherIcon.Home /> &nbsp;
					<span style={{fontSize:'14px'}}>{Sanitize.capitalizeFirstLetter(Reqst.getTokenData('name','data'))}</span>
				 </div>
				  <div className="ps-navbar-menu" onClick={this.handleMenuClick} >
					<FeatherIcon.Menu /> &nbsp;
					{Sanitize.capitalizeFirstLetter(Reqst.getTokenData('name','data'))}
				 </div>
			  </a>
			  {/*<input className="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search" />*/}
			  <ul className="navbar-nav my-right-nav px-2">
			  {/*<li className="nav-item text-nowrap px-2">
					<a href="#">Signout</a>
			  </li>*/}
				
				
			  </ul>
			</nav>
			
			
		);
	}
	
}
export default Header;

