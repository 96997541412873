import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import noImg from '../../../../assets/img/noimage_200.png';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PageTitle from '../../PageTitle';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
import ReactFileReader from 'react-file-reader';


class AddPage extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			files : '',
			photoname : '',
			isapp : 0,
			company : Reqst.getTokenData('companyid','cp'),
			pagelist : false,
			userrole : Reqst.getTokenData('role','data'),
			user : Reqst.getTokenData('id','data')
		}
		this.validator = new SimpleReactValidator();
		this.handleFormSubmit= this.handleFormSubmit.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.onChkChange = this.onChkChange.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	
	handleFiles(files){
		this.setState({ files: files.base64,photoname : files.fileList[0]['name']});
	}
	
	onChkChange(e){
		
		if(e.target.checked){
			
			this.setState({isapp: 1});
		}
		else {
			
			this.setState({isapp: 0});
		}
		
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	
	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleFormSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			$("#showLoader").show();
			var data = Sanitize.objectifyForm($("#frmPageadd").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('appcontent/addpage',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					$("#showLoader").hide();
					document.getElementById("frmPageadd").reset();
					swal("Success",res['data'].msg,"success");
				}
				else if(res['data'].status=='2')
				{
					$("#showLoader").hide();
					swal("Success",res['data'].msg,"success");
				}
				else
				{
					$("#showLoader").hide();
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	componentDidMount(){
		
		
		
	}
	
	

	render(){
		
		
		if(this.state.userrole!='admin' && this.state.userrole!='staff')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
	   if(this.state.pagelist)
		{
			return(<Redirect to="/appcontent/pages" />);
		}
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Add Page</title>
				</Helmet>
				
				<PageTitle title="Add Page" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({pagelist:true})}} >
							<i className="fa fa-arrow-left"></i> Back
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
				<form action="" method="post" encType="multipart/form-data" id="frmPageadd" onSubmit={this.handleFormSubmit} >
				<input type="hidden" name="userid" value={this.state.user} />
				<input type="hidden" name="churchid" value={this.state.company} />
				<input type="hidden" name="isapp" value={this.state.isapp} />
					<Row>
					
						
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Title<span className="text-danger">*</span></label>
								<input type="text" name="page_tit" id="page_tit" className="form-control single-line-input"  required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Title', this.state.page_tit, 'required')}
							</div>
						</Col>
						<Col md={12}>
						<div className="form-group" >
								 <div className="md-form">
								  <textarea type="text" id="form7" className="md-textarea form-control" name="page_content" rows="15"  required placeholder="Enter Your Content here.." onChange={this.handleInputChange} onBlur={this.handleInputBlur} ></textarea>
								  {this.validator.message('Content', this.state.page_content, 'required')}
								</div>
								<div className="check inline pull-right">
										<label className="form-check-label" check htmlFor="inline-checkbox1">Show on App </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										<input className="form-check-input" type="checkbox" id="isappcheck"  name="isappcheck" onChange={this.onChkChange}/>
									</div>
							</div>
					 </Col>
				
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Save" loadingText="Saving.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
			</div>
		);
	}
	
}
export default AddPage;


