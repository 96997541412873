import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Pagination from "react-js-pagination";
import PageTitle from '../../PageTitle';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class SupporterList extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			company : Reqst.getTokenData('companyid','cp'),
			user : Reqst.getTokenData('id','data'),
			headlist : [],
			suppedit : false,
			postFilter : [],
			userrole : Reqst.getTokenData('role','data'),
			currentPage: 1,
			idd : '',
			supp_mob : '',
			supp_name : '',
			todosPerPage: 20,
			issuppadd : false,
			activePage : 1
		}
		this.validator = new SimpleReactValidator();
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.onShowChange = this.onShowChange.bind(this);
		this.handleInputChangeByMob = this.handleInputChangeByMob.bind(this);
		this.handleInputChangebyName = this.handleInputChangebyName.bind(this);
	}
	
	
	
	handlePageChange(pageNumber) {
		
		this.setState({activePage: pageNumber});
		this.state.currentPage = Number(pageNumber);
	}
	
	handleInputChangeByMob(e){
		this.state.supp_mob = e.target.value;
		this.filterdata();
	}
	
	handleInputChangebyName(e){
		this.state.supp_name = e.target.value;
		this.filterdata();
	}
	
	onShowChange(e){
		this.state.todosPerPage = e.target.value;
		
			
			this.getdata();
		
	}
	
	
	handleUpdate(id){
		
		this.state.idd = id;
		this.setState({suppedit : true});
	
	}
	
	handleRemovefn(id){
		
		this.state.idd = id;
		this.removesupp();
		
	}
	
	handleRemove = (dd): void => {
		
		this.state.idd = dd;
		 confirmAlert({
		  title: 'Confirm to Delete',
		  message: 'Are you sure to Delete this Supporter ?',
		  buttons: [
			{
			  label: 'No'
			},
			{
			  label: 'Yes',
			  onClick: () => this.handleRemovefn(dd)
			}
		  ]
		})
  };
	

	
	componentDidMount(){
		
		this.getdata();
		
	}
	
	filterdata(){
		
		this.state.headlist = [];
		var dat = [];
		for(var i=0; i<this.state.postFilter.length; i++){
			
			
			if(this.state.supp_name!=''){
				if(this.state.postFilter[i].name.toLowerCase().search(this.state.supp_name.toLowerCase()) !=-1){
					
					dat.push(this.state.postFilter[i]);
				}
			}
			else if(this.state.supp_mob!=''){
				if(this.state.postFilter[i].mobile == this.state.supp_mob){
					
					dat.push(this.state.postFilter[i]);
				}
			}
			else{
				
				dat.push(this.state.postFilter[i]);
			}
			
			
		}
		this.setState({headlist : dat});
		
	}
	
	
	removesupp(){
		 $("#showLoader").show();
		Reqst.GetData('family/removesupporter/'+this.state.idd+'/'+this.state.user).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res.status=='1'){
					swal("Success",res.msg,"success");
					this.getdata();
			}
			else{
				
					swal("Error",res['data'].msg,"error");
			}
		});
	}
	
	getdata(){
		 $("#showLoader").show();
		Reqst.GetData('family/getchurchsupporters/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({headlist : res});
				this.setState({postFilter : res});
			}
		});
	}

	render(){
		
		const { country, region } = this.state;
		
		if(this.state.suppedit){
		  
		return (<Redirect to={'/supporter/updatesupporter/'+ this.state.idd} />);

	  }
	  
	  if(this.state.userrole!='admin' && this.state.userrole!='staff')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
		if(this.state.issuppadd)
		{
			return(<Redirect to="/supporter/add" />);
		}
		
		if(this.state.headlist.length>0){
			
			$(".page_btns").show();
		}
		else{
			$(".page_btns").hide();
		}
		
		  /** set showcount select box **/
	this.shows = [
		{value : '10', name : '10'},
		{value : '20', name : '20'},
		{value : '50', name : '50'},
		{value : '100', name : '100'}
	];
	
	// Logic for displaying current todos
	const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
	const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
	const currentTodos = this.state.headlist.slice(indexOfFirstTodo, indexOfLastTodo);

	const renderTodos = currentTodos.map((item,i) => {
		
	  return <tr key = {item}>
								<td width="20">{i+1}</td>
								<td width="100">{item.memberid}</td>
								<td style={{textTransform:'capitalize'}}width="100">{item.name}</td>
								<td width="300">{item.address} , {item.city} , {item.pincode}</td>
								<td width="100">{item.mobile}</td>
								<td width="100">{item.email}</td>
								<td width="120">
									<button name="edithw" className="btn btn-info btn-sm" onClick={() => this.handleUpdate(item.id)}  ><i className="fa fa-edit"></i></button>
									&nbsp;&nbsp;
									<a target="_blank" href={GC.API_URL+'family/viewsupportercard/'+item.id+'/'+item.churchid} className="btn btn-primary btn-sm"><i className="fa fa-id-card"></i></a>
									&nbsp;&nbsp;
									<button name="removeann" className="btn btn-danger btn-sm" onClick={() => this.handleRemove(item.id)}  ><i className="fa fa-remove"></i></button>
								</td>
							</tr>;
		
	});
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Supporter List </title>
				</Helmet>
				
				<PageTitle title="Supporter List" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({issuppadd:true})}} >
							<FeatherIcon.CheckSquare size={14} /> Add Supporter
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
				<Row>
				  <Col md="12">
				 <Row>
						<Col md={3} Col xs={6}>
							<div className="form-group" >
								<input type="text" name="supp_name" id="supp_name" className="form-control single-line-input"  placeholder="By Name" onChange={this.handleInputChangebyName} />
							</div>
						</Col>
						<Col md={3} Col xs={6}>
							<div className="form-group" >
								<input type="number" name="supp_mob" id="supp_mob" className="form-control single-line-input"  placeholder="By Mobile" onChange={this.handleInputChangeByMob} />
							</div>
						</Col>
				</Row>
				 <br />
					<Table responsive hover bordered>
					  <thead>
						<tr>
						  <th>S.No</th>
						  <th>Id</th>
						  <th>Name</th>
						  <th>Address</th>
						  <th>Mobile</th>
						  <th>Email</th>
						  <th>Manage</th>
						</tr>
					  </thead>
					  <tbody>
					  {this.state.headlist.length>0 ? 
						renderTodos
					  : 
					  <tr><td colSpan="7"><center>No Data Found...</center></td></tr>
					  }
					</tbody>
					</Table>
				  </Col>
				  <Col md="4" className="page_btns">
				  <Pagination
							 activePage={this.state.activePage}
							 itemsCountPerPage={this.state.todosPerPage}
							 pageRangeDisplayed={3}
							 totalItemsCount={this.state.headlist.length}
							 onChange={this.handlePageChange}
							/>
				  </Col>
				  <Col md="2" className="page_btns">
				   <select name="show" onChange={this.onShowChange} id="show" className="form-control" >
								{this.shows.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>&nbsp;
						entries
				  </Col>
				</Row>
			</div>
		);
	}
	
}
export default SupporterList;


