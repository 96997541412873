import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Pagination from "react-js-pagination";
import PageTitle from '../../PageTitle';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class Churches extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			user : Reqst.getTokenData('id','data'),
			churchlist : [],
			churchedit : false,
			userrole : Reqst.getTokenData('role','data'),
			currentPage: 1,
			idd : '',
			todosPerPage: 20,
			churchadd : false,
			activePage : 1
		}
		this.validator = new SimpleReactValidator();
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.onShowChange = this.onShowChange.bind(this);
	}
	
	
	
	handlePageChange(pageNumber) {
		
		this.setState({activePage: pageNumber});
		this.state.currentPage = Number(pageNumber);
	}
	
	onShowChange(e){
		this.state.todosPerPage = e.target.value;
		
			
			this.getdata();
		
	}
	
	
	handleUpdate(id){
		
		this.state.idd = id;
		this.setState({churchedit : true});
	
	}
	
	handleRemovefn(id){
		
		this.state.idd = id;
		this.removechurch();
		
	}
	
	handleRemove = (dd): void => {
		
		this.state.idd = dd;
		 confirmAlert({
		  title: 'Confirm to Delete',
		  message: 'Are you sure to Delete this Church ?',
		  buttons: [
			{
			  label: 'No'
			},
			{
			  label: 'Yes',
			  onClick: () => this.handleRemovefn(dd)
			}
		  ]
		})
  };
	

	
	componentDidMount(){
		
		this.getdata();
		
	}
	
	
	removechurch(){
		 $("#showLoader").show();
		Reqst.GetData('setup/removechurch/'+this.state.idd+'/'+this.state.user).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res.status=='1'){
					swal("Success",res.msg,"success");
					this.getdata();
			}
			else{
				
					swal("Error",res['data'].msg,"error");
			}
		});
	}
	
	getdata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getchurches/').then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({churchlist : res});
			}
		});
	}

	render(){
		
		const { country, region } = this.state;
		
		if(this.state.churchedit){
		  
		return (<Redirect to={'/manage/editchurch/'+ this.state.idd} />);

	  }
	  
	  if(this.state.userrole!='superadmin')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
		if(this.state.churchadd)
		{
			return(<Redirect to="/manage/addchurch" />);
		}
		
		if(this.state.churchlist.length>0){
			
			$(".page_btns").show();
		}
		else{
			$(".page_btns").hide();
		}
		
		  /** set showcount select box **/
	this.shows = [
		{value : '10', name : '10'},
		{value : '20', name : '20'},
		{value : '50', name : '50'},
		{value : '100', name : '100'}
	];
	
	// Logic for displaying current todos
	const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
	const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
	const currentTodos = this.state.churchlist.slice(indexOfFirstTodo, indexOfLastTodo);

	const renderTodos = currentTodos.map((item,i) => {
		
	  return <tr key = {item}>
								<td width="20">{i+1}</td>
								<td width="100">{item.name}</td>
								<td width="100">{item.companyid}</td>
								<td width="100">{item.usercount}</td>
								<td width="100">{item.place}</td>
								<td width="100">{item.mobile}</td>
								<td width="100">{item.email}</td>
								<td width="30">
									<button name="edithw" className="btn btn-info btn-sm" onClick={() => this.handleUpdate(item.companyid)}  ><i className="fa fa-edit"></i></button>
									&nbsp;&nbsp;
									<button name="removeann" className="btn btn-danger btn-sm" onClick={() => this.handleRemove(item.id)}  ><i className="fa fa-remove"></i></button>
								</td>
							</tr>;
		
	});
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Churches </title>
				</Helmet>
				
				<PageTitle title="Churches" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({churchadd:true})}} >
							<FeatherIcon.CheckSquare size={14} /> Add Church
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
				<Row>
				  <Col md="12">
				 
					<Table responsive hover bordered>
					  <thead>
						<tr>
						  <th>S.No</th>
						  <th>Name</th>
						  <th>ChurchId</th>
						  <th>Users</th>
						  <th>Place</th>
						  <th>Mobile</th>
						  <th>Email</th>
						  <th>Manage</th>
						</tr>
					  </thead>
					  <tbody>
					  {this.state.churchlist.length>0 ? 
						renderTodos
					  : 
					  <tr><td colSpan="8"><center>No Data Found...</center></td></tr>
					  }
					</tbody>
					</Table>
				  </Col>
				  <Col md="4" className="page_btns">
				  <Pagination
							 activePage={this.state.activePage}
							 itemsCountPerPage={this.state.todosPerPage}
							 pageRangeDisplayed={3}
							 totalItemsCount={this.state.churchlist.length}
							 onChange={this.handlePageChange}
							/>
				  </Col>
				  <Col md="2" className="page_btns">
				   <select name="show" onChange={this.onShowChange} id="show" className="form-control" >
								{this.shows.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>&nbsp;
						entries
				  </Col>
				</Row>
			</div>
		);
	}
	
}
export default Churches;


