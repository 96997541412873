import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import Pagination from "react-js-pagination";
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PageTitle from '../../PageTitle';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class ViewGroupMembers extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			groupid : '',
			company : Reqst.getTokenData('companyid','cp'),
			memberlist : [],
			currentPage: 1,
			idd : '',
			grouplist : false,
			todosPerPage: 20,
			activePage : 1,
			userrole : Reqst.getTokenData('role','data'),
			user : Reqst.getTokenData('id','data')
		}
		this.validator = new SimpleReactValidator();
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.onShowChange = this.onShowChange.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	
	handlePageChange(pageNumber) {
		
		this.setState({activePage: pageNumber});
		this.state.currentPage = Number(pageNumber);
	}
	
	onShowChange(e){
		this.state.todosPerPage = e.target.value;
		
			
			this.groupmemberdata();
		
	}
	
	
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}

	
	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	handleRemovefn(id){
		
		this.state.idd = id;
		this.removegroupmember();
		
	}
	
	handleRemove = (dd): void => {
		
		this.state.idd = dd;
		 confirmAlert({
		  title: 'Confirm to Delete',
		  message: 'Are you sure to Delete this Group Member ?',
		  buttons: [
			{
			  label: 'No'
			},
			{
			  label: 'Yes',
			  onClick: () => this.handleRemovefn(dd)
			}
		  ]
		})
  };

	
	
	componentDidMount(){
		
	
         this.getdata();		
	}
	
	removegroupmember(){
		 $("#showLoader").show();
		Reqst.GetData('group/removegroupmember/'+this.state.idd+'/'+this.state.user).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res.status=='1'){
					swal("Success",res.msg,"success");
					this.groupmemberdata();
			}
			else{
				
					swal("Error",res['data'].msg,"error");
			}
		});
	}
	
	getdata(){
		 $("#showLoader").show();
		Reqst.GetData('group/getchurchgroupsbyid/'+this.props.match.params.id).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({
					 gr_name : res[0]['name'],
					 groupid : res[0]['id'],
					 gr_desc : res[0]['description']
				});
				this.groupmemberdata();
			}
		});
	}
	
	groupmemberdata(){
		 $("#showLoader").show();
		Reqst.GetData('group/getgroupmembers/'+this.state.groupid).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({memberlist : res});
			}
		});
	}
	
	

	render(){
		
		

		if(this.state.userrole!='admin' && this.state.userrole!='staff')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
	   if(this.state.grouplist)
		{
			return(<Redirect to="/group/list" />);
		}
		
			if(this.state.memberlist.length>0){
			
			$(".page_btns").show();
		}
		else{
			$(".page_btns").hide();
		}
		
		  /** set showcount select box **/
	this.shows = [
		{value : '10', name : '10'},
		{value : '20', name : '20'},
		{value : '50', name : '50'},
		{value : '100', name : '100'}
	];
	
	// Logic for displaying current todos
	const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
	const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
	const currentTodos = this.state.memberlist.slice(indexOfFirstTodo, indexOfLastTodo);

	const renderTodos = currentTodos.map((item,i) => {
		
	  return <tr key = {item}>
								<td width="20">{i+1}</td>
								<td width="100">{item.memberid}</td>
								<td width="100">{item.fid}</td>
								<td style={{textTransform:'capitalize'}}width="100">{item.memname}</td>
								<td width="100">{item.memmob}</td>
								<td width="100">{item.memmail}</td>
								<td width="20">
									<button name="removeann" className="btn btn-danger btn-sm" onClick={() => this.handleRemove(item.id)}  ><i className="fa fa-remove"></i></button>
								</td>
							</tr>;
		
	});
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Group Members</title>
				</Helmet>
				
				<PageTitle title="Group Members" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({grouplist:true})}} >
							<i className="fa fa-arrow-left"></i> Back
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
					<Col md={12} >
					
					 <table id="" align="center">
						<tbody>
						       <tr>
								<td colSpan="3" style={{marginLeft: 'auto',marginRight : 'auto',textAlign : 'center',fontSize: '20px',fontWeight: 'bold',color : '#8a260e'}}>Group Name - {this.state.gr_name}</td>
								</tr>
								<tr>
									<td  style={{marginLeft: 'auto',marginRight : 'auto',textAlign : 'center',fontSize: '14px'}} >Description - {this.state.gr_desc}</td>
								</tr>
								<tr>
									<td  style={{marginLeft: 'auto',marginRight : 'auto',textAlign : 'center',fontSize: '14px',fontWeight: 'bold',color : '#1d4f71'}}>Total Members - {this.state.memberlist.length}</td>
								</tr>
						</tbody>
					  </table>
	
						
					</Col>
					
				<Row>
				  <Col md="12">
				 
					<Table responsive hover bordered>
					  <thead>
						<tr>
						  <th>S.No</th>
						  <th>Memberid</th>
						  <th>FamilyId</th>
						  <th>Name</th>
						  <th>Mobile</th>
						  <th>Email</th>
						  <th>Manage</th>
						</tr>
					  </thead>
					  <tbody>
					  {this.state.memberlist.length>0 ? 
						renderTodos
					  : 
					  <tr><td colSpan="7"><center>No Data Found...</center></td></tr>
					  }
					</tbody>
					</Table>
				  </Col>
				  <Col md="4" className="page_btns">
				  <Pagination
							 activePage={this.state.activePage}
							 itemsCountPerPage={this.state.todosPerPage}
							 pageRangeDisplayed={3}
							 totalItemsCount={this.state.memberlist.length}
							 onChange={this.handlePageChange}
							/>
				  </Col>
				  <Col md="2" className="page_btns">
				   <select name="show" onChange={this.onShowChange} id="show" class="form-control" >
								{this.shows.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>&nbsp;
						entries
				  </Col>
				  </Row>
			</div>
		);
	}
	
}
export default ViewGroupMembers;


