import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';
import localIpUrl from 'local-ip-url';

import SimpleLineIcon from 'react-simple-line-icons';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class Dashboard extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
	}
	
	componentDidMount(){
		
	}


	render(){
	
		return(
			<div className="container payContainer" >
				<Row>
					
					<Col md={9} >
						
						<Row>
							<Col md={4} >
								<div className="text-center bg-gradiant-primary" style={{ padding: '20px' }} >
									<h5>Success Recharge</h5>
									<div className="dash-count">0</div>
								</div>
							</Col>
							<Col md={4} >
								<div className="text-center bg-gradiant-danger" style={{ padding: '20px' }} >
									<h5>Failed Recharge</h5>
									<div className="dash-count">0</div>
								</div>
							</Col>
							<Col md={4} >
								<div className="text-center bg-gradiant-success" style={{ padding: '20px' }} >
									<h5>Total Earnings</h5>
									<div className="dash-count">0</div>
								</div>
							</Col>
						</Row>
						
						<Row style={{ marginTop: '30px' }} >
							<Col md={12}>
								<h4 className="my-title" >Last Transaction</h4>
								<Table hover bordered>
									<thead>
										<tr>
											<th>S.No</th>
											<th>Operator</th>
											<th>Mobile Number</th>
											<th>Amount</th>
											<th>Status</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1.</td>
											<td>Airtel</td>
											<td>9952667533</td>
											<td>3500.00</td>
											<td>Success</td>
										</tr>
									</tbody>
								</Table>
							</Col>
						</Row>
						
						<Row>
							<Col md={12} >
								<ListGroup>
									<ListGroup.Item>IP : <b><i>{localIpUrl('public')}</i></b></ListGroup.Item>
									<ListGroup.Item>Last Login : {Reqst.getTokenData('lastlogin')}</ListGroup.Item>
									<ListGroup.Item>Last Ip : {Reqst.getTokenData('lastip')}</ListGroup.Item>
								</ListGroup>
							</Col>
						</Row>
				
					</Col>
					<Col md={3} >
						<img src="https://res.cloudinary.com/dzyixy4du/image/upload/v1549359337/dbs/ad/ad_270x450.png" className="dashboard-right-ad" />
					</Col>
				</Row>
				
			</div>
		);
	}
	
}
export default Dashboard;

