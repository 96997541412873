import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';
import LoadingBtn from '../../../tie/LoadingBtn';

import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { Row, Col, Input, Form, Card, InputGroup, Carousel, Modal, Fade, utils } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
import BannerImage from '../../../assets/img/api-integration.png';
import Logo from '../../../assets/img/logo.png';
import SliderBackground from '../../../assets/img/slider_350x150.png';



const styles={
	firstRowStyle:{
		backgroundImage:'linear-gradient(#f9aa1f , #c7418c)',
		height:'calc(100vh)',
	},
	textDiv:{
		color:'#fff',
		marginTop: '20%',
	},
	secondRow: {
		marginTop: '50px',
	},
	dividerStyle: {
		width: '90%',
		marginLeft: '25px',
	},
	btnSignup: {
		marginTop: '80px',
		fontSize: '20px;',
		 width:'300px',
	},
	resendOtp: {
		float: 'right',
		fontSize: '13px',
	},
	backtoLogin: {
		float: 'right',
		marginTop: '-20px'
	}
}

class ForgotPassword extends Component{
	constructor(props){
		super(props);
		this.state = {
			redirect: false,
			showLoader: false,
			showModal: false,
			modalTitle: '',
			userid: '',
			aboutComp: 'recharge',
			hasOtpVerify: false,
		}
		this.validator = new SimpleReactValidator();
		this.handleLoginChange = this.handleLoginChange.bind(this);
		this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
		this.handleLoginBlur = this.handleLoginBlur.bind(this);
		this.handleOtpFormSubmit = this.handleOtpFormSubmit.bind(this);
	}
	
	componentDidMount(){

		$("body").css('background-color','#fff');
	}
	
	
	handleLoginChange(e){
		this.setState({[e.target.name]: e.target.value});
	}

	handleLoginBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	handleLoginSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			/*** Handle Login ****/
			this.setState({showLoader:true});
			var data = Sanitize.objectifyForm($("#frmLogin").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('login/forgotpassword',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					swal("Success",res['data'].msg,"success");
					this.setState({showLoader: false,hasOtpVerify: true,userid:res['data'].userid});
				}	
				else
				{
					this.setState({showLoader: false,hasOtpVerify: false});	
					swal("Error",res['data'].msg,"error");
				}
			});
			
		} else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
		}
	}
	
	/**
	* handleOtpFormSubmit
	* to validate user otp 
	* @since 1.0
	* @DT : 05/02/2019
	*/
	handleOtpFormSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			this.setState({showLoader:true});
			var data = Sanitize.objectifyForm($("#frmOtp").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('login/checkotp/',{token:data}).then(res=>{
				if(res['data'].status=='1')
				{	
					Sanitize.setStorage('catho_data',res['data'].data);
					Sanitize.setStorage('iscatholic_login','yes');
					this.setState({redirect: true});	
					//this.dialog.showAlert(res['data'].msg);
					//swal(res['data'].msg);
				}
				else
				{
					this.setState({showLoader: false});	
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	render(){

		if(this.state.redirect)
		{
			return(<Redirect to="/dashboard" />);
		}
	
		return(
			<div className="container " style={{ maxWidth:'100%' }} >
				<Helmet>
					<title>{GC.APPTITLE} | Forgot Password</title>
				</Helmet>
				
				<Row>
					
					<Col md={12} >
						<Row style={styles.secondRow} >
							<Col md={12} >
								<Link to="/" className="btn btn-info btn-sm" style={styles.backtoLogin} >
									<FeatherIcon.ArrowLeft /> Back to Login
								</Link>
								<h4 className="text-center">FORGOT PASSWORD</h4>
							</Col>
							<Col md={{ span: 6, offset: 3 }} >
								<Row>
								<Col md={12} >
									{this.state.hasOtpVerify ? 
									<div style={{ minHeight: '198px' }} >
										<form action="" method="post" id="frmOtp" encType="mutlipart/form-data" onSubmit={this.handleOtpFormSubmit} >
										<input type="hidden" name="i__UserId" value={this.state.userid} />
											<Col md={12} >
												
											</Col>
											<Col md={12} >
												<div className="form-group" style={{ marginBottom: '0px' }} >
													<label className="label-text" >Otp *</label>
													<input type="text" name="i__otpNo" id="i__otpNo" className="form-control single-line-input" required placeholder="xxxx" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} onKeyPress={Sanitize.integerKeyPress} />
														{/*<a href="javascript:;" style={styles.resendOtp} >Resend Otp</a>*/}
													 {this.validator.message('Otp', this.state.i__otpNo, 'required')}		
													<div style={{ fontSize: '12px', color:'grey' }} >We are sending an one time password to your registered mobile number.</div>
													</div>
											</Col>
											<Col md={12} style={{ marginTop: '30px' }} >
												<LoadingBtn text="Verify" className="btn btn-warning btn-block" loading={this.state.showLoader} loadingText="Verify..." >
													Verify
												</LoadingBtn>
											</Col>
											
										</form>
									</div>
									:
									<form action="" id="frmLogin" method="post" role="form" encType="multipart/form-data" onSubmit={this.handleLoginSubmit} >
										<Row>
											<Col md={12} >
												<div className="form-group" style={{ marginBottom: '0px' }} >
													<label className="label-text" >Mobile Number *</label>
													<input type="text" name="i__forgotMobile" id="i__forgotMobile" className="form-control single-line-input" required placeholder="948xxxxx14" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} />
													 {this.validator.message('Mobile', this.state.i__forgotMobile, 'required|numeric|min:10|max:10')}
												</div>
											</Col>
											<Col md={12} style={{ marginTop: '20px' }} >
												<LoadingBtn text="Verify" className="btn btn-warning btn-block" loading={this.state.showLoader} loadingText="Checking..." >
													Verify
												</LoadingBtn>
											</Col>
										</Row>
									</form>
									}
								</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
				
				

			</div>
		);
	}
	
}
export default ForgotPassword;

