import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';
import Logo from '../../../assets/img/dbs300.png';
import swal from 'sweetalert';

import {Row, Col, Nav, Navbar, Dropdown,  NavDropdown,Form, FormControl, Button } from 'react-bootstrap';
import SimpleLineIcon from 'react-simple-line-icons';
import * as FeatherIcon from 'react-feather';

import * as Menus from './Menus';

const styles = {
	firstHeader: {
		padding:'4px 10px',
    	border: '1px solid grey',
	}
}

class Header extends Component{
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
			showLoader: false,
		}
		
		this.handleLogOut = this.handleLogOut.bind(this);
		this.redirectAndLogout = this.redirectAndLogout.bind(this);
	}
	
	componentDidMount(){
		
		$("body").css('background-color','#fff');	
	}

	handleLogOut(){
		
		swal("Do you want to Logout?",{
			buttons:{
				cancel:'No',
				default:'Yes'
			}
		}).then((value)=>{
			switch(value){
				case "default":
					this.redirectAndLogout();
					break;
				default: console.log('---');
			}
		});
		/*Sanitize.setStorage('dbs_retailer_login','no');
		Sanitize.setStorage('dbs_retailer_data','noooooo');
		this.setState({isRedirect:true});*/
	}
	
	redirectAndLogout(){
		Sanitize.setStorage('dbs_retailer_login','no');
		Sanitize.setStorage('dbs_retailer_data','noooooo');
		this.setState({isRedirect:true});
	}
	
	render(){
	
		if( Sanitize.getStoreageItem('dbs_retailer_login')!='yes')
		{
			return(<Redirect to="/login" />);
		}
		
		let activeMenu = '/'+window.location.hash.split( '/' )[1];
		
		return(
			<div>
					
				<Navbar bg="light" expand="lg" className="header1" fixed="top" >
					<Navbar.Brand href="#dashboard" style={{color:'#fff'}} >
						<img src={Logo}  style={{width: '40px' }} />
						DBS Wallet
					</Navbar.Brand>
						<div className="pull-right" style={{ width: '100%', textAlign: 'right'}}>
							<ul className="topLinks" >
								<li>
									<a><FeatherIcon.PhoneCall size={18} color="white" /> +91 {Reqst.getTokenData('supportmobile','wl')}</a>
								</li>
								<li>
									<a><FeatherIcon.Mail size={18} color="white" /> {Reqst.getTokenData('supportmail','wl')}</a>
								</li>
								<li>
									<a><FeatherIcon.HelpCircle size={18} color="white" /> Help</a>
								</li>
								<li>
									<a><SimpleLineIcon name="present" /> Offer</a>
								</li>
							</ul>
						</div>
					</Navbar>

					<Navbar bg="light" expand="lg" className="header2" fixed="top" >
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="mr-auto">
						{/*<Nav.Link href="#dashboard">Dashboard</Nav.Link>
							<Nav.Link href="#pay">Pay</Nav.Link>
							<Nav.Link href="#transaction-history">Transaction History</Nav.Link>
						<Nav.Link href="#link">Complaint Management</Nav.Link>*/}
						{
							Menus.Menu.map((item,i) => {
								let href = '#'+item.path;
								return <Nav.Link href={href} className={item.path==activeMenu ? 'active' : ''} >{item.value}</Nav.Link>;
							})
						}
						</Nav>
					</Navbar.Collapse>
					<div className="pull-right" style={{ width: '550px', textAlign: 'right'}}>
						<Nav className="mr-auto" style={{ float:'right' }} >
							<Nav.Link>
								({Reqst.getTokenData('outlet_name')})
							</Nav.Link>
							
							{(Reqst.getTokenData('pattern')=='1') ?
								<Nav.Link>
									 <span><FeatherIcon.CreditCard size={20} color="#1d1b1b" /> Rs. {Reqst.getTokenData('ewallet_balance')}</span>
								</Nav.Link> : ''
							}
							
							{(Reqst.getTokenData('pattern')=='2') ?
								<Nav.Link>
									 <span><FeatherIcon.CreditCard size={20} color="#1d1b1b" /> Rs. 350.00</span> 
								</Nav.Link> : ''
							}
							
							{(Reqst.getTokenData('pattern')=='2') ?
								<Nav.Link>
									  <span><i className="fa fa-rupee"></i> Rs. 350.00</span> 
								</Nav.Link> : ''
							}
							
							
							<NavDropdown title={<FeatherIcon.User size={20} color="#1d1b1b" />} id="basic-nav-dropdown" alignRight >
								<NavDropdown.Item href="#action/3.2">Profile</NavDropdown.Item>
								<NavDropdown.Item href="#action/3.3">Settings</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item href="#action/3.4">Logout</NavDropdown.Item>
							</NavDropdown>
							<Nav.Link onClick={this.handleLogOut} >
								<FeatherIcon.Power size={20} color="#1d1b1b" />
							</Nav.Link>
						</Nav>
						</div>
					</Navbar>
				
			</div>
		);
	}
	
}
export default Header;

