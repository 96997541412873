
/** menu for dashboard page **/
export const data = [
	{
		title:'S.Boopal',
		content:'Internet Banking has become a part of our daily life. It is the most convenient way and because of it Banking now can be done as per our ease. Most of us opt for it only to get the bank account details while few take a step. '
	},
	{
		title: 'Afsal Appu',
		content: 'I am using Dbswallet.in for 3 year\'s and this is a really cool application, i had used it for recharging my mobile phones and Airtel Digital T.V.'
	},
	{
		title: 'Santroad India',
		content: 'Dbswallet is online recharge site and also application available in play store.Today I am going to share my experience with dbswallet. It offers and its services throgh website and android platform.'
	}
];