import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Pagination from "react-js-pagination";
import PageTitle from '../../PageTitle';
import noImg from '../../../../assets/img/noimage_200.png';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class PageList extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			company : Reqst.getTokenData('companyid','cp'),
			user : Reqst.getTokenData('id','data'),
			pagelist : [],
			pageedit : false,
			userrole : Reqst.getTokenData('role','data'),
			currentPage: 1,
			idd : '',
			todosPerPage: 20,
			pageadd : false,
			activePage : 1
		}
		this.validator = new SimpleReactValidator();
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.onShowChange = this.onShowChange.bind(this);
	}
	
	
	
	handlePageChange(pageNumber) {
		
		this.setState({activePage: pageNumber});
		this.state.currentPage = Number(pageNumber);
	}
	
	onShowChange(e){
		this.state.todosPerPage = e.target.value;
		
			
			this.getdata();
		
	}
	
	
	handleUpdate(id){
		
		this.state.idd = id;
		this.setState({pageedit : true});
	
	}
	
	handleRemove(id){
		
		this.state.idd = id;
		this.removeevent();
		
	}
	
	gettimeform(time){
		
		//var hours = time.getHours();
		var hours = (time+24-2)%24; 
		var mid='am';
		if(hours==0){ //At 00 hours we need to show 12 am
		hours=12;
		}
		else if(hours>12)
		{
		hours=hours%12;
		mid='pm';
		}
		
		return time + mid;
		
		
	}
	
	getdateofdt(date){
		      
			  if(date!='' && date!=null){		
			  var date1 = new Date(date);
		      var year = date1.getFullYear();
			  var month = (date1.getMonth()+1);
			  var day   = date1.getDate()
			  var strTime = day + '-' + month + '-' + year;
			  return strTime;
		 }
		 else{
			 
			 return 'N/A';
		 }
			
	  }
	

	
	componentDidMount(){
		
		this.getdata();
		
	}
	
	
	removeevent(){
		 $("#showLoader").show();
		Reqst.GetData('appcontent/removeevent/'+this.state.idd+'/'+this.state.user).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res.status=='1'){
					swal("Success",res.msg,"success");
					this.getdata();
			}
			else{
				
					swal("Error",res['data'].msg,"error");
			}
		});
	}
	
	getdata(){
		 $("#showLoader").show();
		Reqst.GetData('appcontent/getpages/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			 console.log(result);
			let res = result;
			if(res){
				this.setState({pagelist : res});
			}
		});
	}

	render(){
		
	
		if(this.state.pageedit){
		  
		return (<Redirect to={'/appcontent/updatepage/'+ this.state.idd} />);

	  }
	  
	  if(this.state.userrole!='admin')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
		if(this.state.pageadd)
		{
			return(<Redirect to="/appcontent/addpage" />);
		}
		
		if(this.state.pagelist.length>0){
			
			$(".page_btns").show();
		}
		else{
			$(".page_btns").hide();
		}
		
		  /** set showcount select box **/
	this.shows = [
		{value : '10', name : '10'},
		{value : '20', name : '20'},
		{value : '50', name : '50'},
		{value : '100', name : '100'}
	];
	
	// Logic for displaying current todos
	const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
	const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
	const currentTodos = this.state.pagelist.slice(indexOfFirstTodo, indexOfLastTodo);

	const renderTodos = currentTodos.map((item,i) => {
		
	  return ( <div className="list-group-item" style={{borderLeft: '2px solid #4CAF50',marginBottom: '8px'}}>
			<Row>
				<Col md="12" sm="12">
			    <h4 className="list-group-item-heading" style={{wordBreak: 'break-all'}} >{item.title}</h4>
				
			       <p className="list-group-item-text">Show on App : &nbsp; 
				             {item.isapp=='1'?
								<span className="label label-success"> Yes</span>
								: 
								<span className="label label-danger"> No</span>
							}
					</p>
			 </Col>
			  <Col md="12" sm="12">
			  
				<button name="edithw" className="btn btn-info btn-md pull-right" onClick={() => this.handleUpdate(item.id)}  ><i className="fa fa-edit"></i></button>
				
			  </Col>
			</Row>
	  </div> );
		
	});
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Pages </title>
				</Helmet>
				
				<PageTitle title="Pages" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({pageadd:true})}} >
							<FeatherIcon.CheckSquare size={14} /> Add Page
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
				<Row>
				  <Col md="12">
				 
					
					  {this.state.pagelist.length>0 ? 
						renderTodos
					  : 
					    <p><center>No Data Found...</center></p>
					  }
					
				  </Col>
				  <br />
				  <Col md="4" className="page_btns">
				  <Pagination
							 activePage={this.state.activePage}
							 itemsCountPerPage={this.state.todosPerPage}
							 pageRangeDisplayed={3}
							 totalItemsCount={this.state.pagelist.length}
							 onChange={this.handlePageChange}
							/>
				  </Col>
				  <Col md="2" className="page_btns">
				   <select name="show" onChange={this.onShowChange} id="show" className="form-control" >
								{this.shows.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>&nbsp;
						entries
				  </Col>
				</Row>
			</div>
		);
	}
	
}
export default PageList;


