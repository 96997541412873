import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../tie/GlobalConstants';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst';


class Home extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
	}
	
	componentDidMount(){
		
	}


	render(){
	
		return(
			<div className="container" >
					This is home
			</div>
		);
	}
	
}
export default Home;

