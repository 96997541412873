import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';

import SimpleLineIcon from 'react-simple-line-icons';
import { Row, Col, Input, Form, Card, InputGroup, Carousel, Modal, Fade, utils } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';

import About_Recharge from './about/Recharge';
import About_DTH from './about/DTH';

const styles={
	iconBottom: {
		fontSize: '40px',
		fontWeight: '500',
		color: '#524e4e',
	},
	modalCloseIcon: {
		textAlign:'right',
		marginBottom: '10px',
		cursor: 'pointer',
	},
}

class Services extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showModal: false,
			modalTitle: '',
			aboutComp: 'recharge',
		}
		
		this.handleServiceClick = this.handleServiceClick.bind(this);
		
	}
	
	componentDidMount(){

		
	}
	
	
	handleServiceClick(title,comp,e){

		this.setState({modalTitle: title,aboutComp: comp, showModal: true});		
	}
	

	render(){

		if(this.state.redirect)
		{
			return(<Redirect to="/dashboard" />);
		}
	
		return(
			<div >
				<Row style={{ marginTop: '40px', backgroundColor:'#e4e4e4', padding: '15px' }} >
					<Col md={3} className="gradiant-order-box" onClick={this.handleServiceClick.bind(this,'Recharge','recharge')} >
						<div className="">
							Recharge
							<SimpleLineIcon name="screen-smartphone" style={styles.iconBottom} />
						</div>
					</Col>
					<Col md={3} className="gradiant-order-box" onClick={this.handleServiceClick.bind(this,'Bill Payment','recharge')} >
						<div className="">
							Bill Payment
							{/*<SimpleLineIcon name="ghost" style={styles.iconBottom} />*/}
							<FeatherIcon.Clipboard size={40} color="#524e4e" />
						</div>
					</Col>
					<Col md={3} className="gradiant-order-box" onClick={this.handleServiceClick.bind(this,'DTH','dth')} >
						<div className="">
							DTH<br/>
							<FeatherIcon.Airplay size={40} color="#524e4e"  />
						</div>
					</Col>
					<Col md={3} className="gradiant-order-box" onClick={this.handleServiceClick.bind(this,'Land Line','recharge')} >
						<div className="">
							Land Line
							<FeatherIcon.Cast size={40} color="#524e4e" />
						</div>
					</Col>
					<Col md={3} className="gradiant-order-box" onClick={this.handleServiceClick.bind(this,'Broad Band','recharge')} >
						<div className="">
							Brod Band
							<FeatherIcon.Wifi size={40} color="#524e4e" />
						</div>
					</Col>
					<Col md={3} className="gradiant-order-box" onClick={this.handleServiceClick.bind(this,'Electricity','recharge')} >
						<div className="">
							Electricity
							<FeatherIcon.Sliders size={40} color="#524e4e" />
						</div>
					</Col>
					<Col md={3} className="gradiant-order-box" onClick={this.handleServiceClick.bind(this,'ECommerce','recharge')} >
						<div className="">
							Shopping<br />
							<FeatherIcon.ShoppingCart size={40} color="#524e4e" />
						</div>
					</Col>
					<Col md={3} className="gradiant-order-box" onClick={this.handleServiceClick.bind(this,'Many More','recharge')} >
						<div className="">
							Many More
							<FeatherIcon.Plus size={40} color="#524e4e" />
						</div>
					</Col>
				</Row>
							
				
				<Modal show={this.state.showModal} className="modal right nomodal-backdrop" size="lg" onHide={()=>{this.setState({showModal:false})}} >
					{/*<Modal.Header closeButton>
					{this.state.modalTitle}
					</Modal.Header>*/}
				  <Modal.Body>
						<div style={styles.modalCloseIcon} onClick={()=>{this.setState({showModal:false})}} >
							<FeatherIcon.X size={32} color="#524e4e" />
						</div>
					  {
						this.state.aboutComp=='recharge' ? <About_Recharge /> : 
						this.state.aboutComp=='dth' ? <About_DTH />: ''
					  }
				  </Modal.Body>
				</Modal>


			</div>
		);
	}
	
}
export default Services;

