import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';

import { Row, Col, Nav, Tab, Table } from 'react-bootstrap';
import SimpleLineIcon from 'react-simple-line-icons';
import * as FeatherIcon from 'react-feather';

import RechargeTransaction from './RechargeTransaction';

const styles = {
	payIconStyle: {
		float: 'right',
		fontSize: '20px',
		fontWeight: '550',
	}
}

class TransactionHistory extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
	}
	
	componentDidMount(){
		
	}


	render(){
	
		return(
			<div className="payContainer" >
				<Tab.Container id="left-tabs-example" defaultActiveKey="trans_recharge" style={{ width: '100%' }} >
					<Row>
						<Col sm={2}>
						<Nav variant="pills" className="flex-column">
							<Nav.Item>
								<Nav.Link eventKey="trans_recharge">
									Recharge Transaction
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="trans_pending">
									Pending Transaction
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="trans_stock">
									Stock Transaction
								</Nav.Link>
							</Nav.Item>
						</Nav>
						</Col>
						<Col sm={10}>
						<Tab.Content>
							<Tab.Pane eventKey="trans_recharge">

								<RechargeTransaction />

							</Tab.Pane>
							<Tab.Pane eventKey="trans_pending">
							
							</Tab.Pane>
						</Tab.Content>
						</Col>
					</Row>
					</Tab.Container>
			</div>
		);
	}
	
}
export default TransactionHistory;

