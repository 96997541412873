import React, { Component } from 'react';
import $ from 'jquery';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';

import SimpleLineIcon from 'react-simple-line-icons';
import { Row, Col } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';

import RefreshImg from './refresh_70x115.png';

const styles = {
	imgStyle: {
		width: '115px',
		padding: '1px',
		backgroundColor: '#868788',
		height: '45px',
		marginTop: '5px',
	},
	refreshImg: {
	    width: '25px',
		marginTop: '-69px',
		marginLeft: '118px',
		cursor: 'pointer',
	}
}

class Captcha extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			captchaImage: '',
			captchaCode: '',
			userCaptchaCode: '',
		}
		this.loadCaptcha = this.loadCaptcha.bind(this);
	}
	
	componentDidMount(){
		
		this.loadCaptcha();
	}

	loadCaptcha(){
		Reqst.axGet('captcha').then(res=>{
			this.setState({captchaImage: res['data'].img, captchaCode: res['data'].code});
		});
	}

	render(){
	
		return(
			<div className="" >
				<img src={this.state.captchaImage} style={styles.imgStyle} />
				<div>
					<input type="hidden" name="liveCaptchCode" value={this.state.captchaCode} />
					<img src={RefreshImg} style={styles.refreshImg} onClick={this.loadCaptcha} />
				</div>
			</div>
		);
	}
	
}
export default Captcha;

