import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';
import Logo from '../../../assets/img/dbs300.png';

import {Row, Col, Nav, Navbar, NavDropdown,Form, FormControl, Button } from 'react-bootstrap';
import SimpleLineIcon from 'react-simple-line-icons';
import * as FeatherIcon from 'react-feather';

const styles = {
	firstRow: {
	    borderTop: '1px solid rgb(239, 239, 239)',
		marginTop: '20px',
		color: '#828282',
		fontWeight: '500',
		width: '100%',
	}
}

class Footer extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
	}
	
	componentDidMount(){
		
	}


	render(){
		
		(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
        } else {
            $('.scrollup').fadeOut();
        }
    });

    $('.scrollup').click(function () {
        $("html, body").animate({
            scrollTop: 0
        }, 600);
        return false;
    });
	
		return(
			<div style={styles.firstRow} >
				<Row style={{ paddingTop: '15px' }} >
					<Col md={2} ></Col>
					<Col md={10} >
						<div className="text-center" >
							<p style={{ fontSize: '13px' }} >
								Copyright &copy; 2018 Peace Soft Technologies. All rights reserved.
							</p>
						</div>
						<div id="floatOptions" style={{position: 'fixed',right:'38px',top: '93%'}}>
						<div>
							<a href="javascript:;" style={{boxShadow: '2px 2px #c0bfc5',borderRadius: '20px'}} className="scrollup btn btn-primary  m-b-sm btn-sm"><i data-toggle="tooltip" data-title="Up" className="fa fa-arrow-up"></i></a>
						</div>
					</div>
					</Col>
				</Row>
			</div>
		);
	}
	
}
export default Footer;

