/*****
Loading Submit Button
Required :- 	1.	fa icon
				2.	Bootstrap
			
Use	:-
	<Jbutton className="btn btn-success" loading={this.state.showLoader} text="Save" loadinText="Saving..." />
****/

import React, { Component } from 'react';

const styles = {
	firstDiv: {
	    width: '100%',
		position: 'fixed',
		top: '0px',
		zIndex: '9999',
		left: '0px',
		textAlign: 'center',
	},
	secondDiv: {
		width: '100px',
		fontSize: '14px',
		minWidth: '250px',
		marginTop: '3px',
		borderRadius: '5px',
		boxShadow: 'inset -1px 0 0 rgba(0, 0, 0, .25)',
	},
	success: {
		backgroundColor: 'rgb(6, 236, 6)',
		color: '#fff',
		padding: '4px',
		border: '1px solid #14ab14',
		borderRadius: '5px',
		
	}
}

class LoaderMsg extends Component {
	
	constructor(props){
		super(props);
		this.state = {
		
		}		
	}
	
	render() {	  
	
		
		return (
			<div style={styles.firstDiv} >
				<center>
					<div style={styles.secondDiv} >
						<div style={styles.success} >
							&#10003; &nbsp;Loading..
						</div>
					</div>
				</center>
			</div>			
		);
	}
}

export default LoaderMsg;