import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PageTitle from '../../PageTitle';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class AddSupporter extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			country : 'India',
			region : 'Tamil Nadu',
			company : Reqst.getTokenData('companyid','cp'),
			supplist : false,
			headlist : [],
			zonallist : [],
			ismarry : 0,
			userrole : Reqst.getTokenData('role','data'),
			user : Reqst.getTokenData('id','data')
		}
		this.validator = new SimpleReactValidator();
		this.handleFormSubmit= this.handleFormSubmit.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleCheckChange = this.handleCheckChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	
	handleCheckChange(e){
		
		if(e.target.checked){
			
			this.setState({ismarry: 1});
		}
		else {
			
			this.setState({ismarry: 0});
		}
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	selectCountry (val) {
		this.setState({ country: val });
	}
 
	selectRegion (val) {
		this.setState({ region: val });
	}

	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleFormSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			this.setState({showLoader:true});
			var data = Sanitize.objectifyForm($("#frmSuppadd").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('family/addsupporter/',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false});	
					document.getElementById("frmSuppadd").reset();
					swal("Success",res['data'].msg,"success");
				}			
				else
				{
					this.setState({showLoader: false});	
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	componentDidMount(){
		
		
		$("#marr_area").hide();	
	}
	
	

	render(){
		
		const { country, region } = this.state;
		
		
		
		$("#supp_dob").change(function(){

				    var dob = $(this).val();
					var fields = dob.split('-');
					var doby = fields[0];
					console.log(doby);
					var today = new Date();
					var dd = today.getDate();
					var mm = today.getMonth()+1; 
					var yyyy = today.getFullYear();
					var age = parseInt(yyyy) - parseInt(doby);
					$("#supp_age").val(age);
	
			});
			
	$("#is_married").change(function(){
		if($(this).prop('checked')==true){
			$("#marr_area").show();			
		}
		else{
			$("#marr_area").hide();	
		}
	});
			
			$("#whatsapp_numchange").click(function(){

				    var mobile = $("#supp_mob").val();
					$("#supp_whatsapp").val(mobile);
	
			});
		
	
		
		this.genders = [
		{value : '', name : '-Select-'},
		{value : 'Male', name : 'Male'},
		{value : 'Female', name : 'Female'}
	   ];
	   
	  
	   
	    this.education = [
		{value : '', name : '-Select-'},
		{value : 'Illiterate', name : 'Illiterate'},
		{value : 'Primary', name : 'Primary'},
		{value : 'Higher', name : 'Higher'},
		{value : 'Secondary', name : 'Secondary'},
		{value : 'Diploma', name : 'Diploma'},
		{value : 'UG', name : 'UG'},
		{value : 'PG', name : 'PG'}
	   ];
	   
	   this.occupation = [
		{value : '', name : '-Select-'},
		{value : 'Pastor', name : 'Pastor'},
		{value : 'Business', name : 'Business'},
		{value : 'Doctor', name : 'Doctor'},
		{value : 'Engineer', name : 'Engineer'},
		{value : 'Farmer', name : 'Farmer'},
		{value : 'Govt/Pvt Service', name : 'Govt/Pvt Service'},
		{value : 'Labour', name : 'Labour'},
		{value : 'Lawyer', name : 'Lawyer'},
		{value : 'Professor', name : 'Professor'},
		{value : 'Professional', name : 'Professional'},
		{value : 'Retired', name : 'Retired'},
		{value : 'Teacher', name : 'Teacher'},
		{value : 'Unemployed', name : 'Unemployed'}
	   ];
		

		if(this.state.userrole!='admin' && this.state.userrole!='staff')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
	   if(this.state.supplist)
		{
			return(<Redirect to="/supporter/list" />);
		}
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Add Supporter</title>
				</Helmet>
				
				<PageTitle title="Add Supporter" >
					<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({supplist:true})}} >
							<i className="fa fa-arrow-left"></i> Back
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
				<form action="" method="post" encType="multipart/form-data" id="frmSuppadd" onSubmit={this.handleFormSubmit} >
				<input type="hidden" name="userid" value={this.state.user} />
				<input type="hidden" name="companyid" value={this.state.company} />
				<input type="hidden" name="region" value={this.state.region} />
				<input type="hidden" name="country" value={this.state.country} />
				<input type="hidden" name="ismarry" value={this.state.ismarry} />
					<Row>
					
						
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Name<span className="text-danger">*</span></label>
								<input type="text" name="supp_name" id="supp_name" className="form-control single-line-input"  required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Name', this.state.supp_name, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Gender<span className="text-danger">*</span></label>
								 <select name="supp_gen" onChange={this.handleInputChange} id="supp_gen" required className="form-control single-line-input" >
								{this.genders.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Gender', this.state.supp_gen, 'required')}
							</div>
						</Col>
						<Col md={3} >
							<div className="form-group" >
								<label className="label-text" >DOB<span className="text-danger"></span></label>
								<input type="date" name="supp_dob" id="supp_dob" className="form-control single-line-input"  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('DOB', this.state.supp_dob, '')}
							</div>
						</Col>
						<Col md={3} >
							<div className="form-group" >
								<label className="label-text" >Age<span className="text-danger"></span></label>
								<input type="number" name="supp_age" id="supp_age" className="form-control single-line-input"  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('DOB', this.state.supp_age, '')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Education<span className="text-danger">*</span></label>
								 <select name="supp_edu" onChange={this.handleInputChange} id="supp_edu" required className="form-control single-line-input" >
								{this.education.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Education', this.state.supp_edu, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Occupation<span className="text-danger"></span></label>
								 <select name="supp_occ" onChange={this.handleInputChange} id="supp_occ"  className="form-control single-line-input" >
								{this.occupation.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Occupation', this.state.supp_occ, '')}
							</div>
						</Col>
					
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Place<span className="text-danger"></span></label>
								<input type="text" name="supp_place" id="supp_place" className="form-control single-line-input"  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Place', this.state.supp_place, '')}
							</div>
						</Col>
						
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >City<span className="text-danger"></span></label>
								<input type="text" name="supp_city" id="supp_city" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('City', this.state.supp_city, '')}
							</div>
						</Col>
						
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Address<span className="text-danger"></span></label>
								<input type="text" name="supp_addr" id="supp_addr" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Address', this.state.supp_addr, '')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Pincode<span className="text-danger"></span></label>
								<input type="number" name="supp_pin" id="supp_pin" className="form-control single-line-input"  placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Pincode', this.state.supp_pin, '')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Country<span className="text-danger">*</span></label>
								<CountryDropdown value={country} className="form-control single-line-input" onChange={(val) => this.selectCountry(val)} id="con" />
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >State<span className="text-danger">*</span></label>
								<RegionDropdown country={country} className="form-control single-line-input" value={region} onChange={(val) => this.selectRegion(val)} id="con" />
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Mobile<span className="text-danger">*</span></label>
								<input type="number" name="supp_mob" id="supp_mob" className="form-control single-line-input"  required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message(' Mobile', this.state.supp_mob, 'required')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Email<span className="text-danger"></span></label>
								<input type="email" name="supp_mail" id="supp_mail" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Email', this.state.f_head_mail, '')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Phone<span className="text-danger"></span></label>
								<input type="number" name="supp_phone" id="supp_phone" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Phone', this.state.f_head_phone, '')}
							</div>
						</Col>
						<Col md={2} >
							<div className="form-group" >
								<label className="label-text" > Married </label>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<input type="checkbox" name="is_married" id="is_married" value="1"  placeholder="" onChange={this.handleCheckChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Phone', this.state.is_married, '')}
							</div>
						</Col>
						<Col md={4}>
							<div className="form-group" id="marr_area">
								<input type="date" name="marr_date" id="marr_date" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Phone', this.state.marr_date, '')}
							</div>
						</Col>
						<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Whats App Number <a href="javascript::;" className="text-primary" id="whatsapp_numchange">(Use the Mobile Number)</a></label>
								<input type="number" name="supp_whatsapp" id="supp_whatsapp" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Phone', this.state.supp_whatsapp, '')}
							</div>
						</Col>
						<Col md={12} >
							<div className="form-group" >
								<label className="label-text" >Remarks<span className="text-danger"></span></label>
								<input type="textarea" name="supp_remarks" id="supp_remarks" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Church Website', this.state.supp_remarks, '')}
							</div>
						</Col>
						
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Save" loadingText="Saving.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
			</div>
		);
	}
	
}
export default AddSupporter;


