import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../tie/GlobalConstants';
import Sanitize from '../../../tie/Sanitize';

import {Row, Col, Nav, Navbar, NavDropdown,Form, FormControl, Button } from 'react-bootstrap';
import SimpleLineIcon from 'react-simple-line-icons';
import * as FeatherIcon from 'react-feather';

import Header from './Header';
import Footer from './Footer';

const styles = {
	firstHeader: {
		padding:'4px 10px',
    	border: '1px solid grey',
	}
}

class Layout extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
	}
	
	componentDidMount(){
		$("body").css('background-color','#fff');	
	}


	render(){
	
		return(
			<div style={{ overflowX: 'hidden' }} >
				
				<Header />
				
				<div className="container page-content" style={{ maxWidth: '99%', padding: '0', margin: '0', marginTop: '100px' }} >
					{this.props.children}
				</div>
				
				<Footer />
				
			</div>
		);
	}
	
}
export default Layout;

