import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PageTitle from '../../PageTitle';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';


class AddGroupMember extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			company : Reqst.getTokenData('companyid','cp'),
			memberlist : false,
			members : [],
			grouplist : [],
			memlist : [],
			zonallist : [],
			fview : 2,
			view : 0,
			userrole : Reqst.getTokenData('role','data'),
			user : Reqst.getTokenData('id','data')
		}
		this.validator = new SimpleReactValidator();
		this.handleFormSubmit= this.handleFormSubmit.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	onSelectMem(id,e){
		
		if(e.target.checked){
			
			if(!this.checkidExists(id)){
				this.state.memlist.push({"id":id});
			}
			console.log(this.state.memlist);
		}
		else{
			
			
			if(this.checkidExists(id)){
				var json = this.state.memlist;
				var index = this.state.memlist.findIndex(function(obj){
		        return (obj.id == id); });
                json[index]['id']='';
			}
			
		}
		
		
		
	}
	
	checkidExists(id){
		
		var bstate=false;
		var needle = id;
			for (var i = 0; i < this.state.memlist.length; i++){
			  // look for the entry with a matching `code` value
			  if (this.state.memlist[i].id == needle){
				 // we found it
				// obj[i].name is the matched result
					bstate=true;
				break;
			  }
			else{
				bstate= false;
			 }
		}
		return bstate;
	}
	
	 showFilter() {
       
	   if(this.state.fview==2 || this.state.fview==1){
		   this.setState({fview:0});
	   }
	   else{
		   
		   this.setState({fview:1});
	   }
    }
	
	
	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleFormSubmit(e){
		e.preventDefault();
		//if (this.validator.allValid()) {
			this.setState({showLoader:true});
			this.setState({members:[]});
			var data = Sanitize.objectifyForm($("#frmMemSearch").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('group/membersearch/',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false});	
					document.getElementById("frmMemSearch").reset();
					this.setState({
						members : res['data'].data,
						view : 1,
						fview : 0
					});
					
				}			
				else
				{
					this.setState({showLoader: false});	
					swal("Error",res['data'].msg,"error");
				}
			});
		/* }
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		} */
	}
	
	componentDidMount(){
		
		this.zoanldata();
		this.groupdata();
		
	}
	
	groupdata(){
		 $("#showLoader").show();
		Reqst.GetData('group/getchurchgroups/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({grouplist : res});
			}
		});
	}
	
	zoanldata(){
		 $("#showLoader").show();
		Reqst.GetData('setup/getchurchzonals/'+this.state.company).then((result) => {
			 $("#showLoader").hide();
			let res = result;
			if(res){
				this.setState({zonallist : res});
			}
		});
	}

	render(){
		
	$("#data_load").hide();
		  
	$('#chkParent').click(function() {
        var isChecked = $(this).prop("checked");
        $('.res_tab tr:has(td)').find('input[type="checkbox"]').prop('checked', isChecked);
	});
	
	$("#frmMemGroupAdd").on('submit', function (e) {
	 if (!e.isDefaultPrevented()) {
	   e.preventDefault();
		
		var len = $("input[name='chkOpt']:checked").length;
		var group = $("#gr_addto").val();
		 
		var count = 0;
		var realcount = 0;
		
	if(len>0){
		
		$("#data_load").show();
		$("#btn_submit").hide();
		
		
		$.each($("input[name='chkOpt']:checked"), function(){
		
		 var id = $(this).attr('data-id');
		 var user = $("#userdataid").val();
		 var company = $("#companydataid").val();

		  $.get(GC.API_URL+'group/addgroupmember/'+id+'/'+group+'/'+user+'/'+company,function(o){
						
				var stcount = o.status;	
				
				realcount = parseInt(realcount) + parseInt(stcount);
				count = parseInt(count) + 1;
			
				if(parseInt(count)==parseInt(len)){
			  
				   $("#data_load").hide();
				   $("#btn_submit").show();
				   alert(realcount+' Members Added Successfully..');
				   $(".filter_area").show();
				   $(".tab_data").hide();
			 
				}
		
			},'json');
			
	
		});
	
	  
	 }
	 else{
		
		 alert('Select Members..');
	}
		
	  }
					
	});
		
		const { country, region } = this.state;
		
		
		
		var areas = this.state.zonallist;
		var groups = this.state.grouplist;
		
		this.genders = [
		{value : '', name : '-Select-'},
		{value : 'Male', name : 'Male'},
		{value : 'Female', name : 'Female'}
	   ];
	   
	   this.types = [
		{value : '', name : '-Select-'},
		{value : 'head', name : 'Head'},
		{value : 'member', name : 'Member'}
	   ];
	  
	   
	    this.education = [
		{value : '', name : '-Select-'},
		{value : 'Illiterate', name : 'Illiterate'},
		{value : 'Primary', name : 'Primary'},
		{value : 'Higher', name : 'Higher'},
		{value : 'Secondary', name : 'Secondary'},
		{value : 'Diploma', name : 'Diploma'},
		{value : 'UG', name : 'UG'},
		{value : 'PG', name : 'PG'}
	   ];
	   
	   this.occupation = [
		{value : '', name : '-Select-'},
		{value : 'Pastor', name : 'Pastor'},
		{value : 'Business', name : 'Business'},
		{value : 'Doctor', name : 'Doctor'},
		{value : 'Engineer', name : 'Engineer'},
		{value : 'Farmer', name : 'Farmer'},
		{value : 'Govt/Pvt Service', name : 'Govt/Pvt Service'},
		{value : 'Labour', name : 'Labour'},
		{value : 'Lawyer', name : 'Lawyer'},
		{value : 'Professor', name : 'Professor'},
		{value : 'Professional', name : 'Professional'},
		{value : 'Retired', name : 'Retired'},
		{value : 'Teacher', name : 'Teacher'},
		{value : 'Unemployed', name : 'Unemployed'}
	   ];
		
		 if(this.state.view == 1){
		
		   $(".tab_data").show();
		}
		else{
			
			$(".tab_data").hide();
		}
	
	 if(this.state.fview == 2 || this.state.fview == 1){
		
		$(".filter_area").show();
	 }
	 else{
			
		$(".filter_area").hide();
	 }
		
		if(this.state.userrole!='admin' && this.state.userrole!='staff')
		{
			return(<Redirect to="/invalidaccess" />);
		}
		
	   if(this.state.memberlist)
		{
			return(<Redirect to="/family/memberlist" />);
		}
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Add Group Member</title>
				</Helmet>
				
				<PageTitle title="Add Group Member" >
					<div className="btn-toolbar mb-2 mb-md-0">
					<div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.showFilter()}} >
							<i className="fa fa-search"></i>&nbsp;Show / Hide Filters
						</button>
					
					  </div>
					</div>
				</PageTitle>
				<Row className="filter_area">
				  <Col md="12">
				<form action="" method="post" encType="multipart/form-data" id="frmMemSearch" onSubmit={this.handleFormSubmit} >
				<input type="hidden" name="userid" value={this.state.user} />
				<input type="hidden" name="companyid" value={this.state.company} />
					<Row>
					
						<Col md={4} >
							<div className="form-group" >
								<label className="label-text" >Family Id<span className="text-danger"></span></label>
								<input type="text" name="f_head_id" id="f_head_id" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Name', this.state.f_head_name, '')}
							</div>
						</Col>
						<Col md={4} >
							<div className="form-group" >
								<label className="label-text" >Gender<span className="text-danger"></span></label>
								 <select name="f_head_gen" onChange={this.handleInputChange} id="f_head_gen"  className="form-control single-line-input" >
								{this.genders.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Gender', this.state.f_head_gen, '')}
							</div>
						</Col>
					

						<Col md={4} >
							<div className="form-group" >
								<label className="label-text" >Education<span className="text-danger"></span></label>
								 <select name="f_head_edu" onChange={this.handleInputChange} id="f_head_edu"  className="form-control single-line-input" >
								{this.education.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Education', this.state.f_head_edu, '')}
							</div>
						</Col>
						
						<Col md={4} >
							<div className="form-group" >
								<label className="label-text" >Occupation<span className="text-danger"></span></label>
								 <select name="f_head_occ" onChange={this.handleInputChange} id="f_head_occ"  className="form-control single-line-input" >
								{this.occupation.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Occupation', this.state.f_head_occ, '')}
							</div>
						</Col>
						<Col md={4} >
							<div className="form-group" >
								<label className="label-text" >Area<span className="text-danger"></span></label>
								 <select name="f_head_area" onChange={this.handleInputChange} id="f_head_area"  className="form-control single-line-input" >
								  <option value="">Select Area</option>
									{
										areas.map(function (item) {
											return <option value={item.id }>{item.name}</option>;
										})
									}
							 </select>
								 {this.validator.message('Area', this.state.f_head_area, '')}
							</div>
						</Col>
						<Col md={4} >
							<div className="form-group" >
								<label className="label-text" >Type<span className="text-danger"></span></label>
								 <select name="f_mem_type" onChange={this.handleInputChange} id="f_mem_type"  className="form-control single-line-input" >
								{this.types.map((e, key) => {
									return <option key={key} value={e.value}>{e.name}</option>;
								})}	
							 </select>
								 {this.validator.message('Gender', this.state.f_mem_type, '')}
							</div>
						</Col>
						<Col md={3} >
							<div className="form-group" >
								<label className="label-text" >Age From<span className="text-danger"></span></label>
								<input type="number" name="mem_age_from" id="mem_age_from" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Name', this.state.f_head_name, '')}
							</div>
						</Col>
						<Col md={3} >
							<div className="form-group" >
								<label className="label-text" >Age To<span className="text-danger"></span></label>
								<input type="number" name="mem_age_to" id="mem_age_to" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Name', this.state.f_head_name, '')}
							</div>
						</Col>

						
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Search" loadingText="Searching.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
				 </Col>
			</Row>
			<br />
			<br />
				<Row className="tab_data">
				  <Col md="12">
				  <form action="" method="post" encType="multipart/form-data" id="frmMemGroupAdd" onSubmit={this.handleAddSubmit} >
				  <input type="hidden" name="userid" id="userdataid" value={this.state.user} />
				<input type="hidden" name="companyid" id="companydataid" value={this.state.company} />
				 <Col md="12" style={{height: '150px',overflowY: 'scroll'}}>
					<Table responsive hover bordered className="res_tab">
					  <thead>
						<tr>
						  <th>S.No</th>
						  <th>Member Id</th>
						  <th>Family Id</th>
						  <th>Name</th>
						  <th>Mobile</th>
						  <th>Email</th>
						  <th><input type="checkbox" id="chkParent" /></th>
						</tr>
					  </thead>
					  <tbody>
						{this.state.members.map((item,index) =>
					 
									<tr key = {item}>
										<td  width="20">{index+1}</td>
										<td width="100">{item.memberid}</td>
										<td width="100">{item.familyid}</td>
										<td style={{textTransform:'capitalize'}}width="100">{item.name}</td>
										<td width="100">{item.mobile}</td>
										<td width="100">{item.email}</td>
										<td width="80">
								
								        <input type="checkbox" name="chkOpt" id="chkOpt" data-id={item.memberid} />
								
								       </td>
									</tr>
								)}
					</tbody>
					</Table>
				 </Col>
				<Row className="submit_area">
				  <Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Group<span className="text-danger"> *</span></label>
								 <select name="gr_addto" onChange={this.handleInputChange} id="gr_addto"  required className="form-control single-line-input" >
								  <option value="">Select Group</option>
									{
										groups.map(function (item) {
											return <option value={item.id }>{item.name}</option>;
										})
									}
							 </select>
								 {this.validator.message('Group', this.state.gr_addto, 'required')}
							</div>
						</Col>
				  </Row>
				  <Row className="submit_area">
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
	                      <span className="pull-right" id="data_load"><i className="fa fa-spinner fa-spin" style={{fontSize:'24px'}}></i></span>
						  <span id="btn_submit">
							 <LoadingBtn text="Add" loadingText="Adding.." className="btn btn-sm btn-success"  loading={this.state.showLoader} >
							
							</LoadingBtn>
						</span>
						</div>
					</Col>
					</Row>
				</form>
				</Col>
					</Row>
			</div>
		);
	}
	
}
export default AddGroupMember;


