import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import LoadingBtn from '../../../../tie/LoadingBtn';

import SimpleLineIcon from 'react-simple-line-icons';
import { Row, Col, Input, Form, Card, InputGroup, Carousel, Modal, Fade, utils } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
import Logo from '../../../../assets/img/logo.png';
import TitleLogo from '../../../../assets/img/dth_1.png';

const styles = {
	rowStyle: {
		lineHeight: '27px',
		fontSize: '15px',
	}
}

class DTH extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showModal: false,
			modalTitle: '',
		}
		
	}
	
	

	render(){

		
	
		return(
			<div>
				<Row style={styles.rowStyle} >
					
					<Col md={12} className="text-center" >
						<h4 style={{ fontWeight: '330' }} >DTH Recharge</h4>
						<img src={TitleLogo} style={{ width: '350px' }}  />
						<br />
						<br />
					</Col>
					<Col md={12} >
						&emsp; DbsWallet Recharge makes the recharge of prepaid DTH account quick and comfortable. You do not have to visit the retail outlets for getting your online DTH account recharged. Online DTH Recharge through DbsWallet Recharge facilitates recharging of prepaid DTH account for operators like Dish TV, Big TV, Sun Direct, Videocon D2H and Tata Sky. You can recharge it at the comfort of your home or office with few clicks of mouse. All you need to have is a computer with internet connection and online recharge of your prepaid DTH account is just few clicks away. Just login, select the operator, fill the recharge denomination, DTH account number and proceed further with recharge. It is as simple as ever.
					</Col>
				</Row>
			</div>
		);
	}
	
}
export default DTH;

