import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';
import * as GC from '../../../../tie/GlobalConstants';
import Sanitize from '../../../../tie/Sanitize';
import * as Reqst from '../../../../tie/Reqst';
import localIpUrl from 'local-ip-url';
import LoadingBtn from '../../../../tie/LoadingBtn'
import SimpleLineIcon from 'react-simple-line-icons';
import SimpleReactValidator from 'simple-react-validator';
import noImg from '../../../../assets/img/noimage_200.png';
import swal from 'sweetalert';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PageTitle from '../../PageTitle';
import { Row, Col, Input, Form, Card, InputGroup, Table, Carousel, Modal, Fade, utils, ListGroup } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
import ReactFileReader from 'react-file-reader';
import CKEditor from 'ckeditor4-react';


class AddAnnouncement extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			 files : '',
			 photoname : '',
			 datalist : false,
			 editorContent : '',
			userrole : Reqst.getTokenData('role','data'),
			user : Reqst.getTokenData('id','data')
		}
		this.validator = new SimpleReactValidator();
		this.handleEditorChange = this.handleEditorChange.bind(this);
		this.handleFormSubmit= this.handleFormSubmit.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
		this.handlefileRemove = this.handlefileRemove.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	
	handleEditorChange(v){
		var newContent = v.editor.getData();
		this.setState({editorContent:newContent});
	}
	
	/**
	* to handle all the input change events
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	
	handlefileRemove(){
		
		this.setState({ files: '',photoname : ''});
	
	}
	
	handleFiles(files){
		this.setState({ files: files.base64,photoname : files.fileList[0]['name']});
	}
	
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	
	viewPassword(){
		this.setState({viewpass : !this.state.viewpass});
	}

	/**
	* to handle all the input blur event 
	* only for react simple validator
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	


	/**
	* to handle product category form submit event
	*
	* @since 1.0
	* @DT : 13/02/2019
	*/
	handleFormSubmit(e){
		e.preventDefault();
		
		if (this.validator.allValid()) {
			if(this.state.editorContent.length<=1)
			{
				swal("Error","Enter Content","error");
				return;
			}
			$("#showLoader").show();
			var data = Sanitize.objectifyForm($("#frmAnnUpdate").serializeArray());
			data = Reqst.Token(data);
			Reqst.axPost('appcontent/addannouncement/',{token: data}).then(res=>{
				if(res['data'].status=='1')
				{
					$("#showLoader").hide();
					swal("Success",res['data'].msg,"success");
					document.getElementById("frmAnnUpdate").reset();
					this.setState({ files: '',photoname : ''});
				}			
				else
				{
					$("#showLoader").hide();
					swal("Error",res['data'].msg,"error");
				}
			});
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	componentDidMount(){
		
	
		
	}
	
	
	
	
	

	render(){
		
		
		
		 if(this.state.datalist)
		{
			return(<Redirect to="/appcontent/announcements" />);
		}
	
		return(
			<div className="container payContainer">		
			<Helmet>
					<title>{GC.APPTITLE} | Add Announcement</title>
				</Helmet>
				
				<PageTitle title="Add Announcement" >
				<div className="btn-toolbar mb-2 mb-md-0">
					  <div className="btn-group mr-2">
						<button className="btn btn-sm btn-outline-secondary" onClick={()=>{this.setState({datalist:true})}} >
							<i className="fa fa-arrow-left"></i> Back
						</button>
					
					  </div>
					</div>
				</PageTitle>
				
				<form action="" method="post" encType="multipart/form-data" id="frmAnnUpdate" onSubmit={this.handleFormSubmit} >
				<input type="hidden" name="userid" value={this.state.user} />
				  <input type="hidden" name="photoname" value={this.state.photoname} />
				  <input type="hidden" name="files" value={this.state.files} />
				  <input type="hidden" value={this.state.editorContent} id="ann_content" name="ann_content" />
					<Row>
					<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Title<span className="text-danger">*</span></label>
								<input type="text" name="ann_tit" id="ann_tit" className="form-control single-line-input"  required placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Title', this.state.ann_tit, 'required')}
							</div>
					</Col>
					<Col md={6} >
							<div className="form-group" >
								<label className="label-text" >Expired On<span className="text-danger"></span></label>
								<input type="date" name="ann_date" id="ann_date" className="form-control single-line-input"   placeholder="" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
								 {this.validator.message('Title', this.state.ann_date, '')}
							</div>
					</Col>
					
					 <Col md={8}>
						<div className="form-group" >
									           <CKEditor 
												  data={this.state.editorContent} 
												  config={{extraPlugins :'font,colorbutton,colordialog,iframe'}}
												  onChange = {this.handleEditorChange}
												  
												 />
							</div>
						
					 </Col>
					 <Col md={4} >
							<div className="form-group" >
								<label className="label-text" >Image<span className="text-danger">(Recommended Size: 600px X 450px (W : H = 4:3 ))</span></label>
								<ReactFileReader handleFiles={this.handleFiles} fileTypes='image/*' base64={true}>
							     <img src={this.state.files!='' ? this.state.files : noImg} style={{border : '1px solid #cccccc',width : '100%',maxWidth:'150px',maxHeight:'150px',cursor : 'pointer'}} />
						       </ReactFileReader>
							   {this.state.files!='' ?
								  <span className="btn btn-danger btn-xs" onClick={this.handlefileRemove}>Remove</span>
								  : ''								  
								}
							</div>
					</Col>
	
						
					</Row>	
					<Row>
					<Col md={12} Col xs={12} >
						<div className="btn-list text-right mt-4">
							<LoadingBtn text="Save" loadingText="Saving.." className="btn btn-sm btn-success" loading={this.state.showLoader} > >
							
							</LoadingBtn>
							
						</div>
					</Col>
					</Row>
				</form>
			</div>
		);
	}
	
}
export default AddAnnouncement;


