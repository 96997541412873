import React, { Component } from 'react';
import $ from 'jquery';
import * as GC from '../../tie/GlobalConstants';
import Sanitize from '../../tie/Sanitize';

class PageTitle extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
	}
	
	componentDidMount(){
		
	}


	render(){
	
		return(
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
				<h1 className="h2" style={{ fontSize:'20px' }} >{this.props.title}</h1>
					{this.props.children}
			  </div>							  
		);
	}
	
}
export default PageTitle;

